import React from 'react';

interface Props {
	bgColor: string;
	mainColor: string;
}

export const ChatBubbleSVG = (props: Props) => {
	return (
		<svg
			width="46px"
			height="46px"
			viewBox="0 0 46 46"
			version="1.1"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			xmlns="http://www.w3.org/2000/svg"
		>
			<desc>Chat Bubble</desc>
			<g id="icon/jobs-blue">
				<g id="Group-7">
					<path
						d="M23 46C35.7025 46 46 35.7025 46 23C46 10.2975 35.7025 0 23 0C10.2975 0 0 10.2975 0 23C0 35.7025 10.2975 46 23 46Z"
						id="Oval"
						fill={props.bgColor}
						fillOpacity="0.3094308"
						stroke="none"
					/>
					<path
						d="M15 0L13.5 0L13.5 9C13.5 9.828 12.828 10.5 12 10.5L2.742 10.5L0 13.5L7.5 13.5L12 18L12 13.5L15 13.5C15.828 13.5 16.5 12.828 16.5 12L16.5 1.5C16.5 0.672 15.828 0 15 0Z"
						transform="translate(18.5 18)"
						id="Path"
						fill={props.mainColor}
						stroke="none"
					/>
					<path
						d="M1.5 0L16.5 0C17.328 0 18 0.672 18 1.5L18 12C18 12.828 17.328 13.5 16.5 13.5L9 13.5L4.5 18L4.5 13.5L1.5 13.5C0.672 13.5 0 12.828 0 12L0 1.5C0 0.672 0.672 0 1.5 0Z"
						transform="translate(11 12)"
						id="Path"
						fill={props.mainColor}
						stroke="none"
					/>
				</g>
			</g>
		</svg>
	);
};
