import { KeystoneUser } from 'src/helpers/UserManager';

export const generateProductsAndServices = async (country?: string) => {
	return !country || AssessmentAndRecruitmentCountries.includes(country)
		? AssessmentAndRecruitmentValue
		: AssessmentOnlyValue;
};

export const AssessmentAndRecruitmentCountries: string[] = ['Australia'];

export const AssessmentAndRecruitmentValue: string = 'Assessment & Recruitment';

export const AssessmentOnlyValue: string = 'Assessment Only';
