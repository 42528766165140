import { getProfileDataElements } from './common';
import * as common from './common';
import { ProfileData } from './component';
import * as logic from 'src/components/PageBuilder/components/Builder/logic';
import { UserManager } from 'src/helpers/UserManager/UserManager';

class ProfileImplementation {
	public BuildProfileDict: any = [
		{
			id: '1',
			caption: '',
			type: 'container',
			styles: 'header',
			elements: [
				{
					id: 'div',
					type: 'container',
					styles: 'bookend',
					elements: [
						{
							id: 'back',
							caption: 'Back',
							type: 'arrowButton',
							isBackButton: true,
							onClick: logic.checkURLAndNavigate,
							params: { defaultLocation: '/profile' },
						},
					],
				},
				{
					id: 'heading',
					type: 'containerH1',
					elements: [
						{
							type: 'text',
							caption: 'My Details',
						},
					],
				},
				{
					id: 'div',
					type: 'container',
					styles: 'bookend',
				},
			],
		},
		{
			id: 'hr',
			type: 'hr',
		},
		{
			id: '1',
			caption: '',
			type: 'tabs',
			defaultActiveKey: 1,
			vertical: true,
			elements: [
				{
					id: '1',
					caption: 'PERSONAL DETAILS',
					type: 'tabpane',
					key: 1,
					elements: this.constructProfilePersonalDetails(),
					thisTabComplete: this.thisTabComplete,
				},
				{
					id: '2',
					caption: 'LOCATION',
					type: 'tabpane',
					key: 2,
					elements: this.constructWorkRightsAndLocation(),
					thisTabComplete: this.thisTabComplete,
				},
				{
					id: '3',
					caption: 'EMPLOYMENT PREFERENCES',
					type: 'tabpane',
					key: 3,
					elements: this.constructProfileEmploymentPreferences(),
					thisTabComplete: this.thisTabComplete,
				},
				{
					id: '4',
					caption: 'WORKPLACE SUPPORTS',
					type: 'tabpane',
					key: 4,
					elements: this.constructProfileWorkplaceSupports(),
					thisTabComplete: this.thisTabComplete,
				},
				{
					id: '5',
					caption: 'CAREER PREFERENCES',
					type: 'tabpane',
					key: 5,
					elements: this.constructProfileCareerPreferences(),
					thisTabComplete: this.thisTabComplete,
				},
				{
					id: '6',
					caption: 'WORK HISTORY',
					type: 'tabpane',
					key: 6,
					elements: this.constructProfileWorkHistory(),
					thisTabComplete: this.thisTabComplete,
				},
				{
					id: '7',
					caption: 'TECH QUESTIONNAIRE',
					type: 'tabpane',
					key: 7,
					elements: this.constructProfileTechQuestionnaire(),
					thisTabComplete: this.thisTabComplete,
					hidden: this.isProfileTechQuestionnaireHidden,
				},
			],
		},
		{
			id: 'div',
			type: 'container',
			styles: 'footer',
			elements: [
				{
					id: 'hr',
					type: 'hr',
					styles: 'hr',
				},
				{
					id: 'save',
					caption: 'Save',
					type: 'arrowButton',
					onClick: logic.profileDetailsUpdateProfile,
					params: { defaultLocation: '/profile' },
					isDisabled: logic.profileDetailsSaveButtonValidation,
				},
				{
					id: 'div',
					type: 'container',
					styles: 'validationMsg',
					elements: [
						{
							type: 'text',
							caption: 'Profile incomplete: Review items with (!) or *',
						},
					],
					hidden: (controller: any) => {
						return UserManager.profileDetailsCompleted(
							controller.state,
							controller.state.techQuestionnaireEnabled
						);
					},
				},
			],
		},
	];

	private thisTabComplete(constructor: any, tab: String) {
		ProfileData.map((element: any) => {
			if (element.requiredForTab == tab) {
				if (!!!constructor.state[element.stateField]) {
					return false;
				}
			}
		});
		return true;
	}

	private constructProfilePersonalDetails() {
		const result = [
			{
				type: 'container',
				styles: 'row',
				elements: [
					{
						type: 'container',
						styles: 'column',
						elements: getProfileDataElements(['First Name', 'Last Name', 'Email', 'Mobile']),
					},
					{
						type: 'container',
						styles: 'column',
						elements: getProfileDataElements(['marketingSource', 'Pronouns', 'autismDiagnosis']),
					},
				],
			},
		];
		return result;
	}

	private constructWorkRightsAndLocation() {
		const result = [
			{
				type: 'container',
				styles: 'row',
				elements: [
					{
						type: 'container',
						styles: 'column',
						elements: getProfileDataElements(['Country', 'LocationAU'])
							.concat({
								id: 'LocationAU',
								type: 'container',
								elements: getProfileDataElements(['Suburb']).concat({
									type: 'container',
									styles: 'line',
									elements: getProfileDataElements(['State', 'Postcode']),
								}),
								hidden: (controller: any) => {
									return !logic.isInAustralia(controller);
								},
							})
							.concat({
								id: 'LocationInternational',
								type: 'container',
								elements: getProfileDataElements(['City/Town', 'StateFreetext']),
								hidden: (controller: any) => {
									return logic.isInAustralia(controller);
								},
							}),
					},
				],
			},
		];
		return result;
	}

	private constructProfileEmploymentPreferences() {
		const result = [
			{
				type: 'container',
				styles: 'row',
				elements: [
					{
						type: 'container',
						styles: 'column',
						elements: [
							{
								id: 'workRightsContainer',
								type: 'container',
								elements: getProfileDataElements(['workRights']),
								hidden: (controller: any) => {
									return !logic.isInAustralia(controller);
								},
							},
						].concat(
							getProfileDataElements([
								'neurodiversity',
								'hoursPerWeek',
								'relocateForWork',
								'workFromHome',
							])
						),
					},
					{
						type: 'container',
						styles: 'column',
						elements: getProfileDataElements(['salaryRange', 'jobMotivations', 'interests']),
					},
				],
			},
		];
		return result;
	}

	private constructProfileWorkplaceSupports() {
		return this.constructProfileWorkplaceSupportsv2();
	}

	private constructProfileWorkplaceSupportsv1() {
		const result = [
			{
				type: 'containerH3',
				styles: 'sectionHeading',
				elements: [
					{
						type: 'text',
						caption:
							'For each of the supports and adjustments listed below, choose whether they are required, preferred, or not required for you in a new job',
					},
				],
			},
			{
				type: 'container',
				styles: '',
				elements: getProfileDataElements(['workplaceSupports']),
			},
		]; //.concat(getProfileDataElements(['workplaceSupports']));
		return result;
	}

	public constructProfileWorkplaceSupportsv2() {
		const result = [
			{
				type: 'container',
				styles: 'infoContainer',
				elements: [
					{
						type: 'container',
						styles: 'paragraph',
						elements: [
							{
								type: 'text',
								caption:
									'For each of the supports and adjustments listed below, choose whether they are required, preferred, or not required for you in a new job',
							},
						],
					},
				],
			},
			{
				type: 'container',
				styles: '',
				elements: [
					{
						type: 'containerH3',
						styles: 'sectionHeading',
						elements: [
							{
								type: 'text',
								caption: 'Physical environment supports:',
							},
						],
					},
					{
						type: 'container',
						styles: '',
						elements: [
							{
								id: 'workplaceSupportsPhysical',
								caption: 'workplaceSupportsPhysical',
								type: 'newTableRadioButtonGroup',
								stateField: 'workplaceSupports',
								elements: [common.getWorkplaceSupportsPhysical()],
							},
						],
					},
					{
						type: 'containerH3',
						styles: 'sectionHeading',
						elements: [
							{
								type: 'text',
								caption: 'Working hours supports:',
							},
						],
					},
					{
						type: 'container',
						styles: '',
						elements: [
							{
								id: 'workplaceSupports',
								caption: 'workplaceSupports',
								type: 'newTableRadioButtonGroup',
								stateField: 'workplaceSupports',
								elements: [common.getWorkplaceSupportsHours()],
							},
						],
					},
					{
						type: 'containerH3',
						styles: 'sectionHeading',
						elements: [
							{
								type: 'text',
								caption: 'Social supports:',
							},
						],
					},
					{
						type: 'container',
						styles: '',
						elements: [
							{
								id: 'workplaceSupports',
								caption: 'workplaceSupports',
								type: 'newTableRadioButtonGroup',
								stateField: 'workplaceSupports',
								elements: [common.getWorkplaceSupportsSocial()],
							},
						],
					},
					{
						type: 'containerH3',
						styles: 'sectionHeading',
						elements: [
							{
								type: 'text',
								caption: 'Work instruction supports:',
							},
						],
					},
					{
						type: 'container',
						styles: '',
						elements: [
							{
								id: 'workplaceSupports',
								caption: 'workplaceSupports',
								type: 'newTableRadioButtonGroup',
								stateField: 'workplaceSupports',
								elements: [common.getWorkplaceSupportsInstructions()],
							},
						],
					},
					{
						type: 'containerH3',
						styles: 'sectionHeading',
						elements: [
							{
								type: 'text',
								caption: 'Meeting supports:',
							},
						],
					},
					{
						type: 'container',
						styles: '',
						elements: [
							{
								id: 'workplaceSupports',
								caption: 'workplaceSupports',
								type: 'newTableRadioButtonGroup',
								stateField: 'workplaceSupports',
								elements: [common.getWorkplaceSupportsMeeting()],
							},
						],
					},
					{
						type: 'containerH3',
						styles: 'sectionHeading',
						elements: [
							{
								type: 'text',
								caption: 'Feedback supports:',
							},
						],
					},
					{
						type: 'container',
						styles: '',
						elements: [
							{
								id: 'workplaceSupports',
								caption: 'workplaceSupports',
								type: 'newTableRadioButtonGroup',
								stateField: 'workplaceSupports',
								elements: [common.getWorkplaceSupportsFeedback()],
							},
						],
					},
					{
						type: 'container',
						styles: '',
						elements: [getProfileDataElements(['workplaceSupportsOther'])],
					},
				],
			},
		]; //.concat(getProfileDataElements(['workplaceSupports']));
		return result;
	}

	private constructProfileCareerPreferences() {
		const result = [
			{
				type: 'container',
				styles: 'row',
				elements: [
					{
						type: 'container',
						styles: 'column',
						elements: getProfileDataElements(['desiredRole', 'mostRecentRole', 'workCategories']),
					},
					{
						type: 'container',
						styles: 'column',
						elements: getProfileDataElements([
							'education',
							'qualifications',
							'employmentOtherNotes',
						]),
					},
				],
			},
		];
		return result;
	}

	private constructProfileWorkHistory() {
		const result = [
			{
				type: 'container',
				styles: 'row',
				elements: [
					{
						type: 'container',
						styles: 'column',
						elements: getProfileDataElements(['workHistory']),
					},
				],
			},
		];
		return result;
	}

	private constructProfileTechQuestionnaire() {
		const result = [
			{
				type: 'container',
				styles: 'row',
				elements: [
					{
						type: 'container',
						styles: 'column',
						elements: getProfileDataElements(['jobTitles']),
					},
				],
			},
			{
				type: 'container',
				styles: 'row',
				elements: [
					{
						type: 'containerH3',
						styles: 'sectionHeading',
						elements: [
							{
								caption: 'Front-end Languages',
								type: 'text',
							},
						],
					},
					{
						id: 'FE',
						caption: 'FE languages',
						type: 'tableRadioButtonGroup',
						stateField: 'Front End Languages',
						elements: [common.getFElanguages()],
					},
					{
						type: 'containerH3',
						styles: 'sectionHeading',
						elements: [
							{
								caption: 'Back-end Languages',
								type: 'text',
							},
						],
					},
					{
						id: 'BE',
						caption: 'BE languages',
						type: 'tableRadioButtonGroup',
						stateField: 'Back End Languages',
						elements: [common.getBElanguages()],
					},

					{
						type: 'containerH3',
						styles: 'sectionHeading',
						elements: [
							{
								caption: 'Data Tools',
								type: 'text',
							},
						],
					},
					{
						id: 'dataTools',
						caption: 'dataTools',
						type: 'tableRadioButtonGroup',
						stateField: 'Data Tools',
						elements: [common.getDataTools()],
					},
				],
			},
			{
				type: 'container',
				styles: 'row',
				elements: [
					{
						type: 'container',
						styles: 'column',
						elements: getProfileDataElements(['otherTech', 'preferredTechLanguage']),
					},
				],
			},
		];
		return result;
	}

	private isProfileTechQuestionnaireHidden(controller: any) {
		return controller.state.techQuestionnaireEnabled
			? !(controller.state['industryCategory'] == 'Technology & Software')
			: true;
	}
}

export const ProfileBuilder = new ProfileImplementation();
