import { ApolloManager } from 'src/helpers/ApolloManager';
import getAllProficiencyTypes from './getAllProficiencyTypes.graphql';

interface ProficiencyTypes {
	allProficiencyTypes: {
		id: number;
		name: string;
		showInSidebar: boolean;
	}[];
}

class ProficiencyTypeImplementation {
	public readonly getAllProficiencyTypes = async (): Promise<ProficiencyTypes | undefined> => {
		try {
			const result = await ApolloManager.client.query<ProficiencyTypes>({
				query: getAllProficiencyTypes,
				fetchPolicy: 'no-cache',
			});
			if (result.data && result.data.allProficiencyTypes && result.data.allProficiencyTypes.length > 0) {
				return result.data;
			}
		} catch (error) {
			console.error(`Proficiency type data retrieval failed. ${error}`);
		}
		return undefined;
	};
} []

export const ProficiencyTypeManager = new ProficiencyTypeImplementation();
