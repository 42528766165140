import fetch from 'isomorphic-fetch';
import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client';
import { getApiUrl } from 'src/utils/Config';

const client = new ApolloClient({
	cache: new InMemoryCache(),
	link: new HttpLink({
		uri: getApiUrl(),
		fetch,
		fetchOptions: {
			// This ensures our cookies get sent with Apollo requests
			credentials: 'include',
		},
	}),
});

export const ApolloManager = {
	client,
};
