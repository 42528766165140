import { ApolloManager } from 'src/helpers/ApolloManager';
import addUserBadge from './addUserBadge.graphql';
import getBadgesWithKey from './getBadgesWithKey.graphql';

export interface Badge {
	id?: string;
	description?: string;
	image?: string;
	key?: string;
}

interface BadgesFetchResult {
	allBadges: Badge[];
}

interface BadgeGetData {
	key: string;
}

interface Result {
	success: boolean;
	error?: string;
}

interface UserBadgeCreation {
	user: number;
	badge: number;
}

//interface 

class BadgeImplementation {
	public readonly addUserBadge = async (userBadgeData: UserBadgeCreation): Promise<Result> => {
		let success: boolean = false;
		try {
			const result = await ApolloManager.client.mutate<UserBadgeCreation>({
				mutation: addUserBadge,
				variables: userBadgeData,
			});
			success = result ? true : false;
		} catch (error) {
			console.error(`User Badge creation failed.`);
		}
		return {
			success: success,
			error: !success
				? `Failed to Create User Badge.`
				: undefined,
		};
	};

	public readonly getBadgesWithKey = async (badgeGetData: BadgeGetData): Promise<Badge[]> => {
		try {
			const result = await ApolloManager.client.query<BadgesFetchResult>({
				query: getBadgesWithKey,
				variables: badgeGetData,
			});
			if (result.data.allBadges) {
				return result.data.allBadges;
			} else {
				return [];
			}
		} catch (error) {
			console.error(`An error occurred fetching badges: Error was ${error}`);
		}
		return [];
	};
}

export const BadgeManager = new BadgeImplementation();
