import React, { Fragment, useState } from 'react';
import classNames from 'classnames';
import styles from './styles.module.css';
import { CSSTransition } from 'react-transition-group';
import slideLeftTransition from './transitions/slideLeft.module.css';

import { LeftArrowSVG } from './components/LeftArrowSVG';
import { XCeptionalLogoSVG } from 'src/components/SVGs/XCeptionalLogoSVG';
import { NavMenuItemList } from './components/NavMenuItemList';
import { Profile } from './components/Profile';
import { KeystoneUser } from 'src/helpers/UserManager';

interface Props {
	user?: KeystoneUser;
	onUserSignOut?: any;
}

export const LeftNav = (props: Props) => {
	const [expanded, setExpanded] = useState(true);
	return (
		<div
			className={classNames(styles.leftNav, {
				[styles.closed]: !expanded,
			})}
		>
			<div className={styles.logoContainer}>
				{expanded && (
					<div
						className={classNames(styles.heading, {
							[styles.closedHeading]: !expanded,
						})}
					>
						<XCeptionalLogoSVG />
						<h1 className={styles.topLogo}>Xceptional</h1>
					</div>
				)}
				<button
					className={expanded ? styles.svgExpanded : styles.svgClosed}
					onClick={() => {
						setExpanded(!expanded);
					}}
					aria-label={expanded ? 'Close Left Nav' : 'Open Left Nav'}
				>
					<LeftArrowSVG />
				</button>
			</div>
			<CSSTransition in={expanded} timeout={250} classNames={slideLeftTransition} unmountOnExit>
				<div>
					<Profile user={props.user} />
					<div className={styles.navItems}>
						<NavMenuItemList onUserSignOut={props.onUserSignOut} />
					</div>
				</div>
			</CSSTransition>
		</div>
	);
};
