import nanoid from 'nanoid';
import nanoidGenerate from 'nanoid/generate';
import { Interest } from 'src/model/Interest';
import { ApolloManager } from 'src/helpers/ApolloManager';
import authenticateUser from './authenticateUser.graphql';
import createUser from './createUser.graphql';
import signOut from './signOut.graphql';
import getAuthenticatedUser from './getAuthenticatedUser.graphql';
import updateUser from './updateUser.graphql';
import setInterests from './setInterests.graphql';
import getUserPermissions from './getUserPermissions.graphql';
import sendUserMagicAuthLink from './sendUserMagicAuthLink.graphql';
import redeemUserMagicAuthLink from './redeemUserMagicAuthLink.graphql';
import sendUserPasswordResetLink from './sendUserPasswordResetLink.graphql';
import redeemUserPasswordResetLink from './redeemUserPasswordResetLink.graphql';
import getIsVerified from './getIsVerified.graphql';
import getUserCountry from './getUserCountry.graphql';
import { getProfileData } from 'src/components/PageBuilder/component';
import { SettingsManager } from '../SettingsManager';
import {
	AssessmentAndRecruitmentValue,
	generateProductsAndServices,
} from 'src/utils/generateProductsAndServices';
import { PuzzleManager } from 'src/helpers/PuzzleManager';
import { PuzzleSessionManager } from 'src/helpers/PuzzleSessionManager';
import { Registration } from '../RegistrationManager';
import { GroupManager } from '../GroupManager';

export interface KeystoneUser {
	id?: number;
	email?: string;
	firstName?: string;
	lastName?: string;
	password?: string;
	isVerified?: boolean;
	guest?: boolean;
	password_is_set?: boolean;
	interests?: Interest[];
	skills?: any;
	badges?: any;
	emailingProfile?: boolean;
	profileURL?: string;
	profileAsJson?: string;
	country?: string;
	registrations?: Registration[];
	groups?: any;
}

interface UpdateUserResult {
	updateUser?: KeystoneUser;
}

interface AuthenticatedUserResult {
	authenticatedUser?: KeystoneUser;
}

interface UserPermissionsResult {
	allUsers: {
		id: number;
		isAdmin: Boolean;
		isSuperUser: Boolean;
	}[];
}

interface AuthenticateResult {
	authenticate?: {
		token?: string;
		item?: KeystoneUser;
	};
}

interface CreateUserResult {
	createUser: KeystoneUser;
}

interface AuthenticationResult {
	user?: KeystoneUser;
	error?: string;
}

interface SignOutResult {
	unauthenticateUser: {
		success: boolean;
	};
	error: string;
}

interface SignedOutResult {
	success?: boolean;
	error?: string;
}

interface UserCreatedResult {
	user?: KeystoneUser;
	error?: string;
}

interface GuestUserCreatedResult {
	user?: KeystoneUser;
	error?: string;
}

interface AuthCheckResult {
	allUsers: {
		id: number;
		name: string;
		email: string;
	}[];
}

interface UserUpdatedResult {
	user?: KeystoneUser;
	error?: string;
}

interface SetUserInterestsResult {
	setInterests: boolean;
}

interface UserResult {
	id: number;
	name: string;
	email: string;
}

interface MagicAuthCreateResult {
	sendUserMagicAuthLink: boolean;
}

interface MagicAuthRedeemResult {
	redeemUserMagicAuthToken: {
		__typename: String;
	};
}

interface PasswordResetCreateResult {
	sendUserPasswordResetLink: boolean;
}

interface PasswordResetRedeemResult {
	redeemUserPasswordResetToken: {
		__typename: String;
	};
}

interface UserIsVerifiedResult {
	users: {
		isVerified: boolean;
	}[];
}

interface getUserCountryResult {
	getUserCountry?: userCountryResult;
}

export interface userCountryResult {
	country?: string;
	error?: string;
}

type SignInStatusCallback = (status: boolean) => any;

export const signInAsGuestUserIfRequired = async () => {
	const authenticatedUser = await UserManager.getAuthenticatedUser();

	if (!authenticatedUser) {
		const guestCreationResult = await UserManager.createGuestUser();

		if (guestCreationResult.user?.email && guestCreationResult.user?.password) {
			const authResult = await UserManager.authenticate({
				email: guestCreationResult.user.email,
				password: guestCreationResult.user.password,
			});

			if (authResult.error) {
				// TODO: This is a fatal error. The user should not be able to continue. Update the UI to reflect this.
				console.error(
					`ERROR: Failed to log in as newly created guest user: ${guestCreationResult.user.email}`
				);
			} else {
				return guestCreationResult.user;
			}
		} else {
			console.error(`ERROR: Failed to create a guest user`);
		}
	}
	return authenticatedUser;
};

class UserImplementation {
	// ApolloClient doesn't provide any way to check whether we have a cookie or not, so let's keep track of that ourselves.
	isSignedInAsUser = false;

	/**
	 * Authenticates a user to the Keystone server.
	 *
	 * When a question is started, its 'dateTimeStarted' field value is set to the current date and time.
	 *
	 * @param {Object} credentials - The `username` and `password` of the user to authenticate.
	 *
	 * @returns {AuthenticationResult} - An object continaing the authenticated user or an error.
	 */
	public readonly authenticate = async (credentials: {
		email: string;
		password: string;
	}): Promise<AuthenticationResult> => {
		let message: string = '';
		try {
			credentials.email = credentials.email.toLocaleLowerCase();
			const result = await ApolloManager.client.mutate<AuthenticateResult>({
				mutation: authenticateUser,
				variables: credentials,
			});

			if (result.data?.authenticate?.token && result.data?.authenticate?.item) {
				// if not a guest
				if (!result.data.authenticate.item.guest) {
					// tell the subscribers we're logged in now
					this.handleAuthenticationStatusChange(true);
				}

				// We now have a cookie, so the token doesn't need to be stored anywhere.
				// Just remember that and go to the authenticated url they were trying to access.
				return { user: result.data?.authenticate.item };
			}
		} catch (error: any) {
			message = error.message;
		}

		return {
			error: `Failed to authenticate user ${credentials.email}. ${message}`,
		};
	};
	/**
	 * Creates a new user on the Keystone server.
	 *
	 * @param {string} name - The name of the user to create.
	 * @param {string} email - The email address (and username) of the user to create.
	 * @param {string} password - The new password of the user to create.
	 *
	 * @returns {UserCreatedResult} - An object continaing the created user or an error.
	 */
	public readonly createUser = async (
		newUser: {
			firstName: string;
			lastName: string;
			email: string;
			password: string;
			country?: string;
			profileAsJson?: string;
			groups?: any;
		},
		isGuest: boolean = false
	): Promise<UserCreatedResult> => {
		try {
			const variables = { ...newUser, guest: isGuest };
			const result = await ApolloManager.client.mutate<CreateUserResult>({
				mutation: createUser,
				variables,
			});
			if (result.data?.createUser) {
				// We now have a cookie, so the token doesn't need to be stored anywhere.
				// Just remember that and go to the authenticated url they were trying to access.
				if (!isGuest) {
					this.handleAuthenticationStatusChange(true);
				}
				return { user: result.data.createUser };
			}
		} catch (error: any) {
			console.error(`Error creating user for ${newUser.email}. Error was:`, error);
		}

		return {
			error: `Failed to create user for ${newUser.email}.`,
		};
	};

	/**
	 * Updates a user, removes guest flag, changes email, name and password
	 *
	 * @returns {UserUpdatedResult} - A boolean success flag and/or error if applicable.
	 */
	public readonly updateUser = async (userUpdateData: KeystoneUser): Promise<UserUpdatedResult> => {
		var message: string = '';
		// Remove elements that cause errors on update
		userUpdateData.badges = undefined;
		userUpdateData.interests = undefined;
		userUpdateData.skills = undefined;
		try {
			const result = await ApolloManager.client.mutate<UpdateUserResult>({
				mutation: updateUser,
				variables: userUpdateData,
			});

			if (result?.data?.updateUser?.id) {
				// tell the subscribers we're logged in now
				this.handleAuthenticationStatusChange(true);
				return { user: result.data.updateUser };
			}
		} catch (error: any) {
			if (
				error.message &&
				error.message.includes(`duplicate key value violates unique constraint "user_email_unique"`)
			) {
				message = `A user with this email already exists.`;
			} else {
				message = `Failed to update user ${userUpdateData.email}. ${message}`;
			}
		}
		return {
			error: message,
		};
	};

	public readonly setInterestsForAuthenticatedUser = async (
		interests: Interest[]
	): Promise<boolean> => {
		try {
			const user = await this.getAuthenticatedUser();
			if (!user) {
				const msg =
					process.env.NODE_ENV === 'production'
						? 'No Authenticated User'
						: 'No Authenticated User.  Check local.config.json is correct.';
				throw new Error(msg);
			}
			const id = user.id;

			const variables = {
				id: id,
				interests: {
					connect: interests.map((i) => JSON.parse(`{"id":${String(i.id)}}`)),
					disconnect: user.interests?.map((i) => JSON.parse(`{"id":${String(i.id)}}`)),
				},
			};
			const result = await ApolloManager.client.mutate<SetUserInterestsResult>({
				mutation: setInterests,
				variables: variables,
			});
			return !!result?.data ? true : false;
		} catch (error: any) {
			console.error(`Error updating user interests (${JSON.stringify(interests)}).`, error);
		}
		return false;
	};

	/**
	 * Creates a user but with practically completely unique and random values.
	 *
	 * @returns {GuestUserCreatedResult} - A KeystoneUser (augmented with the real password) or an error.
	 */
	public readonly createGuestUser = async (): Promise<GuestUserCreatedResult> => {
		const uniqueStr = nanoidGenerate('0123456789-ABCDEF', 30);
		const email = `${uniqueStr}@exceptional.guest`;
		const password = nanoid();
		const firstName = `GUEST`;
		const lastName = `USER`;
		const groups = {
			connect: (await GroupManager.getAllGroups())
				.filter((group) => !!group.default)
				.map((group) => {
					return { id: Number(group.id) };
				}),
		};
		const result = await this.createUser({ email, firstName, lastName, password, groups }, true);
		if (!result.error) {
			return {
				user: { ...result.user, password },
			};
		} else {
			return { error: result.error };
		}
	};

	/**
	 * Signs out the already signed in user. If no user is signed in, and the sign out was
	 * otherwise successful, success will be returned.
	 *
	 * @returns {SignedOutResult} - A boolean success flag and/or error if applicable.
	 */
	public readonly signOut = async (): Promise<SignedOutResult> => {
		try {
			const result = await ApolloManager.client.mutate<SignOutResult>({
				mutation: signOut,
				// variables: {},
			});
			if (result.data && result.data.unauthenticateUser) {
				ApolloManager.client.cache.reset();
				this.handleAuthenticationStatusChange(false);
				return { success: true };
			} else {
				console.error(`Error signing user out. Result was:`, result);
				return { error: `Error signing user out.` };
			}
		} catch (error: any) {
			console.error(`Error signing user out. Error was:`, error);
		}

		return {
			success: false,
			error: `An error occurred signing the user out.`,
		};
	};

	public readonly getAuthenticatedUser = async (): Promise<KeystoneUser | undefined> => {
		try {
			const result = await ApolloManager.client.query<AuthenticatedUserResult>({
				query: getAuthenticatedUser,
				fetchPolicy: 'no-cache',
			});
			if (result?.data?.authenticatedUser) {
				if (!result.data.authenticatedUser.guest) {
					this.handleAuthenticationStatusChange(true);
				}
				return result.data.authenticatedUser;
			}
		} catch (error: any) {
			console.error(`Error getting authenticated user: Error ${error}`);
		}
	};

	public readonly isGuestUser = async () =>
		await this.getAuthenticatedUser().then((user) => {
			return user?.guest ? true : false;
		});

	public readonly getAuthenticatedUserId = async () =>
		await this.getAuthenticatedUser().then((user) => user?.id);

	public readonly getAuthenticatedUserPermissions = async () => {
		try {
			const id = await this.getAuthenticatedUserId();
			const result = await ApolloManager.client.query<UserPermissionsResult>({
				query: getUserPermissions,
				variables: { id },
				fetchPolicy: 'no-cache',
			});

			if (result?.data?.allUsers.length == 1) {
				return result.data.allUsers[0];
			} else {
				return undefined;
			}
		} catch (error: any) {
			console.error(`Error getting authenticated user: Error ${error}`);
		}
	};

	/* Notify Subscribers Functionality*/
	private subscribers: SignInStatusCallback[] = [];
	handleAuthenticationStatusChange = (signedIn: boolean) => {
		if (this.isSignedInAsUser !== signedIn) {
			this.isSignedInAsUser = signedIn;
			this.notifySubscribers();
		}
	};

	private readonly notifySubscribers = () => {
		for (const subscriber of this.subscribers) {
			subscriber(this.isSignedInAsUser);
		}
	};

	public subscribeToSignInChanges = (callback: SignInStatusCallback) => {
		this.subscribers.push(callback);
	};

	public unsubscribeFromSignInChanges = (callback: SignInStatusCallback) => {
		this.subscribers = this.subscribers.filter((subscriber) => subscriber !== callback);
	};

	public userHasCompletedProfileDetails = async (user: KeystoneUser) => {
		try {
			const techQuestionnaireEnabled =
				(await SettingsManager.getSetting('TechQuestionnaireEnabled', user.id)) == 'true'
					? true
					: false;
			if (!user.profileAsJson) return false;
			const profile = JSON.parse(user.profileAsJson);
			return await this.profileDetailsCompleted(profile, techQuestionnaireEnabled);
		} catch (error) {
			console.error(`Error accessing user's profile: ${error}`);
		}
		return false;
	};

	public profileDetailsCompleted = (profile: any, techQuestionnaireEnabled: boolean) => {
		try {
			let completed = true;
			getProfileData().map((element: any) => {
				if (element.isRequired) {
					// Skip checking Tech questionnaire fields if candidate is not eligible
					if (
						(!element.isTechQuestionnaire ||
							(techQuestionnaireEnabled &&
								profile['industryCategory'] == 'Technology & Software')) &&
						(!(element.id == 'workRights') || profile['country'] == 'Australia')
					) {
						if (
							(profile[element.stateField] &&
								Array.isArray(profile[element.stateField]) &&
								profile[element.stateField].length == 0) ||
							(!profile[element.stateField] && typeof profile[element.stateField] != 'boolean')
						) {
							completed = false;
						}
					}
				}
			});
			return completed;
		} catch (error) {
			console.error(`Error parsing profile: ${error}`);
		}
		return false;
	};

	public sendUserMagicAuthLinkForAuthenticatedUser = async () => {
		try {
			const email = (await this.getAuthenticatedUser())?.email;
			const result = await ApolloManager.client.query<MagicAuthCreateResult>({
				query: sendUserMagicAuthLink,
				variables: { email },
				fetchPolicy: 'no-cache',
			});
			return result.data.sendUserMagicAuthLink;
		} catch (e) {
			console.error(`Error generating user authentication token: ${e}`);
		}
		return false;
	};

	public redeemUserMagicAuthLink = async (variables: { email: String; token: String }) => {
		try {
			const result = await ApolloManager.client.query<MagicAuthRedeemResult>({
				query: redeemUserMagicAuthLink,
				variables,
				fetchPolicy: 'no-cache',
			});
			if (result.data.redeemUserMagicAuthToken.__typename === 'RedeemUserMagicAuthTokenSuccess') {
				return true;
			} else {
				console.error(`User code redemption failed`);
			}
		} catch (e) {
			console.error(`Error parsing user authentication token: ${e}`);
		}
		return false;
	};

	public sendUserPasswordResetLinkToEmail = async (email: String) => {
		try {
			const result = await ApolloManager.client.query<PasswordResetCreateResult>({
				query: sendUserPasswordResetLink,
				variables: { email },
				fetchPolicy: 'no-cache',
			});
			return result.data.sendUserPasswordResetLink;
		} catch (e) {
			console.error(`Error generating user authentication token: ${e}`);
		}
		return false;
	};

	public redeemUserPasswordResetLink = async (variables: {
		email: String;
		password: String;
		token: String;
	}) => {
		try {
			const result = await ApolloManager.client.query<PasswordResetRedeemResult>({
				query: redeemUserPasswordResetLink,
				variables,
				fetchPolicy: 'no-cache',
			});
			if (result.data.redeemUserPasswordResetToken === null) {
				return true;
			} else {
				console.error(`User code redemption failed`);
			}
		} catch (e) {
			console.error(`Error parsing user authentication token: ${e}`);
		}
		return false;
	};
	public userIsVerified = async (id: number) => {
		try {
			const result = await ApolloManager.client.query<UserIsVerifiedResult>({
				query: getIsVerified,
				variables: { id },
				fetchPolicy: 'no-cache',
			});

			if (result?.data?.users.length == 1) {
				return result.data.users[0].isVerified;
			} else {
				return false;
			}
		} catch (error: any) {
			console.error(`Error getting isVerified for user: Error ${error}`);
		}
		return false;
	};
	public getProductsAndServices = async (user: KeystoneUser) => {
		try {
			if (!user.profileAsJson) return undefined;
			return JSON.parse(user.profileAsJson)['Products & Services'];
		} catch (error) {
			console.error(`Error accessing user's profile: ${error}`);
		}
		return undefined;
	};

	public isAssessmentRecruitment = async (user: KeystoneUser) => {
		if (!user || user.guest) return false;
		const productsAndServices =
			(await this.getProductsAndServices(user)) || (await this.createProductsAndServices(user));
		return productsAndServices == AssessmentAndRecruitmentValue;
	};

	public createProductsAndServices = async (user: KeystoneUser) => {
		if (!user || user.guest) return false;
		const productsAndServices = await generateProductsAndServices(user.country);
		try {
			const profileAsJson = JSON.stringify(
				Object.assign(user.profileAsJson ? JSON.parse(user.profileAsJson) : {}, {
					'Products & Services': productsAndServices,
				})
			);
			await UserManager.updateUser({ id: user.id, profileAsJson });
		} catch (error) {
			console.error(`Error accessing user's profile: ${error}`);
		}
		return productsAndServices;
	};

	public allPuzzlesCompleteForUser = async (id?: number) => {
		if (!!!id) return false;
		const completedPuzzles = await PuzzleSessionManager.getCompletedPuzzleIdsForUser(id);
		return (
			(await PuzzleManager.getAppropriatePuzzlesForUser(id)).filter((puzzle) => {
				return puzzle.required && !completedPuzzles.includes(puzzle.id);
			}).length == 0
		);
	};

	public allPuzzlesCompleteForAuthUser = async () => {
		return this.allPuzzlesCompleteForUser(await this.getAuthenticatedUserId());
	};

	public readonly getUserCountry = async (
		ipAddress: string,
		countryList: string
	): Promise<userCountryResult> => {
		const variables = {
			ipAddress,
			countryList,
		};
		try {
			const result = await ApolloManager.client.query<getUserCountryResult>({
				query: getUserCountry,
				variables,
			});
			if (!result.data?.getUserCountry) {
				throw new Error('No data received for request to getUserCountry');
			}
			return { country: result.data?.getUserCountry?.country };
		} catch (error: any) {
			console.error(`Error getting country`, error);
		}
		return {
			error: `Failed to get country`,
		};
	};
}

export const UserManager = new UserImplementation();
