export const getApiUrl = () => {
	if (process.env.GATSBY_REVIEW_ID && Number(process.env.GATSBY_REVIEW_ID) > 0) {
		if (!process.env.GATSBY_API_PR_URL) {
			throw new Error('Environment variable GATSBY_API_PR_URL not set.');
		}
		const url = process.env.GATSBY_API_PR_URL.replace(
			'${REVIEW_ID}',
			'51' + process.env.GATSBY_REVIEW_ID
		);
		console.info(`GATSBY_API_PR_URL: ${url}`);
		return url;
	}

	if (process.env.GATSBY_API_URL) {
		console.info(`GATSBY_API_URL: ${process.env.GATSBY_API_URL}`);
		return process.env.GATSBY_API_URL;
	}

	try {
		const config = require('src/../local.config.json');
		console.info(`GATSBY_API_URL (from local.config.json): ${config.GATSBY_API_URL}`);
		return config.GATSBY_API_URL;
	} catch (e: any) {
		throw new Error(`Failed to load file local.config.json. ${e.message}`);
	}
};

export const getStripeEnvironmentVariables = () => {
	let stripeEnvironmentVariables: any = {
		publishableKey: process.env.GATSBY_STRIPE_PUBLISHABLE_KEY,
		buyButtonIdAud: process.env.GATSBY_STRIPE_BUY_BUTTON_ID_AUD,
		buyButtonIdUsd: process.env.GATSBY_STRIPE_BUY_BUTTON_ID_USD,
	};

	if (
		!stripeEnvironmentVariables.publishableKey ||
		!stripeEnvironmentVariables.buyButtonIdAud ||
		!stripeEnvironmentVariables.buyButtonIdUsd
	) {
		try {
			const config = require('src/../local.config.json');
			stripeEnvironmentVariables.publishableKey =
				stripeEnvironmentVariables.publishableKey || config.GATSBY_STRIPE_PUBLISHABLE_KEY;
			stripeEnvironmentVariables.buyButtonIdAud =
				stripeEnvironmentVariables.buyButtonIdAud || config.GATSBY_STRIPE_BUY_BUTTON_ID_AUD;
			stripeEnvironmentVariables.buyButtonIdUsd =
				stripeEnvironmentVariables.buyButtonIdUsd || config.GATSBY_STRIPE_BUY_BUTTON_ID_USD;
		} catch (e: any) {
			throw new Error(`Failed to load file local.config.json. ${e.message}`);
		}
	}
	return stripeEnvironmentVariables;
};
