import React, { useState } from 'react';
import styles from './styles.module.css';
import colors from 'src/css/colors.module.css';
import { ChatBubbleSVG } from './components/ChatBubbleSVG';
import closeX from 'src/images/CloseX.png';
import { TextInput } from 'src/components/TextInput';
import { ArrowButton } from 'src/components/ArrowButton';
import { showMessage } from 'src/components/Message';
import { Checkbox } from 'src/components/Checkbox';

interface Props {
	controller: any;
	comment: string;
	isBottomBox: boolean;
	onCreateAccountClick(
		firstName: string,
		lastName: string,
		email: string,
		password: string,
		country?: string
	): any;
	afterAccountCreated?: () => void;
	hasValidResponses(firstName: string, lastName: string, email: string, password: string): boolean;
	onAccountCreated(): void;
	onEmailValidate(email: string): boolean;
	onPwdValidate(password: string): boolean;
	country?: string;
}

export const SignUpPopupNotification = (props: Props) => {
	const [visible, setVisible] = useState(props.isBottomBox);
	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [hasUserConsented, setHasUserConsented] = useState(false);

	const triggerStateUpdate = () => {
		props.controller && props.controller.setState({});
	};

	const createUser = async (
		firstName: string,
		lastName: string,
		email: string,
		password: string
	) => {
		const duration = 6000;
		let result: boolean = false;
		await props
			.onCreateAccountClick(firstName, lastName, email, password, props.country)
			.then(async (response: any) => {
				result = response && response.success;
				if (result) {
					props.afterAccountCreated && (await props.afterAccountCreated());
					showMessage({ caption: 'Account Created.', autoCloseDelay: duration });
				} else {
					showMessage({ caption: `Oh no! ${response.message}`, autoCloseDelay: duration });
				}
			})
			.catch((error: any) => {
				showMessage({
					caption: `Account creation failed. ${error.message}`,
					autoCloseDelay: duration,
				});
			});
		triggerStateUpdate();
		return result;
	};

	return (
		<>
			{visible ? (
				<div className={props.isBottomBox ? styles.bottomNotificationBox : styles.notificationBox}>
					<button onClick={() => setVisible(false)} className={styles.closeButton}>
						{/*	close icon is not an svg? Can't export from Lunacy/invision.*/}
						<img src={closeX} className={styles.close} alt="Close" />
					</button>
					<div>
						<ChatBubbleSVG mainColor={colors.SVGGreen} bgColor={colors.SVGLightGreen} />
					</div>
					<div className={styles.questionText}>{props.comment}</div>
					<div className={styles.inputFields}>
						<TextInput
							caption="First Name"
							value={firstName}
							onValueChanged={(value) => setFirstName(value)}
							additionalStyles={styles}
							isRequired
						/>
						<TextInput
							caption="Last Name"
							value={lastName}
							onValueChanged={(value) => setLastName(value)}
							additionalStyles={styles}
							isRequired
						/>
						<TextInput
							caption="Email"
							value={email}
							onValueChanged={(value) => setEmail(value.trim())}
							additionalStyles={styles}
							isRequired
							validate={props.onEmailValidate}
							validationMsg={'Please provide a valid email'}
						/>
						<TextInput
							caption="Password"
							value={password}
							onValueChanged={(value) => setPassword(value)}
							additionalStyles={styles}
							isPasswordInput
							isRequired
							validate={props.onPwdValidate}
						/>
						<div className={styles.passwordText}>
							Passwords require 8+ characters with a letter, number, and symbol
						</div>
						<Checkbox
							caption={
								<>
									I agree to{' '}
									<a href="https://xceptional.io/terms" target="_blank">
										Xceptional’s General Terms and Conditions
									</a>{' '}
									and{' '}
									<a href="https://xceptional.io/end-user-agreement" target="_blank">
										End User Agreement
									</a>
									.
								</>
							}
							checked={hasUserConsented}
							onChecked={(value) => {
								setHasUserConsented(value);
							}}
						/>
						<ArrowButton
							caption={'Create Account'}
							onClick={() =>
								createUser(firstName, lastName, email, password).then(
									(result) => result && props.onAccountCreated()
								)
							}
							isDisabled={
								!hasUserConsented || !props.hasValidResponses(firstName, lastName, email, password)
							}
							additionalStyles={styles}
						/>
					</div>
				</div>
			) : (
				<div
					className={styles.flippedBox}
					aria-label={'Flipped Notification'}
					tabIndex={0}
					onFocus={() => setVisible(true)}
					onClick={() => setVisible(true)}
				>
					<div>
						<ChatBubbleSVG mainColor={colors.white} bgColor={colors.SVGlightestBlue} />
					</div>
				</div>
			)}
		</>
	);
};
