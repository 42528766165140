import React from 'react';
import { TagList, SelectedValue } from 'src/components/TagList';

interface Props {
	caption: string;
	onChange(value: string): void;
	defaultOption?: string;
	options: string[];
	isRequired?: boolean;
	validate?: (value: any) => boolean;
	validationMsg?: string;
	isClearable?: boolean;
	width?: number;
}

export default class Dropdown2 extends React.Component<Props> {
	render() {
		return (
			<TagList
				{...this.props}
				isSingle={true}
				options={this.props.options.map((option) => {
					return { label: option, value: option };
				})}
				defaultValues={this.props.defaultOption ? [this.props.defaultOption] : []}
				onChange={(values: SelectedValue) => {
					this.props.onChange(values?.label || '');
				}}
			/>
		);
	}
}

export { Dropdown2 };
