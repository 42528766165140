import React from 'react';
import styles from './styles.module.css';
import classNames from 'classnames';
import { TextInput } from 'src/components/TextInput/component';
import { ArrowButton } from 'src/components/ArrowButton';
import { Dropdown } from 'src/components/Dropdown/component';
import { RadioButton } from 'src/components/RadioButton/component';
import * as PPB from '../Builder/component';
import { Checkbox } from 'src/components/Checkbox';
import { CheckboxGroup } from 'src/components/CheckboxGroup';
import { Tab, Tabs } from 'src/components/Tabs/Tabs';
import { RequiredFieldAsterisk } from 'src/components/SVGs/RequiredFieldAsterisk';
import { TagList } from 'src/components/TagList';
import { Grid } from 'src/components/Grid';

let nextKey = 1;

export function getProfilePage(controller: any) {
	resetKeys();
	return PPB.getProfilePageLayout.map((element: any) => {
		return unmapElement(element, controller);
	});
}

export function getQuestionnairePage(controller: any) {
	resetKeys();
	return PPB.getQuestionnairePageLayout.map((element: any) => {
		return unmapElement(element, controller);
	});
}

export function getProfileDownloadPage(controller: any) {
	resetKeys();
	return PPB.getProfileDownloadPageLayout.map((element: any) => {
		return unmapElement(element, controller);
	});
}

export function setProfileDataCountries(countries: { caption: string }[]) {
	if (countries) {
		countries = countries.map((country: any) => {
			return { caption: country.caption };
		});
	}
	PPB.ProfileData.map((element: any) => {
		if (element.id == 'Country') element.options = countries;
	});
	return true;
}

export function getWorkplaceSupports() {
	return PPB.getWorkplaceSupportsTextOnly().map((support) => {
		return {
			name: `${support}`,
			required: false,
			preferred: false,
		};
	});
}

export function getTechLanguages() {
	return PPB.getTechLanguages();
}

function safeRender(element: any, controller: any, f: any) {
	try {
		return f(element, controller);
	} catch (e: any) {
		console.warn(
			`Unable to render ${
				element?.caption ? element.caption : 'unknown'
			} element. Reported error: ${e.message}`
		);
		return (
			<div>{`Error rendering element ${
				element?.caption ? element.caption : element?.id ? element.id : 'unknown'
			}`}</div>
		);
	}
}

function radioButton(element: any, selected: string, onChange: () => void, value: any = undefined) {
	return (
		<RadioButton
			key={element.caption}
			text={element.caption}
			value={value ? value : element.value ? element.value : element.caption}
			selected={selected}
			onChange={onChange}
		/>
	);
}

function checkbox(
	element: any,
	controller: any,
	multiState: boolean,
	stateField1: any,
	stateField2: any
) {
	return (
		<div key={getNextKey()}>
			<Checkbox
				key={getNextKey()}
				checked={
					multiState
						? controller.state[stateField1]
							? controller.state[stateField1][stateField2] === 'true'
							: false
						: controller.state[stateField1] === 'true'
				}
				onChecked={(checked) => {
					const update: any = {};
					if (multiState) {
						update[stateField1] = controller.state[stateField1]
							? controller.state[stateField1]
							: [];
						update[stateField1][stateField2] = String(checked);
					} else {
						update[stateField1] = String(checked);
					}
					controller.setState(update);
				}}
				caption={element.caption}
			/>
		</div>
	);
}

function newCheckbox(element: any, checked: any, onChecked: any) {
	return (
		<div key={getNextKey()}>
			<Checkbox
				key={getNextKey()}
				checked={checked}
				onChecked={onChecked}
				caption={element.caption}
			/>
		</div>
	);
}

const mappings = [
	// Elements
	{
		type: 'tabs',
		function: (e: any, c: any) => {
			return safeRender(e, c, (element: any, controller: any) => {
				return (
					<Tabs
						vertical={element.vertical}
						key={element.key ? element.key : element.id}
						controller={controller}
					>
						{unmapElements(element.elements, controller)}
					</Tabs>
				);
			});
		},
	},
	{
		type: 'tabpane',
		function: (e: any, c: any) => {
			const hidden = e.hidden ? (typeof e.hidden == 'function' ? e.hidden(c) : e.hidden) : false;
			return !hidden ? (
				<Tab title={e.caption ? e.caption : 'untitled'} key={e.key ? e.key : e.id}>
					{unmapElements(e.elements, c)}
				</Tab>
			) : null;
		},
	},
	{
		type: 'textInput',
		function: (e: any, c: any) => {
			return safeRender(e, c, (element: any, controller: any) => {
				return (
					<TextInput
						caption={element.caption}
						isRequired={element.isRequired}
						value={controller.state[element.stateField]}
						onValueChanged={(value, error) => {
							const update: any = {};
							update[element.stateField] = value;
							update.errors = Object.assign(controller.state.errors, {
								[element.stateField]: error,
							});
							controller.setState(update);
						}}
						isMultiLine={element.isMultiLine}
						key={element.key ? element.key : element.id}
						validate={element.validate}
						validationMsg={element.validationMsg}
					>
						{unmapElements(element.elements, controller)}
					</TextInput>
				);
			});
		},
	},
	{
		type: 'arrowButton',
		function: (e: any, c: any) => {
			return (
				<ArrowButton
					key={getNextKey()}
					caption={e.caption}
					isBackButton={e.isBackButton}
					onClick={
						typeof e.onClick == 'function'
							? e.params
								? () => {
										e.onClick(c, e.params);
								  }
								: e.onClick
							: e.onClick
					}
					isDisabled={typeof e.isDisabled == 'function' ? (() => e.isDisabled(c))() : e.isDisabled}
				/>
			);
		},
	},
	{
		type: 'dropdown',
		function: (e: any, c: any) => {
			return safeRender(e, c, (element: any, controller: any) => {
				const multiState = Array.isArray(element.stateField);
				const stateField1 = multiState ? element.stateField[0] : element.stateField;
				const stateField2 = multiState ? element.stateField[1] : '';
				// DropDown component has a crash bug on undefined value. These value checks are to mitigate that bug
				const value = multiState
					? controller.state[stateField1]
						? controller.state[stateField1][stateField2]
							? controller.state[stateField1][stateField2]
							: ''
						: ''
					: controller.state[stateField1];
				return (
					<Dropdown
						caption={element.caption}
						options={element.options.map((option: any) => {
							return option.caption;
						})}
						showSearch={element.showSearch}
						value={value}
						onChange={(v) => {
							const update: any = {};
							if (multiState) {
								update[stateField1] = controller.state[stateField1]
									? controller.state[stateField1]
									: [];
								update[stateField1][stateField2] = v || '';
							} else {
								update[stateField1] = v || '';
							}
							controller.setState(update);
						}}
						key={element.key ? element.key : element.id}
						isRequired={element.isRequired}
					>
						{unmapElements(element.elements, controller)}
					</Dropdown>
				);
			});
		},
	},
	{
		type: 'taglist',
		function: (e: any, c: any) => {
			return safeRender(e, c, (element: any, controller: any) => {
				const value = controller.state[element.stateField] ?? '';
				return (
					<TagList
						{...element}
						options={element.options.map((option: any) => {
							return { label: option.caption, value: option.caption };
						})}
						defaultValues={value}
						onChange={(v) => {
							const update: any = {};
							update[element.stateField] = v.map((item: any) => item.value);
							controller.setState(update);
							element.onChange && element.onChange(v);
						}}
						key={element.key ? element.key : element.id}
					>
						{unmapElements(element.elements, controller)}
					</TagList>
				);
			});
		},
	},
	{
		type: 'grid',
		function: (e: any, c: any) => {
			return safeRender(e, c, (element: any, controller: any) => {
				const data =
					element.stateField && !!controller.state[element.stateField]
						? JSON.parse(controller.state[element.stateField])
						: [];
				const tableData = { ...element.tableData };
				if (data) tableData.data = data;
				const saveData = (tableData: any) => {
					const update: any = {};
					update[element.stateField] = JSON.stringify(tableData.data);
					controller.setState(update);
				};
				return (
					<Grid
						key={1}
						{...element}
						tableData={tableData}
						onRowAdded={(tableData: any) => {
							saveData(tableData);
							element.props?.onRowAdded && element.props.onRowAdded(tableData);
						}}
						onRowDeleted={(tableData: any) => {
							saveData(tableData);
							element.props?.onRowDeleted && element.props.onRowDeleted(tableData);
						}}
						onRowEdited={(tableData: any) => {
							saveData(tableData);
							element.props?.onRowEdited && element.props.onRowEdited(tableData);
						}}
					/>
				);
			});
		},
	},
	{
		type: 'text',
		function: (element: any, controller: any) => {
			// console.log(
			// 	`Rendering ${JSON.stringify(element)} with caption ${
			// 		element.stateField ? controller.state[element.stateField] : element.caption
			// 	}`
			// );
			return element.stateField ? controller.state[element.stateField] : element.caption;
		},
	},
	{
		type: 'radioButton',
		function: (element: any, controller: any) => {
			const multiState = Array.isArray(element.stateField);
			const elementValue = element.value ? element.value : element.caption;
			const stateField1 = multiState ? element.stateField[0] : element.stateField;
			const stateField2 = multiState
				? element.stateFieldAppendValue
					? element.stateField[1] + elementValue
					: element.stateField[1]
				: '';
			const selected: string = multiState
				? String(controller.state[stateField1][stateField2])
				: String(controller.state[stateField1]);
			const onChange: () => void = () => {
				const update: any = {};
				if (multiState) {
					update[stateField1] = controller.state[stateField1] ? controller.state[stateField1] : [];
					update[stateField1][stateField2] = elementValue;
				} else {
					update[stateField1] = elementValue;
				}
				controller.setState(update);
			};
			return radioButton(element, selected, onChange);
		},
	},
	{
		type: 'radioButtonGroup',
		function: (elementGroup: any, controller: any) => {
			const heading = { ...elementGroup };
			heading.elements = [{ type: 'text', caption: elementGroup.caption }];
			return (
				<div key={getNextKey()} className={styles.bottomMargin}>
					{getContainerH3(heading, controller)}
					{elementGroup.options.map((element: any) => {
						const multiState = Array.isArray(elementGroup.stateField);
						const elementValue = element.value ? element.value : element.caption;
						const stateField1 = multiState ? elementGroup.stateField[0] : elementGroup.stateField;
						const stateField2 = multiState
							? elementGroup.stateFieldAppendValue
								? elementGroup.stateField[1] + elementValue
								: elementGroup.stateField[1]
							: '';
						const selected: string = multiState
							? String(controller.state[stateField1][stateField2])
							: String(controller.state[stateField1]);
						const onChange: () => void = () => {
							const update: any = {};
							if (multiState) {
								update[stateField1] = controller.state[stateField1]
									? controller.state[stateField1]
									: [];
								update[stateField1][stateField2] = elementValue;
							} else {
								update[stateField1] = elementValue;
							}
							controller.setState(update);
						};
						return radioButton(element, selected, onChange);
					})}
				</div>
			);
		},
	},
	{
		type: 'tableRadioButtonGroup',
		function: (elementTableGroup: any, controller: any) => {
			let row = -1;
			return (
				<div key={getNextKey()} className={styles.tableWrapper}>
					<table key={getNextKey()} className={styles.table}>
						<tbody key={getNextKey()}>
							{elementTableGroup.elements.map((elementGroup: any, __index: number) => {
								return elementGroup.elements.map((element: any, _index: number) => {
									row += 1;
									const heading = { ...element };
									heading.elements = [{ type: 'text', caption: element.caption }];
									// e.g. workSupports
									const stateField1 = elementTableGroup.stateField;
									const controllerState = controller.state[stateField1]
										? controller.state[stateField1]
										: [];
									// e.g. physical supports
									const stateField2 = element.value ? element.value : element.caption;
									let targetIndex = controllerState.findIndex(
										(updateField: any) => updateField.name == stateField2
									);
									if (targetIndex == -1) {
										controllerState.push({ name: stateField2 });
										targetIndex = controllerState.findIndex(
											(updateField: any) => updateField.name == stateField2
										);
									}
									return (
										<tr key={getNextKey()} className={row % 2 ? styles.altrowcolour : undefined}>
											<td
												key={getNextKey()}
												className={classNames(styles.paragraph, styles.cappedWidth)}
											>
												{element.caption}
											</td>
											{element.options.map((option: any, index: number) => {
												// e.g. 'required'
												const stateField3 = option.stateField
													? typeof option.stateField != 'string'
														? option.stateField[1]
														: option.stateField
													: undefined;
												const value: string = option.onValue;

												// selected prefers to check its own state field, then falls back to checking nothing else is 'on'
												const selected: string =
													targetIndex != -1 && controllerState && controllerState[targetIndex]
														? stateField3
															? controllerState[targetIndex][stateField3]
															: !element.options.some((o: any) =>
																	o.stateField
																		? typeof o.stateField != 'string'
																			? controllerState[targetIndex][o.stateField[1]] == o.onValue
																			: controllerState[targetIndex][o.stateField] == o.onValue
																		: false
															  )
														: false;
												const onChange: () => void = () => {
													const update: any = {};
													update[stateField1] = controllerState;
													let targetIndex = update[stateField1].findIndex(
														(updateField: any) => updateField.name == stateField2
													);
													if (targetIndex == -1) {
														update[stateField1].push({ name: stateField2 });
														targetIndex = update[stateField1].length - 1;
													}
													element.options.map((option: any, i: number) => {
														if (option.stateField) {
															const stateField4 =
																typeof option.stateField != 'string'
																	? option.stateField[1]
																	: option.stateField;
															update[stateField1][targetIndex][stateField4] =
																i === index ? option.onValue : option.offValue;
														}
													});
													controller.setState(update);
												};
												return (
													<td key={getNextKey()}>
														{radioButton(option, selected, onChange, value)}
													</td>
												);
											})}
										</tr>
									);
								});
							})}
						</tbody>
					</table>
				</div>
			);
		},
	},
	{
		type: 'newTableRadioButtonGroup',
		function: (elementTableGroup: any, controller: any) => {
			let row = -1;
			return (
				<div key={getNextKey()} className={styles.tableWrapper}>
					<table key={getNextKey()} className={styles.table}>
						<tbody key={getNextKey()}>
							{elementTableGroup.elements.map((elementGroup: any, __index: number) => {
								return elementGroup.elements.map((element: any, _index: number) => {
									row += 1;
									// e.g. Physical_Adjustments
									const stateFieldBase = element.stateField;
									const stateFields: any[] = element.options.map((option: any) => {
										if (option.prefix) {
											// e.g. Required_Physical_Adjustments
											const field = `${option.prefix}${stateFieldBase}`;
											const getControllerState = () => {
												const result: any = {};
												if (controller.state[field]) {
													result[field] = controller.state[field];
												} else {
													result[field] = [];
													controller.setState(result);
												}
												return result;
											};
											const isSet = (value: string) => {
												return controller.state[field] && controller.state[field].includes(value);
											};
											const add = (value: string) => {
												const controllerState = getControllerState();
												if (!controllerState[field].includes(value)) {
													controllerState[field].push(value);
													controller.setState(controllerState);
												}
											};
											const remove = (value: string) => {
												const controllerState = getControllerState();
												if (controllerState[field].includes(value)) {
													const index = controllerState[field].indexOf(value);
													if (index != -1) controllerState[field].splice(index, 1);
													controller.setState(controllerState);
												}
											};
											return { field, isSet, add, remove };
										}
									});

									return (
										<tr key={getNextKey()} className={row % 2 ? styles.altrowcolour : undefined}>
											<td
												key={getNextKey()}
												className={classNames(styles.paragraph, styles.cappedWidth)}
											>
												{element.caption}
											</td>
											{element.options.map((option: any) => {
												// e.g. Headphones or other noise adjustments
												const value: string = element.value ? element.value : element.caption;
												const isNotSelectedOption = !option.prefix;
												const optionStateFields = stateFields.filter((stateField: any) => {
													{
														// This must be an explicit return statement, otherwise there is a render/state issue
														return (
															stateField && stateField.field == `${option.prefix}${stateFieldBase}`
														);
													}
												});
												const optionStateField =
													optionStateFields && optionStateFields.length > 0
														? optionStateFields[0]
														: undefined;
												const selectedBool = !isNotSelectedOption
													? (() => {
															return optionStateField && optionStateField.isSet(value);
													  })()
													: !stateFields.some((stateField: any) => {
															return stateField && stateField.isSet(value);
													  });
												const onChange: () => void = () => {
													stateFields.map((stateField: any) => {
														if (stateField && isNotSelectedOption) {
															stateField.remove(value);
														} else if (
															stateField &&
															optionStateField &&
															stateField.field != optionStateField.field
														) {
															stateField.remove(value);
														} else if (stateField) {
															stateField.add(value);
														}
													});
												};
												return (
													<td key={getNextKey()}>
														{radioButton(option, selectedBool ? value : '', onChange, value)}
													</td>
												);
											})}
										</tr>
									);
								});
							})}
						</tbody>
					</table>
				</div>
			);
		},
	},
	{
		type: 'checkbox',
		function: (element: any, controller: any) => {
			const multiState = Array.isArray(element.stateField);
			const elementValue = element.value ? element.value : element.caption;
			const stateField1 = multiState ? element.stateField[0] : element.stateField;
			const stateField2 = multiState
				? element.stateFieldAppendValue
					? element.stateField[1] + elementValue
					: element.stateField[1]
				: '';
			return checkbox(element, controller, multiState, stateField1, stateField2);
		},
	},
	{
		type: 'checkboxGroup',
		function: (elementGroup: any, controller: any) => {
			const heading = { ...elementGroup };
			heading.options = undefined;
			heading.elements = [{ type: 'text', caption: elementGroup.caption }];
			return (
				<div key={getNextKey()} className={styles.bottomMargin}>
					{getContainerH3(heading, controller)}
					{elementGroup.options.map((element: any) => {
						const multiState = Array.isArray(elementGroup.stateField);
						const elementValue = element.value ? element.value : element.caption;
						const stateField1 = multiState ? elementGroup.stateField[0] : elementGroup.stateField;
						const stateField2 = multiState
							? elementGroup.stateFieldAppendValue
								? elementGroup.stateField[1] + elementValue
								: elementGroup.stateField[1]
							: '';
						return checkbox(element, controller, multiState, stateField1, stateField2);
					})}
				</div>
			);
		},
	},
	{
		type: 'newCheckboxGroup',
		function: (elementGroup: any, controller: any) => {
			const heading = { ...elementGroup };
			heading.options = undefined;
			heading.elements = [{ type: 'text', caption: elementGroup.caption }];
			// e.g. Motivation
			const field = elementGroup.stateField;
			const maxSelected = elementGroup.maxSelected || 0;
			const getControllerState = () => {
				const result: any = {};
				if (controller.state[field]) {
					result[field] = controller.state[field];
				} else {
					result[field] = [];
					// TODO: investigate why setState causes bug on first interaction where direct set does not
					//controller.setState(() => result);
					controller.state[field] = result[field];
				}
				return result;
			};
			const isSet = (value: string) => {
				return controller.state[field] && controller.state[field].includes(value);
			};
			const getCount = () => {
				return controller.state[field] ? controller.state[field].length : 0;
			};
			const add = (value: string) => {
				const controllerState = getControllerState();
				if (!controllerState[field].includes(value)) {
					controllerState[field].push(value);
					controller.setState(controllerState);
				}
			};
			const remove = (value: string) => {
				const controllerState = getControllerState();
				if (controllerState[field].includes(value)) {
					const index = controllerState[field].indexOf(value);
					if (index != -1) controllerState[field].splice(index, 1);
					controller.setState(controllerState);
				}
			};
			const elements = elementGroup.options.map((element: any) => {
				const elementValue = element.value ? element.value : element.caption;
				const checked = isSet(elementValue);
				const onChecked = (isChecked: boolean) => {
					remove(elementValue);
					if (isChecked) add(elementValue);
				};
				// 20220413 SC Checkbox component does not support disabling at this time, but the below function
				//   should provide the required functionality if/when support is added
				const isDisabled = () => {
					//console.log(`Comparing ${getCount()} with ${maxSelected}`);
					return maxSelected <= 0 || getCount() < maxSelected;
				};
				return {
					key: elementValue,
					checked: checked,
					onChecked: onChecked,
					caption: element.caption,
				};
			});
			return (
				<CheckboxGroup
					key={getNextKey()}
					caption={heading.elements[0].caption}
					isRequired={elementGroup.isRequired}
					elements={elements}
					currentValueSource={{
						state: controller.state,
						stateField: elementGroup.stateField,
					}}
				/>
			);
		},
	},
	// Containers
	{
		type: 'container',
		function: (element: any, controller: any) => {
			return (
				<div
					className={styles[element.styles]}
					key={getNextKey()}
					hidden={
						element.hidden
							? typeof element.hidden == 'function'
								? element.hidden(controller)
								: element.hidden
							: false
					}
				>
					{unmapElements(element.elements, controller)}
				</div>
			);
		},
	},
	{
		type: 'containerH1',
		function: getContainerH1,
	},
	{
		type: 'containerH2',
		function: getContainerH2,
	},
	{
		type: 'containerH3',
		function: getContainerH3,
	},
	{
		type: 'containerLink',
		function: (element: any, controller: any) => {
			return (
				<a href={element.URL} className={styles[element.styles]} key={getNextKey()}>
					{unmapElements(element.elements, controller)}
				</a>
			);
		},
	},
	{
		type: 'hr',
		function: (element: any, controller: any) => {
			return <hr key={getNextKey()} className={styles[element.styles]} />;
		},
	},
];

function getContainerH1(element: any, controller: any) {
	return (
		<h1 className={styles[element.styles]} key={getNextKey()}>
			{unmapElements(element.elements, controller)}
		</h1>
	);
}

function getContainerH2(element: any, controller: any) {
	return (
		<h2 className={styles[element.styles]} key={getNextKey()}>
			{unmapElements(element.elements, controller)}
		</h2>
	);
}

function getContainerH3(element: any, controller: any) {
	return (
		<h3 className={styles[element.styles]} key={getNextKey()}>
			<div className={styles.row}>
				<div className={element.isRequired ? styles.headingWithAsterisk : ''}>
					{unmapElements(element.elements, controller)}
				</div>
				<div className={styles.asterisk}>
					{element.isRequired ? <RequiredFieldAsterisk /> : null}
				</div>
			</div>
		</h3>
	);
}

function getNextKey() {
	nextKey += 1;
	return String(nextKey - 1);
}

function resetKeys() {
	nextKey = 1;
}

function unmapElements(elements: any, controller: any) {
	return elements ? elements.map((element: any) => unmapElement(element, controller)) : null;
}

// TODO: add safeRender call to mapping.function
//   return safeRender(element, controller, mapping,function(element, controller))

function unmapElement(element: any, controller: any) {
	if (!element) return;
	const result = mappings.map((mapping) => {
		if (mapping.type == element.type) return mapping.function(element, controller);
	});
	if (!result) console.warn(`Unable to unmap element with type ${element.type}`);
	return result;
}
