import React from 'react';

const CheckedSVG = () => (
	<svg
		width="22px"
		height="22px"
		viewBox="0 0 32 32"
		version="1.1"
		xmlnsXlink="http://www.w3.org/1999/xlink"
		xmlns="http://www.w3.org/2000/svg"
	>
		<defs>
			<rect width="31.999939" height="32" id="slice_1" />
			<clipPath id="clip_1">
				<use href="#slice_1" clipRule="evenodd" />
			</clipPath>
		</defs>
		<g id="Slice" clipPath="url(#clip_1)">
			<use href="#slice_1" stroke="none" fill="none" />
			<g id="icons8_check_all_1" transform="translate(3.999969 4.000031)">
				<path
					d="M0 3C0 1.3425 1.3425 0 3 0L21 0C22.6575 0 24 1.3425 24 3L24 21C24 22.6575 22.6575 24 21 24L3 24C1.3425 24 0 22.6575 0 21L0 3ZM19.0606 6.43945C18.7681 6.14695 18.384 6 18 6C17.616 6 17.232 6.14695 16.9395 6.43945L10.5 12.8789L7.06055 9.43945C6.47555 8.85445 5.52445 8.85445 4.93945 9.43945C4.35445 10.0245 4.35445 10.9755 4.93945 11.5605L9.43945 16.0605C10.0245 16.647 10.9755 16.647 11.5605 16.0605L19.0606 8.56055C19.6455 7.97555 19.6455 7.02445 19.0606 6.43945Z"
					id="Shape"
					fill="#16E7BA"
					fillRule="evenodd"
					stroke="none"
				/>
			</g>
			<g id="icons8_uncheck_all_3" transform="translate(-6.103516E-05 0)">
				<path
					d="M0 4.32432C0 1.90203 1.90203 0 4.32432 0L27.6757 0C30.098 0 32 1.90203 32 4.32432L32 27.6757C32 30.098 30.098 32 27.6757 32L4.32432 32C1.90203 32 0 30.098 0 27.6757L0 4.32432ZM27.6756 1.72968L4.32428 1.72968C2.85468 1.72968 1.72968 2.85468 1.72968 4.32428L1.72968 27.6756C1.72968 29.1452 2.85468 30.2702 4.32428 30.2702L27.6756 30.2702C29.1452 30.2702 30.2702 29.1452 30.2702 27.6756L30.2702 4.32428C30.2702 2.85468 29.1452 1.72968 27.6756 1.72968Z"
					id="Shape"
					fill="#C3ECF2"
					fillRule="evenodd"
					stroke="none"
				/>
			</g>
		</g>
	</svg>
);

export { CheckedSVG };
