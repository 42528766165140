import React from 'react';

export const LeftArrowSVG = () => {
	return (
		<svg
			width="21px"
			height="21px"
			viewBox="0 0 21 18"
			version="1.1"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			xmlns="http://www.w3.org/2000/svg"
		>
			<desc>Left Arrow</desc>
			<g id="icon/menu-left">
				<g id="Group">
					<path
						d="M0 0.190909L19.4727 0.190909"
						transform="matrix(-1 0 0 1 19.47273 8.4)"
						id="Path"
						fill="none"
						stroke="#54CADC"
						strokeWidth="2"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						d="M0 0.190909L12.2182 0.190909"
						transform="matrix(-1 0 0 1 21 16.8)"
						id="Path"
						fill="none"
						stroke="#54CADC"
						strokeWidth="2"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						d="M0 0.190909L12.2182 0.190909"
						transform="matrix(-1 0 0 1 21 -6.28414E-13)"
						id="Path"
						fill="none"
						stroke="#54CADC"
						strokeWidth="2"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						d="M0 9.16364L4.58182 4.58182L0 0"
						transform="matrix(-1 0 0 1 4.581818 4.009091)"
						id="Path"
						fill="none"
						stroke="#54CADC"
						strokeWidth="2"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</g>
			</g>
		</svg>
	);
};
