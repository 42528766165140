import React from 'react';

import styles from './styles.module.css';

import { PopUpProps, PopUp } from './components/PopUp';

const fadeInDuration = 400;
const fadeOutDuration = 400;
const defaultAutoCloseDelay = 4200;

const popUpList: PopUpProps[] = [];

let key = 1;

export function showMessage(props: {
	caption: string;
	onClose?: () => void;
	autoCloseDelay?: number;
}) {
	const currentKey = key;
	popUpList.push({
		caption: props.caption,
		onClose: () => {
			props.onClose ? props.onClose : null;
			popKey(currentKey);
		},
		autoCloseDelay: props.autoCloseDelay ? props.autoCloseDelay : defaultAutoCloseDelay,
		fadeInDuration: fadeInDuration,
		fadeOutDuration: fadeOutDuration,
		key: currentKey,
	});
	key += 1;
}

function popKey(key: number) {
	popUpList.forEach((element, index) => {
		if (element.key == key) popUpList.splice(index, 1);
	});
}

class Message extends React.Component {
	render() {
		return (
			<div className={styles.messageContainer}>
				{popUpList?.map((popUp) => {
					return <PopUp {...popUp} />;
				})}
			</div>
		);
	}
}

export { Message };
