import React from 'react';
import classNames from 'classnames';
import nanoid from 'nanoid';
import { Select } from 'antd';
import 'antd/dist/antd.css';
import styles from './styles.module.css';
import { RequiredFieldAsterisk } from 'src/components/SVGs/RequiredFieldAsterisk';

interface Props {
	caption: string;
	onChange(value: string, optionData?: any): void;
	options: string[];
	value?: string;
	defaultOption?: number;
	isRequired?: boolean;
	showSearch?: boolean;
}

interface State {
	selectedValue?: string;
	hasError: boolean;
}

export default class Dropdown extends React.Component<Props, State> {
	uid: string;

	state: State = {
		selectedValue: this.props.value,
		hasError: false,
	};

	constructor(props: Props) {
		super(props);
		this.uid = nanoid();
	}

	componentDidUpdate(prevProps: Props) {
		if (prevProps.value !== this.props.value) {
			this.setState({ selectedValue: this.props.value });
		}
	}

	render() {
		const { Option } = Select;
		const defaultOption = this.props.defaultOption;
		const options = this.props.options;
		const classesObj = {
			[styles.border_error]: this.state.hasError,
			[styles.border_ok]: !this.state.hasError,
			[styles.field]: true,
		};
		const classes: string = classNames(classesObj);

		return (
			<div className={styles.control}>
				<label htmlFor={this.uid} className={styles.caption}>
					{this.props.caption}
					{this.props.isRequired && (
						<div className={styles.asterisk}>
							<RequiredFieldAsterisk />
						</div>
					)}
				</label>
				<Select
					id={this.uid}
					className={classes}
					bordered={false}
					allowClear={true}
					showSearch={this.props.showSearch}
					optionFilterProp="children"
					defaultValue={
						defaultOption && defaultOption < options.length ? options[defaultOption - 1] : undefined
					}
					value={this.state.selectedValue}
					onChange={(value, optionData) => {
						const hasError: boolean =
							this.props.isRequired && (!value || value === '') ? true : false;
						this.setState({ selectedValue: Boolean(optionData) ? value : '', hasError: hasError });
						this.props.onChange(value, optionData);
					}}
				>
					{options.map((option) => (
						<Option key={option} value={option}>
							{option}
						</Option>
					))}
				</Select>
				{this.state.hasError && <div className={styles.validationMsg}>This field is required</div>}
			</div>
		);
	}
}

export { Dropdown };
