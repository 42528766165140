import React from 'react';
import styles from './styles.module.css';
import { RequiredFieldAsterisk } from 'src/components/SVGs/RequiredFieldAsterisk';
import { Checkbox } from 'src/components/Checkbox';

interface Props {
	elements: {
		checked: boolean;
		caption: string;
		onChecked(value: boolean): void;
	}[];
	caption: string | React.ReactFragment;
	headingStyles?: any;
	isRequired: boolean;
	currentValue?: any;
	currentValueSource?: {
		state: any;
		stateField: string;
	};
}

interface State {
	currentValue: any;
}

class CheckboxGroup extends React.Component<Props, State> {
	state: State = {
		// This default value emulates required message suppression until interacted with as per other required elements
		currentValue: ' ',
	};

	render() {
		const { elements, caption, headingStyles, isRequired, currentValueSource } = this.props;
		let { currentValue } = this.state;
		return (
			<div className={styles.bottomMargin}>
				<h3 className={styles ? styles[headingStyles] : ''}>
					<div className={styles.row}>
						<div className={isRequired ? styles.headingWithAsterisk : ''}>{caption}</div>
						<div className={styles.asterisk}>{isRequired ? <RequiredFieldAsterisk /> : null}</div>
					</div>
				</h3>
				{elements.map((element: any) => {
					const onChecked = (checked: boolean) => {
						element.onChecked(checked);
						currentValueSource &&
							this.setState({
								currentValue: currentValueSource.state[currentValueSource.stateField],
							});
					};
					return (
						<Checkbox
							key={element.caption}
							checked={element.checked}
							onChecked={onChecked}
							caption={element.caption}
						/>
					);
				})}
				{isRequired && (!currentValue || currentValue == [] || currentValue == '') ? (
					<div className={styles.validationMsg}>This field is required</div>
				) : (
					<div> </div> // Placeholder space so page doesn't move when error shows/hides
				)}
			</div>
		);
	}
}

export { CheckboxGroup };
