import React from 'react';

export const RightArrowSVG = () => {
	return (
		<svg
			width="54px"
			height="54px"
			viewBox="0 0 54 54"
			version="1.1"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			xmlns="http://www.w3.org/2000/svg"
		>
			<desc>Right Arrow</desc>
			<g id="button/chat-menu">
				<g id="button/chat-menu">
					<path
						d="M27 54C41.9117 54 54 41.9117 54 27C54 12.0883 41.9117 0 27 0C12.0883 0 0 12.0883 0 27C0 41.9117 12.0883 54 27 54Z"
						id="Oval"
						fill="#00B1C9"
						stroke="none"
					/>
					<g id="icon/menu-right" transform="translate(18 18.19091)">
						<g id="Group-2">
							<path
								d="M0 0.190909L19.4727 0.190909"
								transform="translate(1.527273 8.4)"
								id="Path"
								fill="none"
								stroke="#FFFFFF"
								strokeWidth="2"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
							<path
								d="M0 0.190909L12.2182 0.190909"
								transform="translate(-1.003642E-13 16.8)"
								id="Path"
								fill="none"
								stroke="#FFFFFF"
								strokeWidth="2"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
							<path
								d="M0 0.190909L12.2182 0.190909"
								id="Path"
								fill="none"
								stroke="#FFFFFF"
								strokeWidth="2"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
							<path
								d="M0 9.16364L4.58182 4.58182L0 0"
								transform="translate(16.41818 4.009091)"
								id="Path"
								fill="none"
								stroke="#FFFFFF"
								strokeWidth="2"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
						</g>
					</g>
				</g>
			</g>
		</svg>
	);
};
