import React from 'react';
import styles from './styles.module.css';
import { showMessage } from 'src/components/Message';
import { SignUpInput } from 'src/components/SignUpInput';
import { AfterEmailConfirmation } from 'src/components/AfterEmailConfirmation';
import { SetupUserAccount, isEmailValid, isPwdValid } from 'src/helpers/SignUpHelper';
import { UserManager, KeystoneUser, signInAsGuestUserIfRequired } from 'src/helpers/UserManager';
import { RegistrationManager } from 'src/helpers/RegistrationManager';
import { navigate } from 'gatsby';
import { Spinner } from 'src/components/Spinner';
import { SettingsManager } from 'src/helpers/SettingsManager';
import fetch from 'node-fetch';
import { Client } from 'src/helpers/ClientManager';

const publicIp = require('public-ip');

interface Props {
	controller: any;
	headingText: string;
	bodyText?: string;
	groups?: number[];
	assessmentOnly?: boolean;
	source?: string;
	removeDefaultGroups?: boolean;
	onUserCreated?: (user?: KeystoneUser) => void;
}

interface State {
	loading: boolean;
	success: boolean;
	urlParams: URLSearchParams | undefined;
	userCountry?: string;
	authUser?: KeystoneUser;
}

class SignUpPage extends React.Component<Props, State> {
	state: State = { loading: true, success: false, urlParams: undefined };

	componentDidMount = async () => {
		let urlParams;
		// Window is not accessible during server side deployment, so it must be checked prior to usage as shown here
		if (typeof window !== 'undefined') {
			urlParams = new URLSearchParams(window.location.search);
		}
		const email = decodeURIComponent((urlParams ? urlParams.get('email') : undefined) || '');
		const token = urlParams ? urlParams.get('token') : undefined;
		if (email && token) {
			// User is redeeming token for existing account
			const result = await UserManager.redeemUserMagicAuthLink({ email, token });
			if (result) {
				const user = await UserManager.getAuthenticatedUser();
				if (!user) {
					console.error(`Unable to retrieve authenticated user after redeeming token`);
					return;
				}
				user.isVerified = true;
				await UserManager.updateUser(user);
				await RegistrationManager.acceptRegistration(user.id);
				navigate('/');
				return;
			} else {
				console.warn(`Error redeeming provided token`);
			}
		}
		const user = await signInAsGuestUserIfRequired();
		if (user && !user.guest) {
			if (!user.isVerified) {
				this.setState({ loading: false, success: true, authUser: user });
				return;
			} else {
				navigate('/');
				return;
			}
		}
		const userCountry = await identifyUserCountry(this.state.authUser?.id);
		this.setState({ userCountry, loading: false });
	};

	createUser = async (firstName: string, lastName: string, email: string, password: string) => {
		const { urlParams } = this.state;
		const removeDefaultGroups = this.props.removeDefaultGroups || urlParams?.get('rd') == 'true';
		const customGroups = this.props.groups
			? this.props.groups.map((group) => {
					return { id: group };
			  })
			: urlParams
			? urlParams.get('cg')
				? urlParams
						.get('cg')
						?.split(',')
						.map((group) => {
							return { id: Number(group) };
						})
				: []
			: [];

		await SetupUserAccount(
			firstName.trim(),
			lastName.trim(),
			email.trim(),
			password.trim(),
			this.state.userCountry,
			removeDefaultGroups,
			customGroups,
			this.props.assessmentOnly,
			this.props.source
		).then(async (result) => {
			this.setState({ success: result.success });
			if (!result.success) {
				showMessage({
					caption: result.message ? result.message : 'Failed to create user account!',
				});
				this.props.controller.setState({});
			} else {
				await UserManager.sendUserMagicAuthLinkForAuthenticatedUser();
				this.props.onUserCreated && this.props.onUserCreated(result.user);
			}
		});
	};

	render() {
		const { loading, success, authUser } = this.state;

		// Window is not accessible during server side deployment, so it must be checked prior to usage as shown here
		if (typeof window !== 'undefined') {
			this.state.urlParams = new URLSearchParams(window.location.search);
		}

		const bodyText = this.props.bodyText
			? this.props.bodyText
			: `Sign up now to save your interests, skills and results and help us connect you with potential employers:`;

		return (
			<div className={styles.container}>
				{!loading ? (
					!success ? (
						<>
							<div className={styles.headingContainer}>
								<div className={styles.heading}>{this.props.headingText}</div>
								<div className={styles.pText}>{bodyText}</div>
								<div className={styles.panelContainer}>
									<SignUpInput
										onCreateAccountClick={this.createUser}
										emailValid={(email: string) => isEmailValid(email)}
										passwordValid={(password: string) => isPwdValid(password)}
									/>
								</div>
							</div>
						</>
					) : (
						<AfterEmailConfirmation
							headingText="Thanks for signing up."
							bodyText={`Check your ${
								authUser && authUser.email ? `${authUser.email} ` : ''
							}inbox for a confirmation email to continue.`}
							showButton={!!authUser}
							buttonCaption="Resend email"
							buttonSentCaption="Email sent"
							onButtonClick={async (setIsDisabled) => {
								await UserManager.sendUserMagicAuthLinkForAuthenticatedUser();
								setIsDisabled(true);
							}}
						/>
					)
				) : (
					<Spinner />
				)}
			</div>
		);
	}
}

const identifyUserCountry = async (userId?: number) => {
	let userCountry: string | undefined;
	const ipAddress = await publicIp.v4();
	const countries = (await SettingsManager.getCountryList()) || [];
	userCountry = (await UserManager.getUserCountry(ipAddress, JSON.stringify(countries)))?.country;
	return userCountry;
};

export { SignUpPage, identifyUserCountry };
