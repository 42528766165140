import React from 'react';

export const SignUpKey = () => (
	<svg
		width="21px"
		height="22px"
		viewBox="0 0 21 22"
		version="1.1"
		xmlnsXlink="http://www.w3.org/1999/xlink"
		xmlns="http://www.w3.org/2000/svg"
	>
		<desc>Sign Up Key</desc>
		<path
			d="M15.0219 0L6.89168 8.13023C4.44814 7.50544 1.89174 8.6153 0.679727 10.8271C-0.532289 13.039 -0.0920216 15.7909 1.74956 17.5142C3.59114 19.2375 6.3662 19.4944 8.49285 18.1385C10.6195 16.7825 11.5575 14.1582 10.7721 11.7614L12.3707 10.1628L12.3707 7.51163L15.0219 7.51163L15.0219 4.86047L17.6731 4.86047L18.9987 3.53488L18.9987 0L15.0219 0Z"
			transform="translate(1 1)"
			id="Path"
			fill="#DDF4F8"
			stroke="#54CADC"
			strokeWidth="2"
			strokeLinecap="square"
		/>
	</svg>
);
