import { navigate } from 'gatsby';
import { isEmailValid } from 'src/helpers/SignUpHelper';
import { BadgeManager } from 'src/helpers/BadgeManager/BadgeManager';
import { UserManager, KeystoneUser } from 'src/helpers/UserManager';
import { SettingsManager } from 'src/helpers/SettingsManager';
import { showMessage } from 'src/components/Message';
import { getTechLanguages, setProfileDataCountries } from 'src/components/PageBuilder';

export function isInAustralia(controller: any) {
	return (
		controller &&
		controller.state &&
		(!controller.state['country'] || controller.state['country'] === 'Australia')
	);
}

export function checkURLAndNavigate(controller: any, params: { defaultLocation: string }) {
	let { url } = controller.state;
	let { defaultLocation } = params;
	if (url) {
		if (url && url[0] == '/') url = url.substr(1, url.length);
		navigate(`/${url}`);
	} else {
		if (defaultLocation && defaultLocation[0] == '/')
			defaultLocation = defaultLocation.substr(1, defaultLocation.length);
		navigate(`/${defaultLocation}`);
	}
}

export function profileDetailsSaveButtonValidation(controller: any) {
	const { emailAddress, firstName, lastName, errors } = controller.state;
	const error = Object.values(errors).some((error) => error);
	return !(isEmailValid(emailAddress) && firstName !== '' && lastName !== '' && !error);
}

export async function profileDetailsUpdateProfile(controller: any) {
	controller.setState((state: any) => ({
		firstName: state.firstName.trim(),
		lastName: state.lastName.trim(),
		emailAddress: state.emailAddress.trim(),
		loading: true,
	}));
	const { firstName, lastName, emailAddress, user, country, exclusionList } = controller.state;
	const hasValidResponses = !profileDetailsSaveButtonValidation(controller);
	if (hasValidResponses && user) {
		// Compress JSON
		const profileFieldsDict = user?.profileAsJson ? JSON.parse(user.profileAsJson) : {};
		const allFieldsDict = JSON.parse(JSON.stringify(controller.state));

		if (
			!allFieldsDict['Questionnaire Completed'] &&
			UserManager.profileDetailsCompleted(allFieldsDict, allFieldsDict.techQuestionnaireEnabled)
		) {
			allFieldsDict['Questionnaire Completed'] = new Date().toLocaleDateString('en-AU');
			allFieldsDict['Questionnaire Version'] = 'v6';
		}

		if (allFieldsDict['preferredTechLanguage'] !== profileFieldsDict['preferredTechLanguage']) {
			allFieldsDict['testDomeInviteScheduled'] = new Date(
				new Date().getTime() + 600000 // 10 mins in future
			).toISOString();
		}

		for (const [key] of Object.entries(allFieldsDict).filter(
			([key]) => !exclusionList?.includes(key)
		)) {
			const defaultValue = '';
			profileFieldsDict[key] = allFieldsDict[key] ? allFieldsDict[key] : defaultValue;
		}

		const profileAsJson = mergeProfileDictIntoProfileJsonForUser(
			Object.keys(allFieldsDict).reduce((filtered: { [k: string]: any }, key) => {
				if (!exclusionList?.includes(key)) filtered[key] = allFieldsDict[key];
				return filtered;
			}, {}),
			user
		);

		controller.setState({ success: true });
		const badges = await BadgeManager.getBadgesWithKey({ key: 'ProfileComplete' });
		if (badges) {
			let badgeIds: number[] = [];
			badges.map((badge: any) => {
				badgeIds.push(badge.id);
			});
			for (const badge of badgeIds) {
				await BadgeManager.addUserBadge({ user: user.id, badge: badge });
			}
		}
		await UserManager.updateUser({
			id: user.id,
			firstName,
			lastName,
			email: emailAddress,
			profileAsJson,
			country,
		});
		checkURLAndNavigate(controller, { defaultLocation: '/profile' });
	} else {
		// SC 20220703 this should currently be impossible to trigger due to evolution in validation logic
		controller.setState({ success: false });
		showMessage({ caption: 'Unable to save changes as some required fields have invalid data' });
		scrollTo(0, 0);
	}
}

function mergeProfileDictIntoProfileJsonForUser(updatedProfileDict: any, user: KeystoneUser) {
	const profileAsDict = user?.profileAsJson ? JSON.parse(user.profileAsJson) : {};
	for (const [key] of Object.entries(updatedProfileDict)) {
		const defaultValue = '';
		profileAsDict[key] = updatedProfileDict[key] ? updatedProfileDict[key] : defaultValue;
	}
	// Fix users who erroneously stored user data recursively
	profileAsDict.user = undefined;
	const profileAsJson = JSON.stringify(profileAsDict);
	return profileAsJson;
}

export function loadProfileDictFromUser(user: KeystoneUser) {
	const profileAsDict = user.profileAsJson ? JSON.parse(user.profileAsJson) : {};
	profileAsDict.techLanguages = Object.assign(
		profileAsDict.techLanguages || [],
		getTechLanguages()
			.map((techLanguage: any) => {
				profileAsDict.techLanguages
					? profileAsDict.techLanguages.map((element: any) => {
							return techLanguage.name == element.name
								? {
										Junior: element.Junior,
										Middle: element.Middle,
										Senior: element.Senior,
								  }
								: null;
					  })
					: techLanguage;
			})
			// Remove null elements
			.filter((element) => element)
	);
	return profileAsDict;
}

export async function profileDetailsComponentDidMount(controller: any) {
	let { exclusionList } = controller.state;
	const user = await UserManager.getAuthenticatedUser();
	if (!user || user.guest) {
		// no user found so send them to sign in
		navigate('/sign-in?url=profile-details');
		return;
	}
	let urlParams;
	// Window is not accessible during server side deployment, so it must be checked prior to usage as shown here
	if (typeof window !== 'undefined') {
		urlParams = new URLSearchParams(window.location.search);
	}
	const url = urlParams ? urlParams.get('url') || undefined : undefined;
	exclusionList = ['exclusionList'].concat(
		Object.entries(JSON.parse(JSON.stringify(controller.state))).map(([key, _value]: any) => {
			return key ? key : '';
		}),
		'url',
		'user',
		'errors',
		'techQuestionnaireEnabled'
	);

	const techQuestionnaireEnabled =
		(await SettingsManager.getSetting('TechQuestionnaireEnabled', user.id)) == 'true'
			? true
			: false;

	controller.setState({
		success: false,
		user,
		firstName: user.firstName ?? '',
		lastName: user.lastName ?? '',
		emailAddress: user.email ?? '',
		country: user.country ?? '',
		exclusionList,
		url,
		techQuestionnaireEnabled,
	});
	const countries = (await SettingsManager.getCountryList()) || [];
	setProfileDataCountries(countries);

	const profileFieldsDict = loadProfileDictFromUser(user);
	let allFieldsDict = JSON.parse(JSON.stringify(controller.state));
	for (const [key] of Object.entries(profileFieldsDict)) {
		if (!exclusionList?.includes(key)) {
			const defaultValue = '';
			allFieldsDict[key] = profileFieldsDict[key]
				? profileFieldsDict[key]
				: allFieldsDict[key] || defaultValue;
		}
	}
	controller.setState({ ...allFieldsDict, loading: false });
}
