import { isEmailValid } from 'src/helpers/SignUpHelper';
import * as logic from 'src/components/PageBuilder/components/Builder/logic';
// ProfileDataCountries is a placeholder that is overwritten by the renderer
let ProfileDataCountries: any = [{ caption: 'Australia' }, { caption: 'Other' }];

export const ProfileData: { [key: string]: any } = [
	// 20220619 SC: All elements in this list must be single dictionaries with no depth (aka no children) so other functionality works as intended
	//   All structural relationships must instead be be defined elsewhere such as ./profiledetails.tsx
	{
		id: 'Pronouns',
		caption: 'Preferred Pronouns',
		stateField: 'pronouns',
		type: 'dropdown',
		options: getPronouns(),
	},
	{
		id: 'First Name',
		caption: 'First Name',
		stateField: 'firstName',
		type: 'textInput',
		isRequired: true,
		requiredForTab: 'PERSONAL DETAILS',
		validate: (value: string) => value && value.length <= 40,
		validationMsg: 'This field must be 40 characters or less',
	},
	{
		id: 'Last Name',
		caption: 'Last Name',
		stateField: 'lastName',
		type: 'textInput',
		isRequired: true,
		requiredForTab: 'PERSONAL DETAILS',
		validate: (value: string) => value && value.length <= 80,
		validationMsg: 'This field must be 80 characters or less',
	},
	{
		id: 'Email',
		caption: 'Email Address',
		stateField: 'emailAddress',
		type: 'textInput',
		isRequired: true,
		validate: (value: string) => value && value.length <= 100 && isEmailValid(value),
		requiredForTab: 'PERSONAL DETAILS',
		validationMsg: 'Please provide a valid email',
	},
	{
		id: 'Mobile',
		caption: 'Mobile Number',
		stateField: 'mobileNumber',
		type: 'textInput',
		isRequired: true,
		requiredForTab: 'PERSONAL DETAILS',
		validate: (value: string) => value && value.length <= 30,
		validationMsg: 'This field must be 30 characters or less',
	},
	{
		id: 'City/Town',
		caption: 'City/Town',
		stateField: 'addressSuburb',
		type: 'textInput',
		isRequired: true,
		requiredForTab: 'LOCATION',
		validate: (value: string) => value && value.length <= 30,
		validationMsg: 'This field must be 30 characters or less',
	},
	{
		id: 'StateFreetext',
		caption: 'State/Province',
		stateField: 'addressState',
		type: 'textInput',
		isRequired: true,
		requiredForTab: 'LOCATION',
		validate: (value: string) => value && value.length <= 30,
		validationMsg: 'This field must be 30 characters or less',
	},
	{
		id: 'Suburb',
		caption: 'Suburb',
		stateField: 'addressSuburb',
		type: 'textInput',
		isRequired: true,
		requiredForTab: 'LOCATION',
		validate: (value: string) => value && value.length <= 30,
		validationMsg: 'This field must be 30 characters or less',
	},
	{
		id: 'State',
		caption: 'State',
		stateField: 'addressState',
		type: 'dropdown',
		options: getStates(),
		isRequired: true,
		requiredForTab: 'LOCATION',
	},
	{
		id: 'Postcode',
		caption: 'Postcode',
		stateField: 'addressPostcode',
		type: 'textInput',
		validate: (value: string) => value && value.length <= 30,
		validationMsg: 'This field must be 30 characters or less',
	},
	{
		id: 'Country',
		caption: 'Country',
		stateField: 'country',
		type: 'dropdown',
		options: ProfileDataCountries, // e.g. [{ caption: 'Australia' }, { caption: 'Singapore' }]
		showSearch: true,
		isRequired: true,
		requiredForTab: 'LOCATION',
	},
	{
		id: 'autismDiagnosis',
		caption: 'Are you neurodiverse?',
		stateField: 'neurodiversity',
		type: 'radioButtonGroup',
		options: getAutismOptionsAlternateRaw(),
		styles: 'sectionHeading',
		isRequired: true,
		requiredForTab: 'PERSONAL DETAILS',
	},
	{
		id: 'autismDiagnosisAlternate',
		caption: 'How do you identify?',
		stateField: 'neurodiversity',
		type: 'radioButtonGroup',
		options: getAutismOptionsAlternateRaw(),
		styles: 'sectionHeading',
		isRequired: true,
	},
	{
		id: 'background',
		caption: 'Background',
		stateField: 'Background',
		type: 'dropdown',
		options: getBackgroundOptions(),
		showSearch: true,
	},
	{
		id: 'workRights',
		caption: 'On what basis do you have the right to work in Australia?',
		stateField: 'workRights',
		type: 'radioButtonGroup',
		options: getWorkRightsOptionsRaw(),
		styles: 'sectionHeading',
		isRequired: logic.isInAustralia,
		requiredForTab: 'EMPLOYMENT PREFERENCES',
	},
	{
		id: 'neurodiversity',
		caption: 'Which of the conditions below have you been diagnosed with?',
		stateField: 'Diagnosis',
		type: 'newCheckboxGroup',
		options: getNeurodiversityOptionsRaw(),
		styles: 'sectionHeading',
		isRequired: true,
		requiredForTab: 'EMPLOYMENT PREFERENCES',
	},
	{
		id: 'neurodiversityAlternate',
		caption: 'Types of Neurodivergence',
		stateField: 'Diagnosis',
		type: 'newCheckboxGroup',
		options: getNeurodiversityOptionsRaw(),
		styles: 'sectionHeading',
		isRequired: true,
		requiredForTab: 'EMPLOYMENT PREFERENCES',
	},
	{
		id: 'intellectualDisability',
		caption: 'Do you have an intellectual disability?',
		stateField: 'HasIntellectualDisability',
		type: 'radioButtonGroup',
		options: getIntellectualDisabilityOptionsRaw(),
		styles: 'sectionHeading',
	},
	{
		id: 'hoursPerWeek',
		caption: 'How many hours would you prefer to work each week?',
		stateField: 'Desired Workload',
		type: 'radioButtonGroup',
		options: getHoursPerWeekOptionsRaw(),
		styles: 'sectionHeading',
		isRequired: true,
		requiredForTab: 'EMPLOYMENT PREFERENCES',
	},
	{
		id: 'workFromHome',
		caption: 'Would you like the option to work from home?',
		stateField: 'workFromHome',
		type: 'radioButtonGroup',
		options: getWorkFromHomeOptionsRaw(),
		styles: 'sectionHeading',
		isRequired: true,
		requiredForTab: 'EMPLOYMENT PREFERENCES',
	},
	{
		id: 'relocateForWork',
		caption: 'Would you relocate for work?',
		stateField: 'relocate',
		type: 'radioButtonGroup',
		options: getRelocateForWorkOptionsRaw(),
		styles: 'sectionHeading',
		isRequired: true,
		requiredForTab: 'EMPLOYMENT PREFERENCES',
	},
	{
		id: 'mostRecentRole',
		caption: 'What is your current or most recent role?',
		stateField: 'mostRecentRole',
		type: 'textInput',
		validate: (value: string) => value && value.length <= 250,
		validationMsg: 'This field must be 250 characters or less',
	},
	{
		id: 'salaryRange',
		caption: 'What salary range would you consider?',
		stateField: 'Salary Range',
		type: 'radioButtonGroup',
		options: getWageOptionsRaw(),
		styles: 'sectionHeading',
		isRequired: true,
		requiredForTab: 'EMPLOYMENT PREFERENCES',
	},
	{
		id: 'employmentOtherNotes',
		caption: 'Do you have any other comments?',
		stateField: 'employmentOtherNotes',
		isMultiLine: true,
		type: 'textInput',
		validate: (value: string) => value && value.length <= 6000,
		validationMsg: 'This field must be 6000 characters or less',
	},
	{
		id: 'education',
		caption: 'What is your highest level of education?',
		stateField: 'Highest Qualification Held',
		type: 'radioButtonGroup',
		options: getEducationOptionsRaw(),
		styles: 'sectionHeading',
	},
	{
		id: 'qualifications',
		caption:
			'Please list your most recent qualifications (up to 5) chronologically, as they appear on the award, e.g. 2020 Bachelor of Business; 2016 Certificate III of Information Technology:',
		stateField: 'qualifications',
		isMultiLine: true,
		type: 'textInput',
		validate: (value: string) => value && value.length <= 6000,
		validationMsg: 'This field must be 6000 characters or less',
	},
	{
		id: 'interests',
		caption: 'What would you like to do at work?\r\n(You can choose more than one answer)',
		stateField: 'Work Activities',
		type: 'newCheckboxGroup',
		options: getInterestsOptionsRaw(),
		styles: 'sectionHeading',
		// Current disabled due to checkbox not supporting disable option
		maxSelected: 4,
		isRequired: true,
		requiredForTab: 'EMPLOYMENT PREFERENCES',
	},
	{
		id: 'nextOfKinName',
		caption: 'Name',
		stateField: 'nextOfKinName',
		type: 'textInput',
		validate: (value: string) => value && value.length <= 250,
		validationMsg: 'This field must be 250 characters or less',
	},
	{
		id: 'nextOfKinPhone',
		caption: 'Phone Number',
		stateField: 'nextOfKinPhone',
		type: 'textInput',
		validate: (value: string) => value && value.length <= 250,
		validationMsg: 'This field must be 250 characters or less',
	},
	{
		id: 'nextOfKinRelationship',
		caption: 'Relationship to you',
		stateField: 'nextOfKinRelationship',
		type: 'textInput',
		validate: (value: string) => value && value.length <= 250,
		validationMsg: 'This field must be 250 characters or less',
	},
	{
		id: 'workplaceSupports',
		caption: 'workplaceSupports',
		type: 'newTableRadioButtonGroup',
		elements: getWorkplaceSupports(),
	},
	{
		id: 'workplaceSupportsOther',
		caption:
			'Is there anything else you would like us to know about the supports and adjustments you may benefit from',
		stateField: 'workplaceSupportsOther',
		type: 'textInput',
		validate: (value: string) => value && value.length <= 250,
		validationMsg: 'This field must be 250 characters or less',
	},
	{
		id: 'workCategories',
		caption: 'Please select which category you would prefer to work in',
		stateField: 'industryCategory',
		type: 'radioButtonGroup',
		styles: 'sectionHeading',
		options: getWorkCategoryOptionsRaw(),
		isRequired: true,
		requiredForTab: 'CAREER PREFERENCES',
	},
	{
		id: 'desiredRole',
		caption: 'Which roles are you looking for a job in?',
		stateField: 'desiredRole',
		type: 'taglist',
		options: getRoles(),
		isRequired: true,
		validate: (values: [any]) => values && values.length <= 20,
		validationMsg: 'You can only select up to 20 roles',
		requiredForTab: 'CAREER PREFERENCES',
	},
	{
		id: 'marketingSource',
		caption: 'How did you hear about us?',
		stateField: 'marketingSource',
		type: 'dropdown',
		options: getMarketingSourcesRaw(),
		isRequired: true,
		requiredForTab: 'PERSONAL DETAILS',
	},
	{
		id: 'jobMotivations',
		caption: 'Why do you want to find a job?',
		stateField: 'Motivation',
		type: 'newCheckboxGroup',
		options: getJobMotivationsRaw(),
		styles: 'sectionHeading',
		// Current disabled due to checkbox not supporting disable option
		maxSelected: 2,
		isRequired: true,
		requiredForTab: 'EMPLOYMENT PREFERENCES',
	},
	{
		id: 'anyOtherComments',
		caption: 'Do you have any other comments?',
		stateField: 'anyOtherComments',
		type: 'textInput',
		validate: (value: string) => value && value.length <= 250,
		validationMsg: 'This field must be 250 characters or less',
	},
	{
		id: 'jobTitles',
		caption: 'What type of job titles would you be interested in, or have you had in the past?',
		stateField: 'Job Titles',
		type: 'newCheckboxGroup',
		options: getJobTitlesOptionsRaw(),
		styles: 'sectionHeading',
		isRequired: true,
		isTechQuestionnaire: true,
		requiredForTab: 'TECH QUESTIONNAIRE',
	},
	{
		id: 'otherTech',
		caption:
			'Please list any other languages, frameworks, tools, or libraries you are familiar with that are not listed above. (e.g. Selenium, Agile, etc.)',
		stateField: 'otherTech',
		type: 'textInput',
		isTechQuestionnaire: true,
		validate: (value: string) => value && value.length <= 250,
		validationMsg: 'This field must be 250 characters or less',
	},
	{
		id: 'preferredTechLanguage',
		caption:
			'To assist us in understanding your current skill level, please select your preferred language / area of skill, from the list below.',
		stateField: 'preferredTechLanguage',
		type: 'dropdown',
		options: getPreferredTechLanguagesRaw(),
		isRequired: true,
		isTechQuestionnaire: true,
		requiredForTab: 'TECH QUESTIONNAIRE',
	},
	{
		id: 'workHistory',
		caption: 'Enter your work history:',
		stateField: 'workHistory',
		type: 'grid',
		tableData: {
			columns: [
				{
					caption: 'Start Date',
					type: 'DateSelector',
					props: {},
				},
				{
					caption: 'End Date',
					type: 'DateSelector',
					props: {},
				},
				{
					caption: 'Role',
					type: 'Dropdown',
					props: { options: getRoles().map((role) => role.caption), width: '220' },
				},
				{
					caption: 'Description',
					type: 'TextInput',
					props: {
						validate: (value: string) => value && value.length <= 1000,
						validationMsg: 'This field must be 1000 characters or less',
					},
				},
			],
			data: [],
		},
	},
	// 20220516 Tech Languages have not been added to this list and therefore cannot be checked to see if the user has filled it in
];

export function getProfileDataElement(id: string) {
	for (const [_key, element] of Object.entries(ProfileData)) {
		if (element.id == id) {
			return element;
		}
	}
}

export function getProfileDataElements(ids: string[]) {
	const result: any = [];
	ids.map((id) => {
		result.push(getProfileDataElement(id));
	});
	return result;
}

export function getProfileDataElementAsText(id: string) {
	return {
		id: id,
		caption: id,
		stateField: id,
		type: 'text',
	};
}

export function getTechLanguages() {
	return [
		...getFElanguagesRaw().map((element) => element.value),
		...getBElanguagesRaw().map((element) => element.value),
		...getDataToolsRaw().map((element) => element.value),
	].map((language) => {
		return {
			name: `${language}`,
			Junior: false,
			Middle: false,
			Senior: false,
		};
	});
}

function getPronouns() {
	return [
		{ caption: 'He / Him' },
		{ caption: 'She / Her' },
		{ caption: 'They / Them' },
		{ caption: 'Prefer not to say' },
		{ caption: 'Other' },
	];
}

function getStates() {
	return [
		{ caption: 'NSW' },
		{ caption: 'ACT' },
		{ caption: 'VIC' },
		{ caption: 'SA' },
		{ caption: 'WA' },
		{ caption: 'QLD' },
		{ caption: 'NT' },
		{ caption: 'TAS' },
	];
}

function getBackgroundOptions() {
	return [{ caption: 'Test background 1' }, { caption: 'Test background 2' }];
}

// Raw data functions START

function getAutismOptionsAlternateRaw() {
	return [
		{
			caption: `I identify as Neurodivergent, I have a formal diagnosis (e.g. Autism)`,
			value: 'Formal Diagnosis',
		},
		{
			caption: `I identify as Neurodivergent, I have a self diagnosis`,
			value: 'Informal Diagnosis',
		},
		{
			caption: `I do not identify as Neurodivergent`,
			value: 'No Diagnosis',
		},
	];
}

function getWorkRightsOptionsRaw() {
	return [
		{
			caption: 'I am an Australian Citizen',
			value: 'Australian Citizen',
		},
		{
			caption: `I am an Australian Permanent Resident`,
			value: 'Permanent Resident',
		},
		{
			caption: `I have a current Australian Working Visa`,
			value: 'Valid Working Visa',
		},
		{
			caption: `None of the above`,
			value: 'None',
		},
	];
}

function getNeurodiversityOptionsRaw() {
	return [
		{ caption: 'Autism (ASD)' },
		{ caption: 'Aspergers' },
		{ caption: 'ADHD' },
		{ caption: 'Dyslexia' },
		{ caption: 'Obsessive Compulsive Disorder (OCD)' },
		{ caption: 'Tourette Syndrome' },
		{ caption: 'Dyscalculia' },
		{ caption: 'Dyspraxia' },
		{ caption: 'Dysgraphia' },
		{ caption: 'None of the above' },
	];
}

function getIntellectualDisabilityOptionsRaw() {
	return [{ caption: 'Yes' }, { caption: 'No' }];
}

function getHoursPerWeekOptionsRaw() {
	return [
		{ caption: '14 hours per week or less' },
		{ caption: '15-24 hours per week' },
		{ caption: '25-34 hours per week' },
		{ caption: '35 hours per week or more' },
	];
}

function getWorkFromHomeOptionsRaw() {
	return [
		{ caption: 'Willing to work from office and/or home as needed' },
		{ caption: 'Prefer to work in an office environment only' },
		{ caption: 'Prefer to work remotely from home only' },
	];
}

function getRelocateForWorkOptionsRaw() {
	return [
		{ caption: 'Available to relocate locally for the right role' },
		{ caption: 'Available to relocate nationally for the right role' },
		{ caption: 'Not available to relocate' },
	];
}

function getWageOptionsRaw() {
	return [
		{ caption: 'Award wage and conditions or above' },
		{ caption: '$60,000 - $80,000' },
		{ caption: '$80,000 - $100,000' },
		{ caption: '$100,000 - $120,000' },
		{ caption: '$120,000 - $150,000' },
		{ caption: '$150,000 +' },
	];
}

function getEducationOptionsRaw() {
	return [
		{ caption: 'Lower than Year 12 (or equivalent)' },
		{ caption: 'High School' },
		{ caption: 'Certification or Diploma' },
		{ caption: 'Bachelor Degree' },
		{ caption: 'Post Graduate Degree' },
	];
}

function getInterestsOptionsRaw() {
	return [
		{ caption: 'Build and create things', type: 'checkbox' },
		{ caption: 'Manage a process', type: 'checkbox' },
		{ caption: 'Solve complex problems', type: 'checkbox' },
		{ caption: 'Help customers', type: 'checkbox' },
		{ caption: 'Lead a team', type: 'checkbox' },
		{
			caption: 'Be an individual contributor',
			type: 'checkbox',
		},
		{
			caption: 'Work mainly with data and numbers',
			type: 'checkbox',
		},
		{ caption: 'Fix things with my hands', type: 'checkbox' },
		{ caption: 'Be a subject matter expert', type: 'checkbox' },
		{ caption: 'Teach people', type: 'checkbox' },
		{
			caption: 'Market and sell products and services',
			type: 'checkbox',
		},
	];
}

function getWorkCategoryOptionsRaw() {
	return [
		{ caption: 'Accounting, Finance & Insurance' },
		{ caption: 'Data, Information & Process Improvement' },
		{ caption: 'Technology & Software' },
		{ caption: 'Administration & Business' },
		{ caption: 'Science & Engineering' },
		{ caption: 'Disability Support & Aged Care' },
		{ caption: 'Other' },
	];
}

function getRoles() {
	return [
		{ caption: 'Accountant (General)' },
		{ caption: 'Accounts Clerk' },
		{ caption: 'Actuary' },
		{ caption: 'Admissions Clerk' },
		{ caption: 'Advertising Manager' },
		{ caption: 'Advertising Specialist' },
		{ caption: 'Aeronautical Engineer' },
		{ caption: 'Air Traffic Controller' },
		{ caption: 'Alarm, Security or Surveillance Monitor' },
		{ caption: 'Analyst Programmer' },
		{ caption: 'Architect' },
		{ caption: 'Architectural Draftsperson' },
		{ caption: 'Architectural, Building and Surveying Technicians' },
		{ caption: 'Arts Administrator or Manager' },
		{ caption: 'Bookkeeper' },
		{ caption: 'Business Broker' },
		{ caption: 'Call or Contact Centre Manager' },
		{ caption: 'Call or Contact Centre Operator' },
		{ caption: 'Call or Contact Centre Team Leader' },
		{ caption: 'Cartographer' },
		{ caption: 'Chief Executive or Managing Director' },
		{ caption: 'Chief Information Officer' },
		{ caption: 'Civil Engineer' },
		{ caption: 'Civil Engineering Draftsperson' },
		{ caption: 'Civil Engineering Technician' },
		{ caption: 'Clinical Psychologist' },
		{ caption: 'Commodities Trader' },
		{ caption: 'Communications Operator' },
		{ caption: 'Community Arts Worker' },
		{ caption: 'Community Worker' },
		{ caption: 'Company Secretary' },
		{ caption: 'Conference and Event Organiser' },
		{ caption: 'Contract Administrator' },
		{ caption: 'Conveyancer' },
		{ caption: 'Copywriter' },
		{ caption: 'Corporate General Manager' },
		{ caption: 'Corporate Services Manager' },
		{ caption: 'Corporate Treasurer' },
		{ caption: 'Cost Clerk' },
		{ caption: 'Customer Service Manager' },
		{ caption: 'Customs Officer' },
		{ caption: 'Cyber Governance Risk and Compliance Specialist' },
		{ caption: 'Cyber Security Advice and Assessment Specialist' },
		{ caption: 'Cyber Security Analyst' },
		{ caption: 'Cyber Security Architect' },
		{ caption: 'Cyber Security Engineer' },
		{ caption: 'Cyber Security Operations Coordinator' },
		{ caption: 'Data Entry Operator' },
		{ caption: 'Database Administrator' },
		{ caption: 'Developer Programmer' },
		{ caption: 'Devops Engineer' },
		{ caption: 'Economist' },
		{ caption: 'Education Adviser' },
		{ caption: 'Education Managers' },
		{ caption: 'Education Reviewer' },
		{ caption: 'Environmental Consultant' },
		{ caption: 'Environmental Engineer' },
		{ caption: 'Environmental Health Officer' },
		{ caption: 'Environmental Manager' },
		{ caption: 'Environmental Research Scientist' },
		{ caption: 'Environmental Scientists' },
		{ caption: 'Finance Manager' },
		{ caption: 'Human Resource Adviser' },
		{ caption: 'Human Resource Clerk' },
		{ caption: 'Human Resource Manager' },
		{ caption: 'ICT Account Manager' },
		{ caption: 'ICT Business Analyst' },
		{ caption: 'ICT Business Development Manager' },
		{ caption: 'ICT Customer Support Officer' },
		{ caption: 'ICT Managers' },
		{ caption: 'ICT Project Manager' },
		{ caption: 'ICT Quality Assurance Engineer' },
		{ caption: 'ICT Sales Assistant' },
		{ caption: 'ICT Sales Representative' },
		{ caption: 'ICT Support and Test Engineers' },
		{ caption: 'ICT Support Engineer' },
		{ caption: 'ICT Support Technicians' },
		{ caption: 'ICT Systems Test Engineer' },
		{ caption: 'Information and Organisation Professionals' },
		{ caption: 'Information Officer' },
		{ caption: 'Insurance Agent' },
		{ caption: 'Insurance Broker' },
		{ caption: 'Insurance Consultant' },
		{ caption: 'Insurance Investigator' },
		{ caption: 'Insurance Loss Adjuster' },
		{ caption: 'Insurance Risk Surveyor' },
		{ caption: 'Integration Aide' },
		{ caption: 'Irrigation Assistant' },
		{ caption: 'Legal Secretary' },
		{ caption: 'Life Science Technician' },
		{ caption: 'Life Scientist (General)' },
		{ caption: 'Life Scientists' },
		{ caption: 'Market Research Analyst' },
		{ caption: 'Marketing Specialist' },
		{ caption: 'Mathematician' },
		{ caption: 'Multimedia Designer' },
		{ caption: 'Multimedia Specialist' },
		{ caption: 'Order Clerk' },
		{ caption: 'Program or Project Administrator' },
		{ caption: 'Public Relations Manager' },
		{ caption: 'Public Relations Professional' },
		{ caption: 'Purchasing Officer' },
		{ caption: 'Quality Assurance Manager' },
		{ caption: 'Quantity Surveyor' },
		{ caption: 'Records Manager' },
		{ caption: 'Sales Assistant (General)' },
		{ caption: 'Sales Assistants and Salespersons' },
		{ caption: 'Sales Demonstrator' },
		{ caption: 'Software and Applications Programmers' },
		{ caption: 'Software Engineer' },
		{ caption: 'Software Tester' },
		{ caption: 'Statistical Clerk' },
		{ caption: 'Web Administrator' },
		{ caption: 'Web Designer' },
		{ caption: 'Web Developer' },
	];
}

export function getJobTitlesOptionsRaw() {
	return [
		{ caption: 'Software Engineer (Full Stack)' },
		{ caption: 'Software Engineer (Front End)' },
		{ caption: 'Software Engineer (Back End)' },
		{ caption: 'UX / UI Designer' },
		{ caption: 'Quality Engineer / Software Tester' },
		{ caption: 'Help Desk / Tech Support' },
		{ caption: 'Network Administrator' },
		{ caption: 'Cyber Security' },
		{ caption: 'AI / Machine Learning' },
		{ caption: 'Data Scientist' },
		{ caption: 'Data Analyst' },
	];
}

export function getFElanguagesRaw() {
	return [
		{ caption: 'JavaScript', value: 'FE_JavaScript' },
		{ caption: 'HTML', value: 'FE_HTML' },
		{ caption: 'CSS', value: 'FE_CSS' },
		{ caption: 'AngularJS', value: 'FE_AngularJS' },
		{ caption: 'Vue.js', value: 'FE_Vue.js' },
		{ caption: 'React', value: 'FE_React' },
		{ caption: 'SASS', value: 'FE_SASS' },
	];
}

export function getBElanguagesRaw() {
	return [
		{ caption: 'Java', value: 'BE_Java' },
		{ caption: 'Ruby', value: 'BE_Ruby' },
		{ caption: 'Python', value: 'BE_Python' },
		{ caption: 'C#', value: 'BE_C#' },
		{ caption: 'C++', value: 'BE_C++' },
		{ caption: 'JavaScript', value: 'BE_JavaScript' },
		{ caption: 'SQL', value: 'BE_SQL' },
	];
}

export function getDataToolsRaw() {
	return [
		{ caption: 'Tableau', value: 'dataTools_Tableau' },
		{ caption: 'PowerBI', value: 'dataTools_PowerBI' },
		{ caption: 'Excel', value: 'dataTools_Excel' },
		{ caption: 'Python ', value: 'dataTools_Python' },
		{ caption: 'SQL', value: 'dataTools_SQL' },
		{ caption: 'SAS', value: 'dataTools_SAS' },
	];
}

export function getWorkplaceSupports() {
	return [
		getWorkplaceSupportsPhysical(),
		getWorkplaceSupportsHours(),
		getWorkplaceSupportsSocial(),
		getWorkplaceSupportsInstructions(),
		getWorkplaceSupportsMeeting(),
		getWorkplaceSupportsFeedback(),
		getWorkplaceSupportsRecruitment(),
	];
}

export function getWorkplaceSupportsTextOnly() {
	return [
		...getCaptionsAsText(getPhysicalSupportsRaw()),
		...getCaptionsAsText(getWorkingHoursSupportsRaw()),
		...getCaptionsAsText(getSocialSupportsRaw()),
		...getCaptionsAsText(getWorkInstructionSupportsRaw()),
		...getCaptionsAsText(getMeetingSupportsRaw()),
		...getCaptionsAsText(getFeedbackSupportsRaw()),
		...getCaptionsAsText(getRecruitmentSupportsRaw()),
	];
}

function getCaptionsAsText(captions: { caption: String }[]) {
	return captions.map((element) => element.caption);
}

export function getWorkplaceSupportsPhysical() {
	return {
		caption: 'Physical Supports',
		elements: convertSupportsIntoRequiredPreferredNot(
			[...getPhysicalSupportsRaw()],
			'Physical_Adjustments'
		),
		type: 'radioButtonGroupContainer',
		styles: 'sectionHeading',
		state_field: 'Physical_Adjustments',
	};
}

export function getWorkplaceSupportsHours() {
	return {
		caption: 'Working Hours Supports',
		elements: convertSupportsIntoRequiredPreferredNot(
			[...getWorkingHoursSupportsRaw()],
			'Working_Hours_Adjustments'
		),
		type: 'radioButtonGroupContainer',
		styles: 'sectionHeading',
		state_field: 'Working_Hours_Adjustments',
	};
}

export function getWorkplaceSupportsSocial() {
	return {
		caption: 'Social Supports',
		elements: convertSupportsIntoRequiredPreferredNot(
			[...getSocialSupportsRaw()],
			'Social_Adjustments'
		),
		type: 'radioButtonGroupContainer',
		styles: 'sectionHeading',
		state_field: 'Social_Adjustments',
	};
}

export function getWorkplaceSupportsInstructions() {
	return {
		caption: 'Work Instructions Supports',
		elements: convertSupportsIntoRequiredPreferredNot(
			[...getWorkInstructionSupportsRaw()],
			'Work_Instruction_Adjustments'
		),
		type: 'radioButtonGroupContainer',
		styles: 'sectionHeading',
		state_field: 'Work_Instructions_Adjustments',
	};
}

export function getWorkplaceSupportsMeeting() {
	return {
		caption: 'Meeting Supports',
		elements: convertSupportsIntoRequiredPreferredNot(
			[...getMeetingSupportsRaw()],
			'Meeting_Adjustments'
		),
		type: 'radioButtonGroupContainer',
		styles: 'sectionHeading',
		state_field: 'Meeting_Adjustments',
	};
}

export function getWorkplaceSupportsFeedback() {
	return {
		caption: 'Feedback Supports',
		elements: convertSupportsIntoRequiredPreferredNot(
			[...getFeedbackSupportsRaw()],
			'Feedback_Adjustments'
		),
		type: 'radioButtonGroupContainer',
		styles: 'sectionHeading',
		state_field: 'Feedback_Adjustments',
	};
}

export function getWorkplaceSupportsRecruitment() {
	return {
		caption: 'Recruitment Supports',
		elements: convertSupportsIntoRequiredPreferredNot(
			[...getRecruitmentSupportsRaw()],
			'Recruitment_Adjustments'
		),
		type: 'radioButtonGroupContainer',
		styles: 'sectionHeading',
		state_field: 'Recruitment_Adjustments',
	};
}

function convertSupportsIntoRequiredPreferredNot(supports: any, stateField: string) {
	return supports.map((support: any) => {
		return {
			caption: support.caption,
			value: support.value ? support.value : support.caption,
			type: 'radioButtonGroup',
			typeOptions: 'customStateFields',
			styles: 'sectionHeading',
			stateField: stateField,
			options: [
				{
					caption: 'Required',
					prefix: 'Required_',
				},
				{
					caption: 'Preferred',
					prefix: 'Preferred_',
				},
				{ caption: 'Not Required' },
			],
		};
	});
}

function getPhysicalSupportsRaw() {
	return [
		{ caption: 'Headphones or other noise accommodations' },
		{ caption: 'Natural light or other lighting considerations' },
		{ caption: 'Minimal or no visual distractions (busy walkways or open plan)' },
		{ caption: 'Sensory accommodations such as smell or texture' },
	];
}

function getWorkingHoursSupportsRaw() {
	return [
		{ caption: 'Flexible hours or adjusted start times' },
		{ caption: 'The ability to work from home at least some of the time' },
	];
}

function getSocialSupportsRaw() {
	return [
		{ caption: 'Limited social interactions preferably with small groups or individuals' },
		{ caption: 'A social environment with the opportunity to meet new people' },
		{ caption: 'Support understanding social cues and interactions' },
		{ caption: 'A supportive culture that is accepting of different communication styles' },
		{ caption: 'The option to not attend large gatherings' },
	];
}

function getWorkInstructionSupportsRaw() {
	return [
		{ caption: 'The opportunity to learn by task demonstration' },
		{
			caption: 'Receiving instructions in writing or at least with details in a follow up email',
		},
		{ caption: 'Explicit instructions that remove ambiguity' },
		{ caption: 'The opportunity to ask questions and clarify instructions' },
		{ caption: 'Support with understanding workplace communication' },
		{ caption: 'Additional time to complete tasks' },
	];
}

function getMeetingSupportsRaw() {
	return [
		{ caption: 'A proxy to present work or information in meetings' },
		{ caption: 'The option to not attend large gatherings' },
		{ caption: 'A supportive culture that is accepting of different communication styles' },
	];
}

function getFeedbackSupportsRaw() {
	return [
		{ caption: 'Regular communication and feedback from a Manager' },
		{ caption: 'Unambiguous feedback from a Manager that is detailed and direct' },
		{ caption: 'Supportive feedback from a Manager that is calm and gentle' },
		{ caption: 'Written feedback that can be referred back to' },
	];
}

function getRecruitmentSupportsRaw() {
	return [
		{ caption: 'Recognition that skills have been attained outside traditional education' },
		{ caption: 'The option to conduct interviews in writing instead of verbally' },
		{ caption: 'Reducing the number of people on an interview panel to no more than 2' },
		{ caption: 'Receiving details of the interview in advance including interview questions' },
		{ caption: "Adjustments to the interview room's physical environment" },
		{ caption: 'Submitting a work sample as evidence of skills' },
	];
}

function getMarketingSourcesRaw() {
	return [
		{ caption: 'Internet Search' },
		{ caption: 'Psychologist' },
		{ caption: 'NDIS Provider / NDIS Co-ordinator' },
		{ caption: 'Disability Employment Service Provider' },
		{ caption: 'Family / Friend' },
		{ caption: 'News Article' },
		{ caption: 'LinkedIn' },
		{ caption: 'Facebook' },
		{ caption: 'Generations Australia' },
		{ caption: 'Other' },
	];
}

function getJobMotivationsRaw() {
	return [
		{ caption: 'To earn money' },
		{ caption: 'To achieve' },
		{ caption: 'For purpose' },
		{ caption: 'To meet people' },
		{ caption: 'To learn' },
		// Zoho has an 'other' entry that doesn't serve any purpose and could be removed
	];
}

function getPreferredTechLanguagesRaw() {
	return [
		{ caption: 'C#' },
		{ caption: 'Java' },
		{ caption: 'C' },
		{ caption: 'JavaScript' },
		{ caption: 'Python' },
		{ caption: 'C++' },
		{ caption: 'PHP' },
		{ caption: 'HTML/CSS' },
		{ caption: 'SQL' },
		{ caption: 'JS & React' },
		{ caption: 'Ruby' },
		{ caption: 'Data Science' },
		{ caption: 'Cyber Security' },
		{ caption: 'My preferred option is not listed' },
	];
}

export function getFElanguages() {
	return {
		caption: 'FE',
		elements: convertTechLanguagesIntoNoneJrMidSr([...getFElanguagesRaw()], ''),
		type: 'radioButtonGroupContainer',
		styles: 'sectionHeading',
	};
}

export function getBElanguages() {
	return {
		caption: 'BE',
		elements: convertTechLanguagesIntoNoneJrMidSr([...getBElanguagesRaw()], ''),
		type: 'radioButtonGroupContainer',
		styles: 'sectionHeading',
	};
}

export function getDataTools() {
	return {
		caption: 'Data Tools',
		elements: convertTechLanguagesIntoNoneJrMidSr([...getDataToolsRaw()], ''),
		type: 'radioButtonGroupContainer',
		styles: 'sectionHeading',
	};
}

function convertTechLanguagesIntoNoneJrMidSr(languages: any, stateField: string) {
	return languages.map((language: any) => {
		return {
			caption: language.caption,
			value: language.value,
			type: 'radioButtonGroup',
			typeOptions: 'customStateFields',
			styles: 'sectionHeading',
			stateField: stateField,
			options: [
				{
					caption: 'None',
					onValue: true,
					offValue: false,
				},
				{
					caption: 'Junior',
					onValue: true,
					offValue: false,
					stateField: [stateField, 'Junior'],
				},
				{
					caption: 'Middle',
					onValue: true,
					offValue: false,
					stateField: [stateField, 'Middle'],
				},
				{
					caption: 'Senior',
					onValue: true,
					offValue: false,
					stateField: [stateField, 'Senior'],
				},
			],
		};
	});
}

// Raw data functions END
