import React from 'react';

interface Props {
	bgColor: string;
	mainColor: string;
}

export const LightBulbSVG = (props: Props) => {
	return (
		<svg
			width="46px"
			height="46px"
			viewBox="0 0 46 46"
			version="1.1"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g id="icon/jobs-blue">
				<g id="Group-7">
					<path
						d="M23 46C35.7025 46 46 35.7025 46 23C46 10.2975 35.7025 0 23 0C10.2975 0 0 10.2975 0 23C0 35.7025 10.2975 46 23 46Z"
						id="Oval"
						fill={props.bgColor}
						fillOpacity="0.3094308"
						stroke="none"
					/>
				</g>
				<path
					d="M18.75 9.375C18.75 4.21875 14.5313 0 9.375 0C4.21875 0 0 4.21875 0 9.375C0 12.8125 1.875 15.9375 4.6875 17.5L4.6875 20.3125L14.0625 20.3125L14.0625 17.5C16.875 15.9375 18.75 12.8125 18.75 9.375Z"
					transform="translate(14 11)"
					id="Path"
					fill={props.mainColor}
					stroke="none"
				/>
				<path
					d="M3.125 3.125L6.25 3.125C7.96875 3.125 9.375 1.71875 9.375 0L0 0C0 1.71875 1.40625 3.125 3.125 3.125Z"
					transform="translate(18.6875 32.875)"
					id="Path"
					fill={props.mainColor}
					stroke="none"
				/>
			</g>
		</svg>
	);
};
