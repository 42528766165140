import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.css';

import { SpinnerSVG } from './SVG/SpinnerSVG';

export const Spinner = (props: { caption?: string }) => {
	return (
		<div className={styles.spinnerContainer}>
			<span className={styles.spinnerAnim}>
				<SpinnerSVG />
			</span>
			{props.caption && <div className={styles.caption}>{props.caption}</div>}
		</div>
	);
};
