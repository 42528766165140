import React, { useState } from 'react';
import styles from './styles.module.css';
import { ArrowButton } from 'src/components/ArrowButton';
import { TextInput } from 'src/components/TextInput';
import { Checkbox } from '../Checkbox';

interface Props {
	onCreateAccountClick(firstName: string, lastName: string, email: string, password: string): void;
	emailValid(email: string): boolean;
	passwordValid(password: string): boolean;
}

const SignUpInput = (props: Props) => {
	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [hasUserConsented, setHasUserConsented] = useState(false);

	return (
		<div className={styles.container}>
			<div className={styles.panel}>
				<div className={styles.row}>
					<div className={styles.cell}>
						<TextInput
							caption={'First name'}
							value={firstName}
							onValueChanged={(value) => setFirstName(value)}
							isRequired
							additionalStyles={styles}
						/>
					</div>
					<div className={styles.cell}>
						<TextInput
							caption={'Last name'}
							value={lastName}
							onValueChanged={(value) => setLastName(value)}
							isRequired
							additionalStyles={styles}
						/>
					</div>
				</div>

				<div className={styles.row}>
					<div className={styles.cell}>
						<TextInput
							caption={'Email address'}
							value={email}
							onValueChanged={(value) => setEmail(value.trim())}
							isRequired
							validate={(value) => props.emailValid(value)}
							additionalStyles={styles}
							validationMsg={'Please provide a valid email'}
						/>
					</div>
					<div className={styles.cell}>
						<TextInput
							caption={'Password'}
							value={password}
							onValueChanged={(value) => setPassword(value)}
							isPasswordInput={true}
							isRequired
							validate={(value) => props.passwordValid(value)}
							additionalStyles={styles}
						/>
					</div>
				</div>
				<div className={styles.passwordText}>
					Passwords require 8+ characters with a letter, number, and symbol
				</div>
			</div>

			<div className={styles.footer}>
				<div className={styles.footerItem}>
					<Checkbox
						caption={
							<>
								I agree to{' '}
								<a href="https://xceptional.io/terms" target="_blank">
									Xceptional’s General Terms and Conditions
								</a>{' '}
								and{' '}
								<a href="https://xceptional.io/end-user-agreement" target="_blank">
									End User Agreement
								</a>
								.
							</>
						}
						checked={hasUserConsented}
						onChecked={(value) => {
							setHasUserConsented(value);
						}}
					/>
				</div>

				<div className={styles.footerItem}>
					<ArrowButton
						caption={'Create Account'}
						onClick={() => props.onCreateAccountClick(firstName, lastName, email, password)}
						isDisabled={
							!(
								hasUserConsented &&
								firstName !== '' &&
								lastName !== '' &&
								props.emailValid(email) &&
								props.passwordValid(password)
							)
						}
					/>
				</div>
			</div>
		</div>
	);
};

export { SignUpInput };
