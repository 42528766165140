export interface TextInputState {
	checked: boolean;
	value: string;
	hasError: boolean;
	errorMsg: string;
}

export enum InputControlType {
	TextField = 0,
	PasswordField = 1,
	MultiLineField = 2,
}
