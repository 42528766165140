import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.css';
import { PuzzlePiece } from './SVGs/PuzzlePiece';
import { Skills } from './SVGs/Skills';
import { SignUpKey } from './SVGs/SignUpKey';

import { Link } from 'gatsby';
import { LinkGetProps } from 'reach__router';
import PersonIcon from './SVGs/PersonIcon';

interface Props {
	title: string;
	badgeValue?: number;
	icon: number;
	to: string;
}

const isActive = ({ isCurrent, href, location }: LinkGetProps) => {
	if (location.pathname.includes('puzzle') && href === '/') {
		return { className: classNames(styles.activityIrrelevant, styles.active) };
	}
	return isCurrent
		? { className: classNames(styles.activityIrrelevant, styles.active) }
		: { className: classNames(styles.activityIrrelevant, styles.inactive) };
};
const NavMenuItem = (props: Props) => {
	const iconMap: { [key: number]: any } = {
		1: <PuzzlePiece />,
		2: <Skills />,
		3: <SignUpKey />,
		4: <PersonIcon />,
	};
	const icon = iconMap[props.icon];
	return (
		<Link to={props.to} getProps={isActive}>
			<div className={styles.icon}>{icon}</div>
			<div className={styles.title}>{props.title}</div>
			{props.badgeValue && <div className={styles.skillNumber}>{props.badgeValue}</div>}
		</Link>
	);
};

export { NavMenuItem };
