import React, { useState } from 'react';
import styles from './styles.module.css';
import { EmailSVG } from 'src/components/SVGs/EmailSVG';
import { ArrowButton } from '../ArrowButton';

interface Props {
	headingText?: string | undefined;
	bodyText?: string | undefined;
	showButton?: boolean | undefined;
	buttonCaption?: string | undefined;
	buttonSentCaption?: string | undefined;
	onButtonClick?: (setIsDisabled: React.Dispatch<React.SetStateAction<boolean>>) => {};
}

export const AfterEmailConfirmation = (props: Props) => {
	const [isDisabled, setIsDisabled] = useState(false);
	return (
		<div className={styles.centered}>
			<div className={styles.content}>
				<div className={styles.heading}>
					{props.headingText ? props.headingText : 'An email has been sent'}
				</div>
				<EmailSVG />
				<div className={styles.body}>
					{props.bodyText ? props.bodyText : 'Check your inbox for details'}
				</div>
				{props.showButton ? (
					!isDisabled ? (
						<ArrowButton
							caption={props.buttonCaption ? props.buttonCaption : 'Resend email'}
							onClick={async () => {
								props.onButtonClick ? props.onButtonClick(setIsDisabled) : null;
							}}
						/>
					) : (
						<ArrowButton
							caption={props.buttonSentCaption ? props.buttonSentCaption : 'Email sent'}
							isDisabled={true}
						/>
					)
				) : null}
			</div>
		</div>
	);
};
