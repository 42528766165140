import React, { useEffect, useState } from 'react';
import styles from './styles.module.css';
import colors from 'src/css/colors.module.css';
import { ChatBubbleSVG } from './components/ChatBubbleSVG';
import closeX from 'src/images/CloseX.png';
import { TextInput } from 'src/components/TextInput';
import { ArrowButton } from 'src/components/ArrowButton';
import { Checkbox } from 'src/components/Checkbox/component';

export interface CodeArea {
	caption: string;
	value?: boolean;
	id?: any;
}

interface Props {
	comment: string;
	isBottomBox: boolean;
	codeAreas: CodeArea[];
	onSubmitClick(codeAreas: CodeArea[], otherCodeArea?: string): any;
}

export const AreasOfCodeNotification = (props: Props) => {
	const [visible, setVisible] = useState(props.isBottomBox);
	const [codeAreas, setCodeAreas] = useState(props.codeAreas);
	const [codeAreaOther, setCodeAreaOther] = useState('');
	const [hasValidResponses, setHasValidResponses] = useState(false);

	useEffect(() => {
		setHasValidResponses(codeAreaOther.length > 0 || codeAreas.some((area) => area.value === true));
	});

	const setCodeArea = (index: number, checked: boolean) => {
		var areas = [...codeAreas];
		areas[index].value = checked;
		setCodeAreas(areas);
	};

	return (
		<>
			{visible ? (
				<div className={props.isBottomBox ? styles.bottomNotificationBox : styles.notificationBox}>
					<button onClick={() => setVisible(false)} className={styles.closeButton}>
						{/*	close icon is not an svg? Can't export from Lunacy/invision.*/}
						<img src={closeX} className={styles.close} alt="Close" />
					</button>
					<div>
						<ChatBubbleSVG mainColor={colors.SVGGreen} bgColor={colors.SVGLightGreen} />
					</div>
					<div className={styles.questionText}>{props.comment}</div>
					<div className={styles.inputFields}>
						{codeAreas.map((item, index) => (
							<div className={styles.checkboxContainer} key={index}>
								<Checkbox
									caption={item.caption}
									checked={item.value || false}
									onChecked={(value) => setCodeArea(index, value)}
								/>
							</div>
						))}
						<TextInput
							caption="Other"
							value={codeAreaOther}
							onValueChanged={(value) => setCodeAreaOther(value)}
							additionalStyles={styles}
						/>
						<ArrowButton
							caption={'Submit'}
							onClick={() => props.onSubmitClick(codeAreas, codeAreaOther)}
							isDisabled={!hasValidResponses}
							additionalStyles={styles}
						/>
					</div>
				</div>
			) : (
				<div
					className={styles.flippedBox}
					aria-label={'Flipped Notification'}
					tabIndex={0}
					onFocus={() => setVisible(true)}
					onClick={() => setVisible(true)}
				>
					<div>
						<ChatBubbleSVG mainColor={colors.white} bgColor={colors.SVGlightestBlue} />
					</div>
				</div>
			)}
		</>
	);
};
