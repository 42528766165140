import React from 'react';
import styles from './styles.module.css';

import { PageLink } from '../PageLink.interface';

interface Props {
	topNavPages: PageLink[];
}

export const TopNav = (props: Props) => {
	return (
		<div className={styles.topNav}>
			{props.topNavPages.map(page => {
				return (
					<a className={styles.links} key={page.title} href={page.url}>
						{page.title}
					</a>
				);
			})}
		</div>
	);
};
