import * as Renderer from './components/Renderer/component';
import { ProfileData } from './components/Builder/common';
import * as logic from './components/Builder/logic';

// TODO: Create visual editor to make editing pages more accessible

export function getPageByName(controller: any, name: String) {
	if (name.toLowerCase() == 'profile') return Renderer.getProfilePage(controller);
	if (name.toLowerCase() == 'questionnaire') return Renderer.getQuestionnairePage(controller);
	return null;
}

export function getProfilePage(controller: any) {
	return Renderer.getProfilePage(controller);
}

export function getQuestionnairePage(controller: any) {
	return Renderer.getQuestionnairePage(controller);
}

export function getProfileDownloadPage(controller: any) {
	return Renderer.getProfileDownloadPage(controller);
}

export function setProfileDataCountries(countries: any) {
	return Renderer.setProfileDataCountries(countries);
}

export function getWorkplaceSupports() {
	return Renderer.getWorkplaceSupports();
}

export function getTechLanguages() {
	return Renderer.getTechLanguages();
}

export function getProfileData() {
	return ProfileData;
}

export async function profileDetailsComponentDidMount(controller: any) {
	await logic.profileDetailsComponentDidMount(controller);
}
