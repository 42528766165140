import { ApolloManager } from 'src/helpers/ApolloManager';
import { Client } from '../ClientManager';
import { KeystoneUser } from '../UserManager';
import getRegistrationForUser from './getRegistrationForUser.graphql';
import createRegistration from './createRegistration.graphql';
import updateRegistration from './updateRegistration.graphql';
import { CouponCode } from '../CouponCodeManager/CouponCode';

interface RegistrationBase {
	id?: number;
	returnUrl?: string;
	created?: Date;
	createdBy?: KeystoneUser | number;
	accepted?: Date;
}

export interface Registration extends RegistrationBase {
	couponCode?: CouponCode;
	user?: KeystoneUser;
	client?: Client;
}

interface RegistrationCreate extends RegistrationBase {
	user?: number;
	client?: { connect: { id: number } };
	group?: { connect: { id: number } };
	couponCode?: { connect: { id: number } };
}

interface RegistrationUpdate extends RegistrationBase {
	id: number;
	user?: number;
	client?: number;
}

interface getRegistrationForUserResult {
	registrations: Registration[];
}

interface registrationUpdatedResult {
	registration: Registration;
	error?: string;
}

interface createdRegistrationResult {
	createdRegistration?: Registration;
}

interface updatedRegistrationResult {
	updatedRegistration?: Registration;
}

class RegistrationImplementation {
	public readonly getRegistrationsForUser = async (
		userId: number
	): Promise<Registration[] | undefined> => {
		try {
			const result = await ApolloManager.client.query<getRegistrationForUserResult>({
				query: getRegistrationForUser,
				variables: { userId },
			});
			if (result.data.registrations && result.data.registrations.length > 0) {
				return result.data.registrations;
			}
		} catch (error) {
			console.error(`An error occurred fetching registrations for user: Error was ${error}`);
		}
		return;
	};

	public readonly getMostRecentRegistrationForUser = async (
		userId?: number
	): Promise<Registration | undefined> => {
		if (!!!userId) return undefined;
		const registrations = await this.getRegistrationsForUser(userId);
		let result: Registration | undefined = undefined;
		registrations?.map((registration: Registration | undefined) => {
			if (
				!!!result ||
				(!!registration && !!registration.id && !!result.id && registration.id > result.id)
			)
				result = registration;
		});
		return result;
	};

	public readonly createRegistration = async (
		registrationCreateData: RegistrationCreate
	): Promise<registrationUpdatedResult | undefined> => {
		try {
			const result = await ApolloManager.client.mutate<createdRegistrationResult>({
				mutation: createRegistration,
				variables: registrationCreateData,
			});
			if (result?.data?.createdRegistration?.id) {
				return { registration: result.data.createdRegistration };
			}
		} catch (error: any) {
			console.error(`An error occurred updating registration: Error was ${error}`);
			console.error(`An error occurred creating registration: Error was ${error}`);
		}
		return;
	};

	public readonly updateRegistration = async (
		registrationUpdateData: RegistrationUpdate
	): Promise<registrationUpdatedResult | undefined> => {
		try {
			const result = await ApolloManager.client.mutate<updatedRegistrationResult>({
				mutation: updateRegistration,
				variables: registrationUpdateData,
			});
			if (result?.data?.updatedRegistration?.id) {
				return { registration: result.data.updatedRegistration };
			}
		} catch (error: any) {
			console.error(`An error occurred updating registration: Error was ${error}`);
		}
		return;
	};

	public readonly acceptRegistration = async (
		userId?: number
	): Promise<Registration | undefined> => {
		if (userId) {
			const registration = await this.getMostRecentRegistrationForUser(userId);
			if (registration?.id && !registration.accepted) {
				await this.updateRegistration({ id: registration.id, accepted: new Date() });
			}
		}
		return;
	};
}

export const RegistrationManager = new RegistrationImplementation();
