import { getProfileDataElements } from './common';
import * as logic from 'src/components/PageBuilder/components/Builder/logic';
import { ProfileBuilder } from './profiledetails';

class QuestionnaireImplementation {
	public BuildQuestionnaireDict = [
		{
			id: '1',
			caption: '',
			type: 'tabs',
			defaultActiveKey: 1,
			tabPosition: 'top',
			elements: [
				{
					id: '1',
					caption: 'PART 1',
					type: 'tabpane',
					key: 1,
					elements: this.constructQuestionnairePage1(),
				},
				{
					id: '2',
					caption: 'PART 2',
					type: 'tabpane',
					key: 2,
					elements: this.constructQuestionnairePage2(),
					hidden: (controller: any) => !logic.isInAustralia(controller),
				},
				{
					id: '3',
					caption: 'PART 2',
					type: 'tabpane',
					key: 3,
					elements: this.constructQuestionnairePage2International(),
					hidden: (controller: any) => logic.isInAustralia(controller),
				},
				{
					id: '4',
					caption: 'PART 3',
					type: 'tabpane',
					key: 4,
					elements: this.constructQuestionnairePage3(),
				},
				{
					id: '5',
					caption: 'PART 4',
					type: 'tabpane',
					key: 5,
					elements: this.constructQuestionnairePage4(),
				},
				{
					id: '6',
					caption: 'PART 5',
					type: 'tabpane',
					key: 6,
					elements: this.constructQuestionnairePage5(),
				},
				{
					id: '7',
					caption: 'PART 6',
					type: 'tabpane',
					key: 7,
					elements: this.constructQuestionnairePage6(),
				},
			],
		},
	];

	private showQuestionnarePage(page: number, controller: any) {
		var result = true;
		if (page == 1) return result;
		result =
			result &&
			controller.state['country'] &&
			controller.state['firstName'] &&
			controller.state['lastName'] &&
			controller.state['emailAddress']
				? true
				: false;
		if (page == 2) return result;
		return result;
	}

	private constructQuestionnairePage1() {
		const result = [
			{
				type: 'container',
				styles: 'column',
				elements: [
					{
						type: 'container',
						styles: 'column',
						elements: getProfileDataElements([
							'First Name',
							'Last Name',
							'Pronouns',
							'Email',
							'Country',
							'marketingSource',
						]),
					},
				],
			},
		];
		return result;
	}

	private constructQuestionnairePage2() {
		const result = [
			{
				type: 'container',
				styles: 'column',
				elements: [
					{
						type: 'container',
						styles: 'column',
						elements: [
							{
								type: 'container',
								styles: 'column',
								elements: [
									{
										type: 'container',
										styles: 'sectionHeading',
										elements: getProfileDataElements(['workRights']),
									},
								],
							},
						].concat(
							getProfileDataElements(['Street Address', 'Suburb', 'State', 'Postcode', 'Mobile'])
						),
						hidden: (controller: any) => {
							return !this.showQuestionnarePage(2, controller);
						},
					},
					{
						type: 'container',
						styles: 'column',
						elements: [
							{
								type: 'container',
								styles: 'questionText',
								elements: [
									{
										type: 'text',
										caption:
											'Please select all required fields (First name, Last name, Email, Country)',
									},
								],
							},
						],
						//hidden: false,
						hidden: (controller: any) => {
							return this.showQuestionnarePage(2, controller);
						},
					},
				],
			},
		];
		return result;
	}

	private constructQuestionnairePage2International() {
		const result = [
			{
				type: 'container',
				styles: 'column',
				elements: [
					{
						type: 'container',
						styles: 'column',
						elements: getProfileDataElements(['City/Town', 'State']),
						hidden: (controller: any) => {
							return !this.showQuestionnarePage(2, controller);
						},
					},
					{
						type: 'container',
						styles: 'column',
						elements: [
							{
								type: 'container',
								styles: 'questionText',
								elements: [
									{
										type: 'text',
										caption:
											'Please select all required fields (First name, Last name, Email, Country)',
									},
								],
							},
						],
						//hidden: false,
						hidden: (controller: any) => {
							return this.showQuestionnarePage(2, controller);
						},
					},
				],
			},
		];
		return result;
	}

	private constructQuestionnairePage3() {
		const result = [
			{
				type: 'container',
				styles: 'column',
				elements: [
					{
						type: 'container',
						styles: 'column',
						elements: getProfileDataElements([
							'autismDiagnosisAlternate',
							'neurodiversityAlternate',
							'intellectualDisability',
						]),

						hidden: (controller: any) => {
							return !this.showQuestionnarePage(3, controller);
						},
					},
					{
						type: 'container',
						styles: 'column',
						elements: [
							{
								type: 'container',
								styles: 'questionText',
								elements: [
									{
										type: 'text',
										caption:
											'Please select all required fields (First name, Last name, Email, Country)',
									},
								],
							},
						],
						//hidden: false,
						hidden: (controller: any) => {
							return this.showQuestionnarePage(3, controller);
						},
					},
				],
			},
		];
		return result;
	}

	private constructQuestionnairePage4() {
		const result = [
			{
				type: 'container',
				styles: 'column',
				elements: [
					{
						type: 'container',
						styles: 'column',
						elements: getProfileDataElements([
							'hoursPerWeek',
							'workFromHome',
							'relocateForWork',
							'salaryRange',
							'jobMotivations',
							'interests',
						]),

						hidden: (controller: any) => {
							return !this.showQuestionnarePage(3, controller);
						},
					},
					{
						type: 'container',
						styles: 'column',
						elements: [
							{
								type: 'container',
								styles: 'questionText',
								elements: [
									{
										type: 'text',
										caption:
											'Please select all required fields (First name, Last name, Email, Country)',
									},
								],
							},
						],
						//hidden: false,
						hidden: (controller: any) => {
							return this.showQuestionnarePage(3, controller);
						},
					},
				],
			},
		];
		return result;
	}

	private constructQuestionnairePage5() {
		return ProfileBuilder.constructProfileWorkplaceSupportsv2();
	}

	private constructQuestionnairePage6() {
		const result = [
			{
				type: 'container',
				styles: 'column',
				elements: [
					{
						type: 'container',
						styles: 'column',
						elements: getProfileDataElements([
							'mostRecentRole',
							'workCategories',
							'education',
							'qualifications',
							'anyOtherComments',
						]),

						hidden: (controller: any) => {
							return !this.showQuestionnarePage(3, controller);
						},
					},
					{
						type: 'container',
						styles: 'column',
						elements: [
							{
								type: 'container',
								styles: 'questionText',
								elements: [
									{
										type: 'text',
										caption:
											'Please select all required fields (First name, Last name, Email, Country)',
									},
								],
							},
						],
						//hidden: false,
						hidden: (controller: any) => {
							return this.showQuestionnarePage(3, controller);
						},
					},
				],
			},
		];
		return result;
	}
}

export const QuestionnaireBuilder = new QuestionnaireImplementation();
