import { ApolloManager } from 'src/helpers/ApolloManager';
import startPuzzleQuestion from './puzzleSession.graphql';
import answerQuestion from './answerQuestion.graphql';
import getCompletedPuzzleCount from './getCompletedPuzzlesByUser.graphql';
import getAllPuzzleSessionScoresForUser from './getAllPuzzleSessionScoresForUser.graphql';
import getAllPuzzleSessionScoresForAllUsers from './getAllPuzzleSessionScoresForAllUsers.graphql';
import NullUpdatePuzzleSessionQuestion from './triggerUpdate.graphql';
import getPuzzleSessionScore from './getPuzzleSessionScore.graphql';
import getHighScorePopupThresholdForPuzzleSession from './getHighScorePopupThresholdForPuzzleSession.graphql';

export interface PuzzleSession {
	id?: number;
	sessionQuestions?: SessionQuestion[];
	puzzle?: {
		id?: number;
	};
}

export interface Question {
	id?: number;
	sequenceNumber?: number;
	specification?: string;
	puzzle?: {
		id?: number;
	};
}

export interface SessionQuestion {
	id?: number;
	session?: PuzzleSession;
	question?: Question;
	answer?: string;
	skipped?: boolean;
	correct: boolean | null;
}

export interface Skill {
	id: number;
	description: string;
	image: 'bulb' | 'magnifyingGlass';
}

// return type of graphql call
interface StartNextQuestionResult {
	startPuzzleQuestion: {
		puzzleSessionQuestion?: SessionQuestion;
		acquiredSkills: Skill[];
	};
}

interface AnsweredSessionQuestionResult {
	answerQuestion?: SessionQuestion;
}

export interface SessionQuestionResult {
	sessionQuestion?: SessionQuestion;
	acquiredSkills?: Skill[];
	error?: string;
}

interface GetCompletedPuzzlesByUserResult {
	getCompletedPuzzlesByUser: [
		{
			completedPuzzles: number;
		}
	];
}

interface GetAllPuzzleSessionScoresResult {
	allPuzzleSessions: [
		{
			id: number;
			user: {
				id: number;
			};
			puzzle: {
				id: number;
				title?: string;
				puzzleType: {
					id: number;
					skills: [
						{
							id: number;
							description?: string;
						}
					];
				};
			};
			sessionQuestionsCount: number;
			sessionQuestions: [
				{
					id: number;
					dateTimeAnswered?: Date;
					skipped?: boolean;
					correct?: boolean;
					score?: boolean;
				}
			];
		}
	];
}

interface GetPuzzleSessionScoreResult {
	allPuzzleSessions: { sessionQuestions: { correct: boolean | null }[] }[];
}

interface GetHighScorePopupThresholdResult {
	allPuzzleSessions: { puzzle: { highScorePopupThreshold: number } }[];
}

// export type SessionQuestionOrError = SessionQuestion | ErrorResponse;

class PuzzleSessionImplementation {
	/**
	 * Starts - or progresses - a new question from a session question.
	 *
	 * If the puzzleSessionId is omitted, this function will locate the user's current (ie. non-completed) session for the
	 *   provided puzzle id and return the puzzleSessionQuestionResult they are currently on. If no such session exists, a new session
	 *   will be created. If the puzzleSessionId is provided, and no session exists, an error will be thrown.
	 *   If the puzzleSessionId is provided but is complete, a null record will be returned.
	 *
	 * When a question is started, its 'dateTimeStarted' field value is set to the current date and time.
	 *
	 * @param {number} puzzleId - The unique identifier for the puzzle the user is attempting to undertake.
	 * @param {string} puzzleSessionId? - (Optional) The user's current puzzleSessionId.
	 *
	 * @returns {SessionQuestionResult} - The session question that is being started / progressed and all its related information
	 *   This includes updates after the answer has been provided.
	 */
	public readonly startNextQuestion = async (
		puzzleId: number,
		userId: number,
		puzzleSessionId?: number
	): Promise<SessionQuestionResult> => {
		const variables: {
			puzzleId: number;
			userId: number;
			puzzleSessionId?: number;
		} = {
			puzzleId,
			userId,
		};
		if (puzzleSessionId) variables.puzzleSessionId = puzzleSessionId;

		try {
			const result = await ApolloManager.client.mutate<StartNextQuestionResult>({
				mutation: startPuzzleQuestion,
				variables,
			});
			if (!result.data?.startPuzzleQuestion) {
				throw new Error('No data received for request to startPuzzleQuestion');
			}
			return {
				sessionQuestion: result.data.startPuzzleQuestion.puzzleSessionQuestion,
				acquiredSkills: result.data?.startPuzzleQuestion.acquiredSkills,
			};
		} catch (error: any) {
			console.error('Error starting the next question. Error was: ' + JSON.stringify(error));
		}

		return {
			error: `Failed to start the question for: puzzleId: ${puzzleId}`,
		};
	};

	/**
	 * Answers a specific question for the current session for the user.
	 *
	 * @param {number} sessionQuestionId - The unique identifier for the session question.
	 * @param {string} answer? - (Optional) The answer to the sessionQuestion specified. If this is not supplied, the question will be skipped.
	 * @param {boolean} isMultiple? - (Optional) True if the answer is an array of coordinates.
	 *
	 * @returns {SessionQuestionResult} - The session question that is being answered and all its related information
	 *   This includes updates after the answer has been provided.
	 */
	public readonly answerQuestion = async (
		sessionQuestionId: number,
		answer?: string,
		isMultiple?: boolean
	): Promise<SessionQuestionResult> => {
		const variables = {
			sessionQuestionId,
			answer,
			isMultiple,
		};

		try {
			const result = await ApolloManager.client.mutate<AnsweredSessionQuestionResult>({
				mutation: answerQuestion,
				variables,
			});
			if (!result.data?.answerQuestion) {
				throw new Error('No data received for request to answerQuestion');
			}

			return { sessionQuestion: result.data.answerQuestion };
		} catch (error: any) {
			console.error(`Error answering the question for ${sessionQuestionId}`, error);
		}

		return {
			error: `Failed to answer the question for: puzzleSessionQuestionId: ${sessionQuestionId}`,
		};
	};

	public readonly getCompletedPuzzleCount = async (userId?: number) => {
		if (!!!userId) return 0;
		try {
			const variables = { userId: parseInt(userId.toString()) };
			const result = await ApolloManager.client.query<GetCompletedPuzzlesByUserResult>({
				query: getCompletedPuzzleCount,
				variables,
				fetchPolicy: 'no-cache',
			});
			if (!result?.data) {
				throw new Error('Result is undefined.');
			}
			return !result.data.getCompletedPuzzlesByUser[0]?.completedPuzzles
				? 0
				: result.data.getCompletedPuzzlesByUser[0].completedPuzzles;
		} catch (error: any) {
			throw new Error(
				`Failed to obtain completed puzzle count for user ${userId}. ${error.message}`
			);
		}
	};

	public readonly getAllPuzzleSessionScoresForUser = async (userId: number) => {
		try {
			const variables = { user: parseInt(userId.toString()) };
			const result = await ApolloManager.client.query<GetAllPuzzleSessionScoresResult>({
				query: getAllPuzzleSessionScoresForUser,
				variables,
				fetchPolicy: 'no-cache',
			});
			if (!result?.data) {
				throw new Error('Result is undefined.');
			}
			return result.data;
		} catch (error: any) {
			throw new Error(
				`Failed to obtain all puzzle session scores for user ${userId}. ${error.message}`
			);
		}
	};

	public readonly getBestScoresForSkillsForUser = async (userId: number) => {
		try {
			return this.convertPuzzleSessionScoresToBestScores(
				await this.getAllPuzzleSessionScoresForUser(userId)
			);
		} catch (error: any) {
			`Failed to obtain best scores for user ${userId}. ${error.message}`;
			return [];
		}
	};

	public readonly getAllPuzzleSessionScoresForAllUsers = async () => {
		try {
			const result = await ApolloManager.client.query<GetAllPuzzleSessionScoresResult>({
				query: getAllPuzzleSessionScoresForAllUsers,
				fetchPolicy: 'no-cache',
			});
			if (!result?.data) {
				throw new Error('Result is undefined.');
			}
			return result.data;
		} catch (error: any) {
			throw new Error(`Failed to obtain all puzzle session scores for all users. ${error.message}`);
		}
	};

	public readonly getBestScoresForSkillsForAllUsers = async () => {
		try {
			let result: { user: number; id: number; description?: string; score: number }[] = [];
			return this.convertPuzzleSessionScoresToBestScores(
				await this.getAllPuzzleSessionScoresForAllUsers()
			);
		} catch (error: any) {
			`Failed to obtain best scores for all users. ${error.message}`;
			return [];
		}
	};

	private convertPuzzleSessionScoresToBestScores(data: GetAllPuzzleSessionScoresResult) {
		let result: { user: number; id: number; description?: string; score: number }[] = [];
		data.allPuzzleSessions.forEach((puzzleSession) => {
			let score: number = 0;
			let answered: number = 0;
			puzzleSession.sessionQuestions.forEach((sessionQuestion) => {
				score += sessionQuestion.correct ? 1 : 0;
				answered += 1;
			});
			// If the user has answered every question available in this puzzleSession ...
			if ((answered = puzzleSession.sessionQuestionsCount)) {
				score = score / puzzleSession.sessionQuestionsCount;
				// ... then add this score to all linked skills, but only if it is the best score
				puzzleSession.puzzle?.puzzleType?.skills?.forEach((skill) => {
					let added = false;
					// If there is already a score for this user update it, otherwise insert a new record
					result.forEach((resultElement, index) => {
						if (resultElement.id === skill.id && resultElement.user == puzzleSession.user.id) {
							added = true;
							result[index].score = resultElement.score < score ? score : resultElement.score;
						}
					});
					if (!added) {
						result.push({
							user: puzzleSession.user.id,
							id: skill.id,
							description: skill.description,
							score: score,
						});
					}
				});
			}
		});
		return result;
	}

	public readonly getCompletedPuzzleIdsForUser = async (userId?: number) => {
		if (!!!userId) return [];
		return this.convertPuzzleSessionsToCompletedPuzzleIds(
			await this.getAllPuzzleSessionScoresForUser(userId)
		);
	};

	private convertPuzzleSessionsToCompletedPuzzleIds(data: GetAllPuzzleSessionScoresResult) {
		let puzzleIds: number[] = [];
		data.allPuzzleSessions.forEach((puzzleSession) => {
			let answered: number = 0;
			puzzleSession.sessionQuestions.forEach((sessionQuestion) => {
				answered++;
			});
			// If the user has answered every question available in this puzzleSession ...
			if (answered == puzzleSession.sessionQuestionsCount) {
				// Check if the completed puzzle ID is already in the array.
				// If it isn't, add the relevant puzzle ID to the array of completed puzzles.
				if (!puzzleIds.includes(puzzleSession.puzzle?.id)) {
					puzzleIds.push(puzzleSession.puzzle?.id);
				}
			}
		});
		return puzzleIds;
	}

	public readonly getPuzzleSessionScore = async (sessionId: number) => {
		try {
			const variables = { sessionId: parseInt(sessionId.toString()) };
			const result = await ApolloManager.client.query<GetPuzzleSessionScoreResult>({
				query: getPuzzleSessionScore,
				variables,
				fetchPolicy: 'no-cache',
			});
			if (!result?.data) {
				throw new Error('Result is undefined.');
			}
			return result.data;
		} catch (error: any) {
			throw new Error(
				`Failed to obtain puzzle session score for session ${sessionId}. ${error.message}`
			);
		}
	};

	public readonly getHighScorePopupThreshold = async (puzzleSessionId: number) => {
		try {
			const variables = { puzzleSessionId: parseInt(puzzleSessionId.toString()) };
			const result = await ApolloManager.client.query<GetHighScorePopupThresholdResult>({
				query: getHighScorePopupThresholdForPuzzleSession,
				variables,
				fetchPolicy: 'no-cache',
			});
			if (!result?.data) {
				throw new Error('Result is undefined.');
			}
			return result.data.allPuzzleSessions[0].puzzle.highScorePopupThreshold;
		} catch (error: any) {
			throw new Error(
				`Failed to obtain the puzzle's high score popup threshold for session ${puzzleSessionId}. ${error.message}`
			);
		}
	};
}

export const PuzzleSessionManager = new PuzzleSessionImplementation();
