import React, { useState } from 'react';
import styles from './styles.module.css';
import { getProfileData } from 'src/components/PageBuilder/component';

interface TabProps {
	title: string;
}

interface TabTitleProps {
	title: string;
	index: number;
	setSelectedTab: (index: number) => void;
	selectedTab: number;
	vertical?: boolean;
}

interface TabsProps {
	// children is either ReactElement or an indirect reference container that has props accessible in index 1
	children: any[];
	vertical?: boolean;
	onChange?: (tab: number) => void;
	controller: any;
}

const Tab: React.FC<TabProps> = ({ children }) => {
	return <div>{children}</div>;
};

const TabTitle: React.FC<TabTitleProps> = ({
	title,
	setSelectedTab,
	index,
	selectedTab,
	vertical,
}) => {
	return (
		<li
			className={`${styles.li} ${vertical ? styles.verticalli : styles.horizontalli} ${
				selectedTab == index ? styles.activeli : undefined
			}`}
		>
			<button
				className={`${selectedTab == index ? styles.activetab : undefined} ${
					vertical ? styles.verticalbutton : styles.horizontalbutton
				}`}
				onClick={() => {
					setSelectedTab(index);
				}}
			>
				{title}
			</button>
		</li>
	);
};

const Tabs: React.FC<TabsProps> = ({ children, vertical, controller }) => {
	const [selectedTab, setSelectedTab] = useState(0);
	return (
		<div className={vertical ? styles.vertical : styles.horizontal}>
			<div className={`${styles.tabs} ${vertical ? styles.verticaltabs : styles.horizontaltabs}`}>
				<ul className={`${styles.ul} ${vertical ? styles.verticalul : styles.horizontalul}`}>
					{children.map((item, index) => {
						let title = item
							? item.props && item.props.title
								? item.props.title
								: item[1]
								? item[1].props?.title
								: undefined
							: undefined;
						if (title) {
							// Loop through all elements and for required elements displayed on this tab, check if they are filled
							getProfileData().map((element: any) => {
								if (
									element.isRequired &&
									(typeof element.isRequired != 'function' || element.isRequired(controller))
								) {
									if (element.requiredForTab == title) {
										if (
											!!!controller.state[element.stateField] ||
											controller.state[element.stateField] == ''
										) {
											title = `(!) ${title}`;
										}
									}
								}
							});
						}
						return title ? (
							<TabTitle
								key={index}
								title={title}
								index={index}
								setSelectedTab={setSelectedTab}
								selectedTab={selectedTab}
								vertical={vertical}
							/>
						) : undefined;
					})}
				</ul>
			</div>
			{children[selectedTab]}
		</div>
	);
};

export { Tab, Tabs };
