import { KeystoneUser, UserManager } from './UserManager';
import { GroupManager, UserGroupUpdate, UserGroupDelete } from './GroupManager';
import {
	AssessmentOnlyValue,
	generateProductsAndServices,
} from 'src/utils/generateProductsAndServices';

export interface SetupUserAccountResult {
	success: boolean;
	message: string;
	user?: KeystoneUser;
}

const isEmailValid = (email: string): boolean => {
	// Source: https://emailregex.com/
	const regEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return !!email.match(regEx) || false;
};

const isPwdValid = (password: string): boolean => {
	const regEx = /^(?=.*\d)(?=.*[a-zA-Z])(?=.*[\W]).{8,}$/;
	return !!password.match(regEx) || false;
};

const SetupUserAccount = async (
	firstName: string,
	lastName: string,
	email: string,
	password: string,
	country?: string,
	removeDefaultGroups?: boolean,
	customGroups?: { id: number }[],
	assessmentOnly?: boolean,
	source?: string
) => {
	let success: boolean = false;
	let message: string = '';

	firstName = firstName.trim();
	lastName = lastName.trim();
	email = email.trim();
	password = password.trim();

	// check if a guest id exists, if so, update the guest account, don't create user
	let user = await UserManager.getAuthenticatedUser();

	const profile: any = {};
	profile['Products & Services'] = !!assessmentOnly
		? AssessmentOnlyValue
		: await generateProductsAndServices(country || user?.country || undefined);
	profile['source'] = source ? source : undefined;
	const profileAsJson = JSON.stringify(profile);

	const defaultGroups = (await GroupManager.getAllGroups())
		.filter((group) => !!group.default)
		.map((group) => {
			return { id: Number(group.id) };
		});
	let groupsToConnect: { id: number }[] = !!!removeDefaultGroups ? defaultGroups : [];
	if (!!customGroups && !!customGroups[0]) groupsToConnect = groupsToConnect.concat(customGroups);
	let groups: any = { connect: groupsToConnect };
	if (user) {
		groups.disconnect = !!removeDefaultGroups ? defaultGroups : undefined;
		if (user.guest && user.id) {
			const updateUserResult = await UserManager.updateUser({
				id: user.id,
				firstName: firstName,
				lastName: lastName,
				email: email,
				password: password,
				guest: false,
				country,
				profileAsJson,
				groups,
			});
			if (updateUserResult?.user?.email) {
				success = true;
			} else {
				message = `Unable to create account.  This email may already be in use.`;
			}
		} else {
			message = 'Unable to create account.  Are you already signed in?';
		}
	} else {
		user = undefined;
		// no guest exists so create a user
		const result = await UserManager.createUser({
			firstName: firstName,
			lastName: lastName,
			email: email,
			password: password,
			country,
			profileAsJson,
			groups,
		});
		if (result.user?.id && !result.error) {
			success = true;
			user = result.user;
		} else {
			message = 'Unable to create account.  This email may already be in use.';
		}
	}

	const result: SetupUserAccountResult = { success: success, message: message, user: user };
	if (success) {
		var response = await UserManager.authenticate({
			email: email,
			password: password,
		});
		result.success = response && !response.error;
		if (!result.success) {
			result.message = `Account created, but unable to automatically log you in.  Try this via the Sign In page.`;
		}
	}

	return result;
};

export { isEmailValid, isPwdValid, SetupUserAccount };
