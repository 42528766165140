import { ApolloManager } from 'src/helpers/ApolloManager';
import getAllGroups from './getAllGroups.graphql';
import getAllAutoAssignGroups from './getAllAutoAssignGroups.graphql';
import updateUserGroups from './updateUserGroups.graphql';

interface Result {
	success: boolean;
	error?: string;
}

interface Group {
	id?: string;
	default?: boolean;
	title?: string;
	description?: string;
}

interface GroupsFetchResult {
	allGroups: Group[];
}

export interface UserGroupUpdate {
	id: number;
	groups: {
		disconnectAll?: boolean;
		connect: { id: number }[];
	};
}

export interface UserGroupDelete {
	id: number;
	groups: { disconnectAll: boolean };
}

class GroupImplementation {
	public readonly getAllGroups = async (): Promise<Group[]> => {
		try {
			const result = await ApolloManager.client.query<GroupsFetchResult>({
				query: getAllGroups,
				fetchPolicy: 'no-cache',
			});
			if (result.data.allGroups) {
				return result.data.allGroups;
			} else {
				return [];
			}
		} catch (error) {
			console.error(`An error occurred fetching all groups: Error was ${error}`);
		}
		return [];
	};

	public readonly getAllAutoAssignGroups = async (): Promise<Group[]> => {
		try {
			const result = await ApolloManager.client.query<GroupsFetchResult>({
				query: getAllAutoAssignGroups,
				fetchPolicy: 'no-cache',
			});
			if (result.data.allGroups) {
				return result.data.allGroups;
			} else {
				return [];
			}
		} catch (error) {
			console.error(`An error occurred fetching all groups: Error was ${error}`);
		}
		return [];
	};

	public readonly updateUserToAutoAssignedGroups = async (id: number): Promise<Result> => {
		var variables = {
			id: id,
			groups: {
				disconnectAll: false,
				connect: (await this.getAllAutoAssignGroups()).map((group) => {
					return { id: Number(group.id) };
				}),
			},
		};
		return this.updateUserGroups(variables);
	};

	public readonly updateUserGroups = async (
		userGroupData: UserGroupUpdate | UserGroupDelete
	): Promise<Result> => {
		let success: boolean = false;
		try {
			const result = await ApolloManager.client.mutate<UserGroupUpdate | UserGroupDelete>({
				mutation: updateUserGroups,
				variables: userGroupData,
				fetchPolicy: 'no-cache',
			});
			success = result ? true : false;
		} catch (error) {
			console.error(`User group update failed.`);
		}
		return {
			success: success,
			error: !success ? `Failed to Update User with Group.` : undefined,
		};
	};
}

export const GroupManager = new GroupImplementation();
