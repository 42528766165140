import React from 'react';
import styles from './styles.module.css';

interface Props {
	text: string;
	value: string;
	selected: string;
	onChange(value: string): void;
}
const RadioButton = (props: Props) => {
	return (
		<div className={styles.container} onClick={() => props.onChange(props.value)}>
			<div className={styles.radioOuterCircle}>
				<div
					className={props.value != props.selected ? styles.null : styles.radioInnerCircle}
				></div>
			</div>
			<div className={styles.caption}>{props.text}</div>
		</div>
	);
};
export { RadioButton };
