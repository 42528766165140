import React from 'react';

import { TopNav } from './components/TopNav';
import { LeftNav } from './components/LeftNav';
import { RightNav } from './components/RightNav';
import styles from './styles.module.css';
import LogRocket from 'logrocket';
import { KeystoneUser, UserManager } from 'src/helpers/UserManager';
import { VerificationReminder } from '../VerificationReminder/component';

import { Message } from 'src/components/Message';
import { Registration, RegistrationManager } from 'src/helpers/RegistrationManager';

interface Props {
	children: React.ReactNode;
	suppressVerificationReminder?: boolean;
}

interface State {
	leftNav: { expanded: boolean };
	rightNav: { expanded: boolean };
	user?: KeystoneUser;
	authenticatedUserIsNotVerified: boolean;
	userRegistration?: Registration;
}

export class Layout extends React.Component<Props, State> {
	state: State = {
		leftNav: { expanded: true },
		rightNav: { expanded: true },
		authenticatedUserIsNotVerified: false,
	};

	async componentDidMount() {
		LogRocket.init('m3tpec/myx');
		await this.updateUser();
	}

	async updateUser() {
		const user = (await UserManager.getAuthenticatedUser()) || { firstName: 'GUEST' };
		const userRegistration = await RegistrationManager.getMostRecentRegistrationForUser(user?.id);
		this.setState({
			user,
			authenticatedUserIsNotVerified:
				user?.id && !user.guest ? !(await UserManager.userIsVerified(user.id)) : false,
			userRegistration: userRegistration,
		});
	}

	render() {
		const { authenticatedUserIsNotVerified, user, userRegistration } = this.state;
		return (
			<>
				{
					// Non-visual Message component here provides access to showMessage functionality in all components that use Layout
					<Message />
				}
				<div className={styles.container}>
					<LeftNav
						user={user}
						onUserSignOut={() => {
							this.updateUser();
						}}
					/>
					<div className={styles.content}>
						<TopNav
							topNavPages={[
								{ title: 'About', url: 'https://xceptional.io/about-us/' },
								{ title: 'For Businesses', url: 'https://xceptional.io/businesses-page/' },
								{ title: 'Media', url: 'https://xceptional.io/media/' },
								{ title: 'FAQs', url: 'https://xceptional.io/faq/' },
								{ title: 'Blog', url: 'https://xceptional.io/blog/' },
								{ title: 'Contact', url: 'https://xceptional.io/#contact-us' },
							]}
						/>
						<div className={styles.pagecontainer}>
							{authenticatedUserIsNotVerified &&
								!this.props.suppressVerificationReminder &&
								!!!userRegistration && <VerificationReminder />}
							{this.props.children}
						</div>
						<div className={styles.marginBottom}></div>
					</div>
					<RightNav controller={this} />
				</div>
			</>
		);
	}
}
