import React from 'react';
import styles from './styles.module.css';
import classNames from 'classnames';
import PubSub from 'pubsub-js';
import { RightArrowSVG } from './components/RightArrowSVG';
import { SignUpPopupNotification } from './components/SignUpPopupNotification';
import { XCeptionalLogoSVG } from 'src/components/SVGs/XCeptionalLogoSVG';
import { NotificationOperations } from 'src/model/Notifications';
import { isEmailValid, isPwdValid, SetupUserAccount } from 'src/helpers/SignUpHelper';
import { AreasOfCodeNotification, CodeArea } from './components/AreasOfCodeNotification';
import { TechRolesPopupNotification } from './components/TechRolesPopupNotification';
import { ProficiencyTypeManager } from 'src/helpers/ProficiencyTypeManager/ProficiencyTypeManager';
import { UserManager } from 'src/helpers/UserManager';
import { navigate } from 'gatsby';
import { identifyUserCountry } from 'src/components/SignUpPage/component';

interface Props {
	controller: any;
}

interface State {
	expanded: boolean;
	notifications: Notification[];

	// Database fields for profile
	id: number;
	userId: { id: number };
	guest?: boolean | undefined;
	proficiencyTypeInterests?: string[];
	country?: string;

	codeAreas: CodeArea[];
}

interface Notification {
	text: string;
	id: number;
	type: NotificationOperations;
	name?: string;
	role?: string;
	description?: string;
	picture?: string;
}

export class RightNav extends React.Component<Props, State> {
	state: State = {
		expanded: true,
		notifications: [],
		id: 0,
		userId: { id: 0 },
		codeAreas: [],
	};

	closeNotification = (id: number) => {
		this.setState({
			notifications: this.state.notifications.filter((notification) => notification.id !== id),
		});
	};

	createAccountToken: any = undefined;
	areasOfCodeToken: any = undefined;
	techRolesToken: any = undefined;
	onNotificationAdd = (msg: any, data: any) => {
		const notifications = [...this.state.notifications];
		notifications.push({
			id: notifications.length + 1,
			text: data.text,
			type: data.type,
			name: data.name,
			role: data.role,
			description: data.description,
			picture: data.picture,
		});
		this.setState({ notifications: notifications });
	};

	async componentDidMount() {
		this.createAccountToken = PubSub.subscribe(
			NotificationOperations[NotificationOperations.ADD_PUZZLECOMPLETE_CREATEACCOUNT],
			this.onNotificationAdd
		);

		this.areasOfCodeToken = PubSub.subscribe(
			NotificationOperations[NotificationOperations.ADD_PUZZLESELECTION_AREASOFCODE],
			this.onNotificationAdd
		);
		this.techRolesToken = PubSub.subscribe(
			NotificationOperations[NotificationOperations.ADD_PUZZLECOMPLETE_TECHROLES],
			this.onNotificationAdd
		);
		const user = await UserManager.getAuthenticatedUser();
		if (!user) return;
		const proficiencyTypeInterests = await this.loadProficiencyTypeInterests(user);
		const country = await identifyUserCountry(user?.id);
		this.setState({
			userId: { id: user?.id ?? 0 },
			guest: user?.guest,
			proficiencyTypeInterests,
			country,
		});
		const proficiencies = await ProficiencyTypeManager.getAllProficiencyTypes();
		this.setState({
			codeAreas: proficiencies
				? proficiencies.allProficiencyTypes
						.filter((proficiency) => proficiency.showInSidebar)
						.map((proficiency) => ({ caption: proficiency.name, id: proficiency.id }))
				: [],
		});
	}

	async componentWillUnmount() {
		PubSub.unsubscribe(this.createAccountToken);
		PubSub.unsubscribe(this.areasOfCodeToken);
		PubSub.unsubscribe(this.techRolesToken);
	}

	async loadProficiencyTypeInterests(user: any) {
		try {
			return JSON.parse(user.profileAsJson || '{}')?.proficiencyTypeInterests || undefined;
		} catch {
			return undefined;
		}
	}

	async saveProficiencyTypeInterests(data: any) {
		const user = await UserManager.getAuthenticatedUser();
		if (!user) return;
		let profileData: any;
		try {
			profileData = JSON.parse(user.profileAsJson || '{}');
		} catch {
			return;
		}
		profileData.proficiencyTypeInterests = !!data.proficiencyTypeOther
			? data.proficiencyTypes.concat([data.proficiencyTypeOther])
			: data.proficiencyTypes;
		await UserManager.updateUser({ id: user.id, profileAsJson: JSON.stringify(profileData) });
	}

	render() {
		const { expanded, notifications, codeAreas } = this.state;
		// Suppress render if areas of code and no data passed through
		return NotificationOperations.ADD_PUZZLESELECTION_AREASOFCODE &&
			codeAreas.length == 0 ? null : (
			<div
				className={classNames(styles.rightNav, {
					[styles.closed]: !expanded,
				})}
			>
				<div className={styles.svgContainer}>
					<button
						className={expanded ? styles.svgExpanded : styles.svgClosed}
						onClick={() => {
							this.setState((prevState) => ({
								...prevState,
								expanded: !prevState.expanded,
							}));
						}}
						aria-label={expanded ? 'Close Right Nav' : 'Open Right Nav'}
					>
						<RightArrowSVG />
					</button>
				</div>
				<div className={styles.bottomContainer}>
					{expanded && (
						<>
							{notifications.map((notification: Notification, i: number) => {
								switch (notification.type) {
									case NotificationOperations.ADD_PUZZLECOMPLETE_CREATEACCOUNT:
										return (
											<SignUpPopupNotification
												controller={this.props.controller}
												key={notification.id}
												comment={notification.text}
												isBottomBox={i === notifications.length - 1}
												onAccountCreated={() => {
													this.closeNotification(notification.id);
													navigate('/sign-up');
												}}
												afterAccountCreated={UserManager.sendUserMagicAuthLinkForAuthenticatedUser}
												onCreateAccountClick={SetupUserAccount}
												onEmailValidate={isEmailValid}
												onPwdValidate={isPwdValid}
												hasValidResponses={(firstname, lastname, email, password) =>
													firstname !== '' &&
													lastname !== '' &&
													isEmailValid(email) &&
													isPwdValid(password)
														? true
														: false
												}
												country={this.state.country}
											/>
										);
									case NotificationOperations.ADD_PUZZLECOMPLETE_TECHROLES:
										return (
											<TechRolesPopupNotification
												key={notification.id}
												comment={notification.text}
												isBottomBox={i === notifications.length - 1}
												name={notification.name || ''}
												role={notification.role || ''}
												description={notification.description || ''}
												picture={notification.picture}
											/>
										);
									case NotificationOperations.ADD_PUZZLESELECTION_AREASOFCODE:
										return this.state.guest ||
											this.state.userId.id == -1 ||
											(!!this.state.proficiencyTypeInterests &&
												this.state.proficiencyTypeInterests !== null &&
												!!this.state.proficiencyTypeInterests[0]) ? null : (
											<AreasOfCodeNotification
												key={notification.id}
												comment="What areas of code are you interested in?"
												codeAreas={codeAreas}
												isBottomBox={i === notifications.length - 1}
												onSubmitClick={(codeAreas, codeAreaOther) => {
													let proficiencyTypes: string[] = codeAreas
														.filter((area) => area.value == true)
														.map((area) => area.caption);
													const proficiencyTypeOther: string | undefined = codeAreaOther;
													const dict = {
														proficiencyTypes: proficiencyTypes,
														proficiencyTypeOther: proficiencyTypeOther,
													};
													this.saveProficiencyTypeInterests(dict);
													this.closeNotification(notification.id);
												}}
											/>
										);
								}
							})}

							<div className={styles.logoContainer}>
								{/*  
									The notification dot should probably only appear when there are unflipped notifications, 
									if you flip all the notifications over, the dot should probably disappear. 
									Not really sure on the design behind the flipping, so not going that far right now.
								*/}
								{this.state.notifications.length > 0 ? (
									<div className={styles.notificationDot} aria-label={'New Notification'} />
								) : undefined}
								<XCeptionalLogoSVG />
							</div>
						</>
					)}
				</div>
			</div>
		);
	}
}
