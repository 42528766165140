import React from 'react';

export const PuzzlePiece = () => (
	<svg
		width="24px"
		height="20px"
		viewBox="0 0 24 20"
		version="1.1"
		xmlnsXlink="http://www.w3.org/1999/xlink"
		xmlns="http://www.w3.org/2000/svg"
	>
		<desc>Puzzle piece</desc>
		<path
			d="M19 6C18.65 6 18.3 6.05 18 6.2L18 0L14 0C14 1.65 12.65 3 11 3C9.35 3 8 1.65 8 0L4 0L4 6.2C3.7 6.05 3.35 6 3 6C1.35 6 0 7.35 0 9C0 10.65 1.35 12 3 12C3.35 12 3.7 11.95 4 11.8L4 18L8 18C8 16.35 9.35 15 11 15C12.65 15 14 16.35 14 18L18 18L18 11.8C18.3 11.9 18.65 12 19 12C20.65 12 22 10.65 22 9C22 7.35 20.65 6 19 6Z"
			transform="translate(1 1)"
			id="Path"
			fill="#54CADC"
			fillOpacity="0.2"
			stroke="#54CADC"
			strokeWidth="2"
			strokeLinecap="square"
		/>
	</svg>
);

