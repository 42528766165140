import React, { Component } from 'react';
import classNames from 'classnames';
import { CheckedSVG } from 'src/components/Checkbox/components/CheckedSVG';
import { UncheckedSVG } from 'src/components/Checkbox/components/UncheckedSVG';
import styles from './styles.module.css';

interface Props {
	checked: boolean;
	caption: string | React.ReactFragment;
	onChecked(value: boolean): void;
}

const Checkbox = (props: Props) => {
	return (
		<div className={styles.control}>
			<div onClick={(e) => props.onChecked(!props.checked)} className={styles.box}>
				<div
					className={styles.checkbox}
					role="checkbox"
					aria-checked={props.checked}
					tab-index="0"
					aria-labelledby="chk1-label"
				>
					{props.checked ? <CheckedSVG /> : <UncheckedSVG />}
				</div>
				<div
					id="chk1-label"
					className={classNames(styles.title, {
						[styles.titleInactive]: !props.checked,
						[styles.titleActive]: props.checked,
					})}
				>
					{props.caption}
				</div>
			</div>
		</div>
	);
};

export { Checkbox };
