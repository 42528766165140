import React, { useState } from 'react';
import styles from './styles.module.css';
import { LightBulbSVG } from './components/LightBulbSVG';
import { UserOutlined } from '@ant-design/icons';
import closeX from 'src/images/CloseX.png';
import { navigate } from 'gatsby';

interface Props {
	comment: string;
	isBottomBox: boolean;
	name: string;
	role: string;
	description: string;
	picture?: string;
	onClick?: () => void;
}

export const TechRolesPopupNotification = (props: Props) => {
	const [visible, setVisible] = useState(props.isBottomBox);
	return (
		<>
			{visible ? (
				<div className={props.isBottomBox ? styles.bottomNotificationBox : styles.notificationBox}>
					<button onClick={() => setVisible(false)} className={styles.closeButton}>
						{/*	close icon is not an svg? Can't export from Lunacy/invision.*/}
						<img src={closeX} className={styles.close} alt="Close" />
					</button>
					<div>
						<LightBulbSVG mainColor={'#DF4F5D'} bgColor={'#DF4F5D'} />
					</div>
					<div className={styles.questionText}>{props.comment}</div>
					<div className={styles.picName}>
						<div className={styles.profilePic}>
							<div className={styles.line} />
							{props.picture ? (
								<img className={styles.profileImg} src={props.picture} />
							) : (
								<div className={styles.profileImg}>
									<UserOutlined style={{ fontSize: '32px', color: '#54cadc' }} />
								</div>
							)}
						</div>
					</div>
					<p className={styles.profileName}>{props.name}</p>
					<p className={styles.employment}>{props.role.toUpperCase()}</p>
					{props.description && <div className={styles.jobDescription}>{props.description}</div>}
					<div className={styles.capsule} onClick={() => setVisible(false)}>
						<p className={styles.label}>Dismiss</p>
					</div>
				</div>
			) : (
				<div
					className={styles.flippedBox}
					aria-label={'Flipped Notification'}
					tabIndex={0}
					onFocus={() => setVisible(true)}
					onClick={() => setVisible(true)}
				></div>
			)}
		</>
	);
};
