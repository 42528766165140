import { ApolloManager } from 'src/helpers/ApolloManager';
import getAllPuzzlesForUser from './getAllPuzzlesForUser.graphql';
import getAllPuzzles from './getAllPuzzles.graphql';
import getAllQuestionsForPuzzle from './getAllQuestionsForPuzzle.graphql';
import getAllPuzzleTypesByKey from './getAllPuzzleTypesByKey.graphql';
import createPuzzle from './createPuzzle.graphql';
import updatePuzzle from './updatePuzzle.graphql';
import createQuestion from './createQuestion.graphql';
import updateQuestion from './updateQuestion.graphql';
import deleteQuestion from './deleteQuestion.graphql';
import { Puzzle } from 'src/model/PuzzleData';

interface PuzzlesFetchResult {
	allPuzzles: Puzzle[];
}

export interface Question {
	id?: number;
	sequenceNumber: number;
	specification: string;
	correctAnswer?: string;
	threshold?: number;
}

interface QuestionsFetchResult {
	puzzle: {
		questions: Question[];
	};
}

interface PuzzleTypeFetchResult {
	allPuzzleTypes?: {
		id: string;
		key: string;
	}[];
}

interface createPuzzleData {
	title?: string;
	titleImage?: string;
	expectedDurationMinutes?: number;
	descriptionLine1?: string;
	descriptionLine2?: string;
	puzzleType: string;
	archived?: boolean;
	threshold?: string;
	highScorePopupThreshold?: string;
}

interface createPuzzleFetchResult {
	createPuzzle: Puzzle;
}

interface modifyPuzzleResult {
	puzzle?: Puzzle;
}

interface modifyPuzzleResult2 {
	puzzle: Puzzle;
}

interface updatePuzzleData {
	id: string;
	title?: string;
	titleImage?: string;
	expectedDurationMinutes?: number;
	descriptionLine1?: string;
	descriptionLine2?: string;
	archived?: boolean;
	threshold?: string;
	highScorePopupThreshold?: string;
}

interface updatePuzzleFetchResult {
	updatePuzzle: Puzzle;
}

interface createQuestionData {
	sequenceNumber?: number;
	specification?: string;
	correctAnswer?: string;
	threshold?: number;
	puzzle: string;
}

interface modifyQuestionResult {
	question?: Question;
}

interface updateQuestionData {
	id: string;
	sequenceNumber?: number;
	specification?: string;
	correctAnswer?: string;
	threshold?: number;
}

interface DeletePuzzleFetchResult {
	deletePuzzle: { id: String };
}

class PuzzleImplementation {
	public readonly getAllPuzzles = async (): Promise<Puzzle[]> => {
		try {
			const result = await ApolloManager.client.query<PuzzlesFetchResult>({
				query: getAllPuzzles,
				variables: {},
				fetchPolicy: 'no-cache',
			});
			if (result.data.allPuzzles) {
				return result.data.allPuzzles;
			} else {
				return [];
			}
		} catch (error) {
			console.error(`An error occurred fetching puzzles: Error was ${error}`);
		}
		return [];
	};

	public readonly getAllPuzzlesForUser = async (userId: number): Promise<Puzzle[]> => {
		try {
			const result = await ApolloManager.client.query<PuzzlesFetchResult>({
				query: getAllPuzzlesForUser,
				variables: { user: userId },
				fetchPolicy: 'no-cache',
			});
			if (result.data.allPuzzles) {
				return result.data.allPuzzles;
			} else {
				return [];
			}
		} catch (error) {
			console.error(`An error occurred fetching puzzles: Error was ${error}`);
		}
		return [];
	};

	public readonly getAppropriatePuzzlesForUser = async (userId?: number): Promise<Puzzle[]> => {
		if (!!!userId) return [];
		const puzzles = await this.getAllPuzzlesForUser(userId);
		let appropriatePuzzles: Puzzle[] = [];
		puzzles.forEach((puzzle) => {
			if (!puzzle.archived) {
				if (!puzzle.groups || Number(puzzle.groups?.length) == 0) {
					// Puzzle has no groups assigned, so it is free to be seen by all users
					appropriatePuzzles.push(puzzle);
				} else {
					// Puzzle has groups assigned, so the user must be in at least one linked group
					let userInGroup = false;
					puzzle.groups?.forEach((group) => {
						group.users?.forEach((user) => {
							if (user.id === userId) {
								userInGroup = true;
							}
						});
					});
					if (userInGroup) {
						appropriatePuzzles.push(puzzle);
					}
				}
			}
		});
		return appropriatePuzzles;
	};

	public readonly getAllQuestionsForPuzzle = async (puzzleId: number): Promise<Question[]> => {
		try {
			const result = await ApolloManager.client.query<QuestionsFetchResult>({
				query: getAllQuestionsForPuzzle,
				variables: { id: puzzleId },
				fetchPolicy: 'no-cache',
			});
			if (result.data.puzzle.questions) {
				return result.data.puzzle.questions;
			} else {
				return [];
			}
		} catch (error) {
			console.error(`An error occurred fetching questions: Error was ${error}`);
		}
		return [];
	};

	public readonly getAllPuzzleTypesByKey = async (key: string): Promise<PuzzleTypeFetchResult> => {
		try {
			const result = await ApolloManager.client.query<PuzzleTypeFetchResult>({
				query: getAllPuzzleTypesByKey,
				variables: { key },
				fetchPolicy: 'no-cache',
			});
			if (result.data) {
				return result.data;
			} else {
				return {};
			}
		} catch (error) {
			console.error(`An error occurred fetching questions: Error was ${error}`);
		}
		return {};
	};

	public readonly CreatePuzzle = async (
		variables: createPuzzleData
	): Promise<modifyPuzzleResult> => {
		try {
			const result = await ApolloManager.client.mutate<createPuzzleFetchResult>({
				mutation: createPuzzle,
				variables,
				fetchPolicy: 'no-cache',
			});
			if (result.data) {
				return { puzzle: result.data.createPuzzle };
			} else {
				return {};
			}
		} catch (error) {
			console.error(`An error occurred creating puzzle: Error was ${error}`);
		}
		return {};
	};

	public readonly CreatePuzzle2 = async (
		variables: createPuzzleData
	): Promise<modifyPuzzleResult2> => {
		let puzzle = undefined;
		try {
			const result = await ApolloManager.client.mutate<createPuzzleFetchResult>({
				mutation: createPuzzle,
				variables,
				fetchPolicy: 'no-cache',
			});
			if (!result || result.data == undefined) throw new Error('Failed to create Puzzle.');
			puzzle = result.data.createPuzzle;
		} catch (error) {
			throw error;
		}
		return { puzzle: puzzle };
	};

	public readonly UpdatePuzzle = async (
		variables: updatePuzzleData
	): Promise<modifyPuzzleResult> => {
		try {
			const result = await ApolloManager.client.mutate<updatePuzzleFetchResult>({
				mutation: updatePuzzle,
				variables,
				fetchPolicy: 'no-cache',
			});
			if (result.data) {
				return { puzzle: result.data.updatePuzzle };
			} else {
				return {};
			}
		} catch (error) {
			console.error(`An error occurred updating puzzle: Error was ${error}`);
		}
		return {};
	};

	public readonly CreateQuestion = async (
		variables: createQuestionData
	): Promise<modifyQuestionResult> => {
		try {
			const result = await ApolloManager.client.mutate<Question>({
				mutation: createQuestion,
				variables,
				fetchPolicy: 'no-cache',
			});
			if (result.data) {
				return { question: result.data };
			} else {
				return {};
			}
		} catch (error) {
			console.error(`An error occurred creating question: Error was ${error}`);
		}
		return {};
	};

	public readonly UpdateQuestion = async (
		variables: updateQuestionData
	): Promise<modifyQuestionResult> => {
		try {
			const result = await ApolloManager.client.mutate<Question>({
				mutation: updateQuestion,
				variables,
				fetchPolicy: 'no-cache',
			});
			if (result.data) {
				return { question: result.data };
			} else {
				return {};
			}
		} catch (error) {
			console.error(`An error occurred updating question: Error was ${error}`);
		}
		return {};
	};

	public readonly DeleteQuestion = async (id: number): Promise<Boolean> => {
		try {
			const result = await ApolloManager.client.mutate<DeletePuzzleFetchResult>({
				mutation: deleteQuestion,
				variables: { id },
				fetchPolicy: 'no-cache',
			});
			if (result.data) {
				return true;
			} else {
				return false;
			}
		} catch (error) {
			console.error(`An error occurred deleting question: Error was ${error}`);
		}
		return false;
	};
}

export const PuzzleManager = new PuzzleImplementation();
