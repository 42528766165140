import React from 'react';
import styles from './styles.module.css';
import classNames from 'classnames';
import { Link, navigate } from 'gatsby';
import { LeftArrowSVG } from 'src/components/ArrowButton/components/LeftArrowSVG';
import { RightArrowSVG } from 'src/components/ArrowButton/components/RightArrowSVG';

interface Props {
	to?: string;
	caption: string;
	isBackButton?: boolean;
	isDisabled?: boolean;
	state?: any;
	onClick?: (arg0: any) => any;
	onKeyDown?: (e: KeyboardEvent) => any;
	gatsbyLinkState?: any;
	additionalStyles?: { [key: string]: string };
	directLink?: boolean;
}

const backButton = (
	caption: string,
	isBackButton?: boolean,
	additionalStyles?: { [key: string]: string }
) => {
	const classesObj: { [key: string]: boolean } = {};
	if (additionalStyles && additionalStyles.arrowButtonCaption) {
		classesObj[additionalStyles.arrowButtonCaption as any] = true;
	}

	const classes = classNames(
		styles.caption,
		{
			[styles.caption_backwards]: isBackButton,
			[styles.caption_forwards]: !isBackButton,
		},
		classesObj
	);

	return (
		<>
			{isBackButton && <LeftArrowSVG />}
			<div className={classes}>{caption}</div>
			{!isBackButton && <RightArrowSVG />}
		</>
	);
};

/* Styles that can be overriden by additionalStyles:

   internal class => external class
   --------------------------------
   link              => arrowButton
   caption_forwards  => arrowButtonCaption
   caption_backwards => arrowButtonCaption

*/
const ArrowButton = (props: Props) => {
	const classesObj: { [key: string]: boolean } = {};
	classesObj[styles.link] = true;
	if (props.additionalStyles && props.additionalStyles.arrowButton) {
		classesObj[props.additionalStyles.arrowButton as any] = true;
	}
	let classes: string = classNames(styles.link, classesObj, {
		[styles.link_backwards]: props.isBackButton,
		[styles.link_forwards]: !props.isBackButton,
		[styles.disabledButton]: props.isDisabled,
	});
	return (
		<>
			{props.to && props.gatsbyLinkState ? (
				<Link
					className={classNames(styles.link, {
						[styles.link_backwards]: props.isBackButton,
						[styles.link_forwards]: !props.isBackButton,
					})}
					to={props.to}
					state={props.gatsbyLinkState}
					onClick={props.onClick}
				>
					{backButton(props.caption, props.isBackButton)}
				</Link>
			) : props.to && props.directLink ? (
				<button
					className={classes}
					disabled={props.isDisabled}
					onClick={(arg0: any) => {
						props.onClick && props.onClick(arg0);
						if (typeof window !== 'undefined') props.to && window.location.assign(props.to);
					}}
					onKeyDown={props.onClick}
				>
					{backButton(props.caption, props.isBackButton, props.additionalStyles)}
				</button>
			) : props.to ? (
				<Link
					className={classNames(styles.link, {
						[styles.link_backwards]: props.isBackButton,
						[styles.link_forwards]: !props.isBackButton,
					})}
					to={props.to}
					onClick={props.onClick}
				>
					{backButton(props.caption, props.isBackButton)}
				</Link>
			) : (
				<button
					className={classes}
					disabled={props.isDisabled}
					onClick={props.onClick}
					onKeyDown={props.onClick}
				>
					{backButton(props.caption, props.isBackButton, props.additionalStyles)}
				</button>
			)}
		</>
	);
};

export { ArrowButton };
