import { getProfileDataElements, getProfileDataElementAsText } from './common';

class ProfileDownloadImplementation {
	public BuildProfileDownloadDict: any = [
		{
			id: '1',
			caption: '',
			type: 'tabs',
			defaultActiveKey: 1,
			elements: [1, 2, 3, 4, 5, 6, 7].map((index) => {
				return {
					id: index,
					caption: `Page ${index}`,
					type: 'tabpane',
					key: index,
					elements: this.constructProfilePageByIndex(index),
				};
			}),
		},
	];

	private constructProfilePageByIndex(index: number) {
		switch (index) {
			case 1:
				return this.constructProfilePage1();
			case 2:
				return this.constructProfilePage2();
			case 3:
				return this.constructProfilePage3();
			case 4:
				return this.constructProfilePage4();
			case 5:
				return this.constructProfilePage5();
			case 6:
				return this.constructProfilePage6();
			case 7:
				return this.constructProfilePage7();
			default:
				return null;
		}
	}

	private constructProfilePage1() {
		const result = [
			{
				type: 'container',
				styles: 'column',
				elements: [
					{
						type: 'container',
						styles: 'row',
						elements: [
							{
								type: 'container',
								styles: 'infoHeading',
								elements: [
									{
										id: '1',
										caption: 'Candidate: ',
										type: 'text',
									},
								],
							},
							{
								type: 'container',
								styles: 'paragraph',
								elements: [
									...this.convertComponentsToText([...getProfileDataElements(['First Name'])]),
									{
										id: '2',
										caption: ' ',
										type: 'text',
									},
									...this.convertComponentsToText([...getProfileDataElements(['Last Name'])]),
								],
							},
						],
					},
					{
						type: 'container',
						styles: 'row',
						elements: [
							{
								type: 'container',
								styles: 'infoHeading',
								elements: [
									{
										id: '3',
										caption: 'Location: ',
										type: 'text',
									},
								],
							},
							{
								type: 'container',
								styles: 'paragraph',
								elements: [
									...this.convertComponentsToText([...getProfileDataElements(['Suburb'])]),
									{
										id: '4',
										caption: ', ',
										type: 'text',
									},
									...this.convertComponentsToText([...getProfileDataElements(['State'])]),
								],
							},
						],
					},
					{
						type: 'container',
						styles: 'infoHeading',
						elements: [
							{
								id: '3',
								caption: 'Candidate Profile',
								type: 'text',
							},
						],
					},
					{
						type: 'container',
						styles: 'infoHeading',
						elements: [
							{
								id: '3',
								caption: new Date(Date.now()).toLocaleString(undefined, {
									year: 'numeric',
									month: 'long',
									day: '2-digit',
								}),
								type: 'text',
							},
						],
					},
				],
			},
		];
		return result;
	}

	private constructProfilePage2() {
		const result = [
			{
				type: 'container',
				styles: 'column',
				elements: [
					{
						type: 'container',
						styles: 'row',
						elements: [
							{
								type: 'container',
								styles: 'infoHeading',
								elements: [
									{
										id: '1',
										caption: 'Neurodiversity in your workplace',
										type: 'text',
									},
								],
							},
						],
					},
					{
						type: 'container',
						styles: 'row',
						elements: [
							{
								type: 'container',
								styles: 'paragraph',
								elements: [
									{
										id: '1',
										caption: `Autistic employees can possess well documented natural talents, such as high levels of concentration, acute attention to detail, persistence, accuracy and an excellent memory. This untapped talent pool is often overlooked as the application, interview and assessment process is not optimised to identify essential skills for a role, instead relying heavily on self promotion, eye contact and confidence. Autistic employees often fill gaps in workplaces where systemic thinking, visual perception, honesty and dedication can be valuable traits. Feedback from hiring managers and colleagues has shown us that building a neurodiverse workplace has increased productivity, improved customer service and internal communication and has created a more inclusive and enjoyable workplace.`,
										type: 'text',
									},
								],
							},
						],
					},
					{
						type: 'container',
						styles: 'row',
						elements: [
							{
								type: 'container',
								styles: 'infoHeading',
								elements: [
									{
										id: '1',
										caption: 'Recruiting for success',
										type: 'text',
									},
								],
							},
						],
					},
					{
						type: 'container',
						styles: 'row',
						elements: [
							{
								type: 'container',
								styles: 'paragraph',
								elements: [
									{
										id: '1',
										caption: `Xceptional’s unique assessment and recruitment process breaks down the skills matching methods used in traditional recruitment practices to truly identify each candidate’s natural abilities and potential contribution to the workplace. By identifying natural abilities, hiring managers and talent specialists can better map a candidates suitability to a role without relying on an applicant’s ability to articulate and self promote under what is often a highly anxiety provoking interview process.`,
										type: 'text',
									},
								],
							},
						],
					},
					{
						type: 'container',
						styles: 'row',
						elements: [
							{
								type: 'container',
								styles: 'paragraph',
								elements: [
									{
										id: '1',
										caption: `Xceptional’s skills assessment tools identify skills employers might otherwise miss, such as Problem Solving, Logical Reasoning, Attention to Detail, Numerical Reasoning, and Pattern Recognition. Results are provided against a benchmark of average results to provide an indication of where a candidate’s skills rank in the current market place.`,
										type: 'text',
									},
								],
							},
						],
					},
					{
						type: 'container',
						styles: 'row',
						elements: [
							{
								type: 'container',
								styles: 'infoHeading',
								elements: [
									{
										id: '1',
										caption: 'Five Pillars',
										type: 'text',
									},
								],
							},
						],
					},
					{
						type: 'container',
						styles: 'row',
						elements: [
							{
								type: 'container',
								styles: 'paragraph',
								elements: [
									{
										id: '1',
										caption: `~~Five Pillars graphic here~~`,
										type: 'text',
									},
								],
							},
						],
					},
				],
			},
		];
		return result;
	}

	private constructProfilePage3() {
		const result = [
			{
				type: 'container',
				styles: 'column',
				elements: [
					{
						type: 'container',
						styles: 'row',
						elements: [
							{
								type: 'container',
								styles: 'infoHeading',
								elements: [
									{
										id: '1',
										caption: 'Interpreting the Skills Profile',
										type: 'text',
									},
								],
							},
						],
					},
					{
						type: 'container',
						styles: 'row',
						elements: [
							{
								type: 'container',
								styles: 'paragraph',
								elements: [
									{
										id: '1',
										caption: `Each of the Five Pillars have been identified as critical skills in the technology and business sector contributing to the overall success of an employee's ability to contribute to, and enhance, business performance. Interpreting the skills Profile should be considered in conjunction with employees goals, level of experience and career objectives. Rating highly in a particular area does not indicate a candidate is an expert just as rating low does not indicate a candidate is not able to contribute. When considering a skills match, positions require varying levels of skills. For example a Junior Data Analyst that is required to extract and report back on trends may require higher levels of Numerical Reasoning and Pattern Recognition than the other skills mapped on the FIve Pillars. It is just as likely that as a Junior, the skill level attained on Numerical Reasoning and Pattern Recognition may be lower than that required of a Senior Data Analyst or Data Scientist.`,
										type: 'text',
									},
								],
							},
						],
					},
					{
						type: 'container',
						styles: 'row',
						elements: [
							{
								type: 'container',
								styles: 'infoHeading',
								elements: [
									{
										id: '1',
										caption: 'Skills Profile',
										type: 'text',
									},
								],
							},
						],
					},
					{
						type: 'container',
						styles: 'row',
						elements: [
							{
								type: 'container',
								styles: 'paragraph',
								elements: [
									{
										id: '1',
										caption: `~~Skills Profile Chart goes here~~`,
										type: 'text',
									},
								],
							},
						],
					},
					{
						type: 'container',
						styles: 'row',
						elements: [
							{
								type: 'container',
								styles: 'paragraph',
								elements: this.convertComponentsToText([
									...getProfileDataElements(['First Name']),
								]).concat([
									{
										id: '1',
										caption: `'s natural skills are strongest in ${`Skill 1`}, ${`Skill 2`} and ${`Skill 3`}'`,
										type: 'text',
									},
								]),
							},
						],
					},
					{
						type: 'container',
						styles: 'row',
						elements: [
							{
								type: 'container',
								styles: 'infoHeading',
								elements: [
									{
										id: '1',
										caption: `${`Skill 1`}`,
										type: 'text',
									},
								],
							},
							{
								type: 'container',
								styles: 'paragraph',
								elements: [
									{
										id: '2',
										caption: `${` - Skill 1 description`}`,
										type: 'text',
									},
								],
							},
						],
					},
					{
						type: 'container',
						styles: 'row',
						elements: [
							{
								type: 'container',
								styles: 'infoHeading',
								elements: [
									{
										id: '1',
										caption: `${`Skill 2`}`,
										type: 'text',
									},
								],
							},
							{
								type: 'container',
								styles: 'paragraph',
								elements: [
									{
										id: '2',
										caption: `${` - Skill 2 description`}`,
										type: 'text',
									},
								],
							},
						],
					},
					{
						type: 'container',
						styles: 'row',
						elements: [
							{
								type: 'container',
								styles: 'infoHeading',
								elements: [
									{
										id: '1',
										caption: `${`Skill 3`}`,
										type: 'text',
									},
								],
							},
							{
								type: 'container',
								styles: 'paragraph',
								elements: [
									{
										id: '2',
										caption: `${` - Skill 3 description`}`,
										type: 'text',
									},
								],
							},
						],
					},
				],
			},
		];
		return result;
	}

	private constructProfilePage4() {
		const empty: any = [];
		const result = [
			{
				type: 'container',
				styles: 'row',
				elements: [
					{
						type: 'container',
						styles: 'infoHeading',
						elements: [
							{
								id: '1',
								caption: 'Work Preferences',
								type: 'text',
							},
						],
					},
				],
			},
			{
				type: 'container',
				styles: 'row',
				elements: [
					{
						type: 'container',
						styles: 'paragraph',
						elements: [
							{
								id: '1',
								caption: `Research shows that employees are more productive, healthier, take less sick days and produce higher quality work when working in an environment that meets their broader emotional, psychological and physical needs. Identifying a candidate’s work preferences helps to identify which workplaces an employee is more likely to succeed in and establish a long and meaningful career.`,
								type: 'text',
							},
						],
					},
				],
			},
			{
				type: 'container',
				styles: 'row',
				elements: [
					{
						type: 'container',
						styles: 'infoHeading',
						elements: [
							{
								id: '1',
								caption: `${`Industries:`}`,
								type: 'text',
							},
						],
					},
					{
						type: 'container',
						styles: 'paragraph',
						elements: [getProfileDataElementAsText('industryCategory')],
					},
				],
			},
			{
				type: 'container',
				styles: 'row',
				elements: [
					{
						type: 'container',
						styles: 'infoHeading',
						elements: [
							{
								id: '1',
								caption: `${`Location:`}`,
								type: 'text',
							},
						],
					},
					{
						type: 'container',
						styles: 'paragraph',
						elements: [
							getProfileDataElementAsText('addressSuburb'),
							{
								id: '1',
								caption: `${`, `}`,
								type: 'text',
							},
							getProfileDataElementAsText('addressState'),
						],
					},
				],
			},
			{
				type: 'container',
				styles: 'row',
				elements: [
					{
						type: 'container',
						styles: 'infoHeading',
						elements: [
							{
								id: '1',
								caption: `${`Working Rights:`}`,
								type: 'text',
							},
						],
					},
					{
						type: 'container',
						styles: 'paragraph',
						elements: [getProfileDataElementAsText('workRights')],
					},
				],
			},
			{
				type: 'container',
				styles: 'row',
				elements: [
					{
						type: 'container',
						styles: 'infoHeading',
						elements: [
							{
								id: '1',
								caption: `${`Desired Workload:`}`,
								type: 'text',
							},
						],
					},
					{
						type: 'container',
						styles: 'paragraph',
						elements: [
							{
								id: '1',
								caption: `${`~~TO BE ADDED~~`}`,
								type: 'text',
							},
						],
					},
				],
			},
			{
				type: 'container',
				styles: 'row',
				elements: [
					{
						type: 'container',
						styles: 'infoHeading',
						elements: [
							{
								id: '1',
								caption: `${`Desired Location:`}`,
								type: 'text',
							},
						],
					},
					{
						type: 'container',
						styles: 'paragraph',
						elements: [
							{
								id: '1',
								caption: `${`~~TO BE ADDED~~`}`,
								type: 'text',
							},
						],
					},
				],
			},
			{
				type: 'container',
				styles: 'row',
				elements: [
					{
						type: 'container',
						styles: 'infoHeading',
						elements: [
							{
								id: '1',
								caption: `${`Relocation:`}`,
								type: 'text',
							},
						],
					},
					{
						type: 'container',
						styles: 'paragraph',
						elements: [
							{
								id: '1',
								caption: `${`~~TO BE ADDED~~`}`,
								type: 'text',
							},
						],
					},
				],
			},
			{
				type: 'container',
				styles: 'row',
				elements: [
					{
						type: 'container',
						styles: 'infoHeading',
						elements: [
							{
								id: '1',
								caption: `${`Salary Range:`}`,
								type: 'text',
							},
						],
					},
					{
						type: 'container',
						styles: 'paragraph',
						elements: [
							{
								id: '1',
								caption: `${`~~TO BE ADDED~~`}`,
								type: 'text',
							},
						],
					},
				],
			},
			{
				type: 'container',
				styles: 'row',
				elements: [
					{
						type: 'container',
						styles: 'infoHeading',
						elements: [
							{
								id: '1',
								caption: `${`Motivators:`}`,
								type: 'text',
							},
						],
					},
					{
						type: 'container',
						styles: 'paragraph',
						elements: [
							{
								id: '1',
								caption: `${`~~TO BE ADDED~~`}`,
								type: 'text',
							},
						],
					},
				],
			},
			{
				type: 'container',
				styles: 'row',
				elements: [
					{
						type: 'container',
						styles: 'infoHeading',
						elements: [
							{
								id: '1',
								caption: `${`Preferred Tasks:`}`,
								type: 'text',
							},
						],
					},
					{
						type: 'container',
						styles: 'paragraph',
						elements: [
							{
								id: '1',
								caption: `${`~~TO BE ADDED~~`}`,
								type: 'text',
							},
						],
					},
				],
			},
			{
				type: 'container',
				styles: 'row',
				elements: [
					{
						type: 'container',
						styles: 'infoHeading',
						elements: [
							{
								id: '1',
								caption: 'Interpreting the data to identify suitable job titles',
								type: 'text',
							},
						],
					},
				],
			},
			{
				type: 'container',
				styles: 'row',
				elements: [
					{
						type: 'container',
						styles: 'paragraph',
						elements: [
							{
								id: '1',
								caption: `Australian market conditions are constantly changing. The skills and preferences that matched a Software Developer in 1990 are far from the skills and preferences that match in today’s employment market. This change is more prevalent and rapid in the technology industry than in any other area. Job opportunities change from region to region as do the requirements based on the availability of local talent. Identifying suitable job opportunities in today’s market is something a skilled employment consultant may be able to assist with by combining the Skills Profile, Intelligences and Preferences contained in this Candidate Profile, with local knowledge of your employment market.`,
								type: 'text',
							},
						],
					},
				],
			},
			{
				type: 'container',
				styles: 'row',
				elements: [
					{
						type: 'container',
						styles: 'infoHeading',
						elements: [
							{
								id: '1',
								caption: 'Desired Workload',
								type: 'text',
							},
						],
					},
				],
			},
			{
				type: 'container',
				styles: 'row',
				elements: [
					{
						type: 'container',
						styles: 'paragraph',
						elements: [
							{
								id: '1',
								caption: `It is often assumed that all employees seek to work full time hours, and that if only part time hours are available that they will eventually seek to increase their hours. Many employees, for various reasons, aspire to work part time. Part time in itself though does not provide an employer with sufficient information to understand if a candidate is seeking 1-2 days or 3-4 days or if this is a temporary preference while working to increase their hours. Indicating a candidate’s desired workload allows an employer to consider if these hours could be achieved from the commencement of employment to create a more desirable offer and if not, provides an indication of what hours the candidate would ideally like to work toward by either reducing or increasing their hours over time.`,
								type: 'text',
							},
						],
					},
				],
			},
		];
		return result;
	}

	private constructProfilePage5() {
		const result = [
			{
				type: 'container',
				styles: 'row',
				elements: [
					{
						type: 'container',
						styles: 'infoHeading',
						elements: [
							{
								id: '1',
								caption: `Gardner's Theory of Multiple Intelligences`,
								type: 'text',
							},
						],
					},
				],
			},
			{
				type: 'container',
				styles: 'row',
				elements: [
					{
						type: 'container',
						styles: 'paragraph',
						elements: [
							{
								id: '1',
								caption: `Howard Gardner is an American developmental psychologist that changed the way people studied intelligence and transformed the fields of psychology and education. The theory of “multiple intelligence” developed by Howard Gardner provides nine different potential pathways to learning – Language, Logical/Mathematical, Visual/Spatial, Hands-on/Kin-aesthetic, Naturalist, Harmony & Rhythm, Interpersonal, Intra-personal and Metaphysical.`,
								type: 'text',
							},
						],
					},
					{
						type: 'container',
						styles: 'paragraph',
						elements: [
							{
								id: '2',
								caption: `Workplace adjustments are an initial indication of how barriers may be removed to improve performance and inclusion. Optional adjustments are also detailed as, even though they are not critical to performance, they may contribute to improving the working environment.`,
								type: 'text',
							},
						],
					},
				],
			},
			{
				type: 'container',
				styles: 'row',
				elements: [
					{
						type: 'container',
						styles: 'infoHeading',
						elements: [
							{
								id: '1',
								caption: 'Intelligence Areas',
								type: 'text',
							},
						],
					},
				],
			},
			{
				type: 'container',
				styles: 'row',
				elements: [
					{
						type: 'container',
						styles: 'paragraph',
						elements: [
							{
								id: '1',
								caption: `~~Intelligence Areas chart goes here~~`,
								type: 'text',
							},
						],
					},
				],
			},
			{
				type: 'container',
				styles: 'row',
				elements: [
					{
						type: 'container',
						styles: 'paragraph',
						elements: [
							...this.convertComponentsToText([...getProfileDataElements(['First Name'])]),
							{
								id: '1',
								caption: `${`'s potential and learning styles are strongest across ${`Intelligence 1`}, ${`Intelligence 2`} and ${`Intelligence 3`}`}`,
								type: 'text',
							},
						],
					},
				],
			},
			{
				type: 'container',
				styles: 'row',
				elements: [
					{
						type: 'container',
						styles: 'infoHeading',
						elements: [
							{
								id: '1',
								caption: `${`Intelligence 1`}`,
								type: 'text',
							},
						],
					},
					{
						type: 'container',
						styles: 'paragraph',
						elements: [
							{
								id: '1',
								caption: `${`Intelligence 1 Description`}`,
								type: 'text',
							},
						],
					},
				],
			},
			{
				type: 'container',
				styles: 'row',
				elements: [
					{
						type: 'container',
						styles: 'infoHeading',
						elements: [
							{
								id: '1',
								caption: `${`Intelligence 2`}`,
								type: 'text',
							},
						],
					},
					{
						type: 'container',
						styles: 'paragraph',
						elements: [
							{
								id: '1',
								caption: `${`Intelligence 2 Description`}`,
								type: 'text',
							},
						],
					},
				],
			},
			{
				type: 'container',
				styles: 'row',
				elements: [
					{
						type: 'container',
						styles: 'infoHeading',
						elements: [
							{
								id: '1',
								caption: `${`Intelligence 3`}`,
								type: 'text',
							},
						],
					},
					{
						type: 'container',
						styles: 'paragraph',
						elements: [
							{
								id: '1',
								caption: `${`Intelligence 3 Description`}`,
								type: 'text',
							},
						],
					},
				],
			},
		];
		return result;
	}

	private constructProfilePage6() {
		const result = [
			{
				type: 'container',
				styles: 'row',
				elements: [
					{
						type: 'container',
						styles: 'infoHeading',
						elements: [
							{
								id: '1',
								caption: `Reasonable Adjustments`,
								type: 'text',
							},
						],
					},
				],
			},
			{
				type: 'container',
				styles: 'row',
				elements: [
					{
						type: 'container',
						styles: 'paragraph',
						elements: [
							{
								id: '1',
								caption: `Reasonable adjustments to both the recruitment process and the workplace remove unnecessary barriers that prevent autistic talent from participating and contributing in the workforce. Adjustments below are categorised to identify both required and preferred adjustments allowing employers the ability to consider what adjustments can be reasonably accommodated in their workplace, without excluding an applicant or employee unnecessarily.`,
								type: 'text',
							},
						],
					},
					{
						type: 'container',
						styles: 'paragraph',
						elements: [
							{
								id: '2',
								caption: `Workplace adjustments are an initial indication of how barriers may be removed to improve performance and inclusion. Optional adjustments are also detailed as, even though they are not critical to performance, they may contribute to improving the working environment.`,
								type: 'text',
							},
						],
					},
				],
			},
			{
				type: 'container',
				styles: 'row',
				elements: [
					{
						type: 'container',
						styles: 'infoHeading',
						elements: [
							{
								id: '1',
								caption: 'Recruitment Adjustments',
								type: 'text',
							},
						],
					},
				],
			},
			{
				type: 'container',
				styles: 'row',
				elements: [
					{
						type: 'container',
						styles: 'infoHeading',
						elements: [
							{
								id: '1',
								caption: `${`Required:`}`,
								type: 'text',
							},
						],
					},
					{
						type: 'container',
						styles: 'paragraph',
						elements: [
							{
								id: '1',
								caption: `${`No known adjustments`}`,
								type: 'text',
							},
						],
					},
				],
			},
			{
				type: 'container',
				styles: 'row',
				elements: [
					{
						type: 'container',
						styles: 'infoHeading',
						elements: [
							{
								id: '1',
								caption: `${`Preferred:`}`,
								type: 'text',
							},
						],
					},
					{
						type: 'container',
						styles: 'paragraph',
						elements: [
							{
								id: '1',
								caption: `${`No known adjustments`}`,
								type: 'text',
							},
						],
					},
				],
			},
			{
				type: 'container',
				styles: 'row',
				elements: [
					{
						type: 'container',
						styles: 'infoHeading',
						elements: [
							{
								id: '1',
								caption: 'Workplace Adjustments',
								type: 'text',
							},
						],
					},
				],
			},
			{
				type: 'container',
				styles: 'row',
				elements: [
					{
						type: 'container',
						styles: 'infoHeading',
						elements: [
							{
								id: '1',
								caption: `${`Physical Environment:`}`,
								type: 'text',
							},
						],
					},
					{
						type: 'container',
						styles: 'paragraph',
						elements: [
							{
								id: '1',
								caption: `${`No known adjustments`}`,
								type: 'text',
							},
						],
					},
				],
			},
			{
				type: 'container',
				styles: 'row',
				elements: [
					{
						type: 'container',
						styles: 'infoHeading',
						elements: [
							{
								id: '1',
								caption: `${`Working Hours:`}`,
								type: 'text',
							},
						],
					},
					{
						type: 'container',
						styles: 'paragraph',
						elements: [
							{
								id: '1',
								caption: `${`No known adjustments`}`,
								type: 'text',
							},
						],
					},
				],
			},
			{
				type: 'container',
				styles: 'row',
				elements: [
					{
						type: 'container',
						styles: 'infoHeading',
						elements: [
							{
								id: '1',
								caption: `${`Social Interaction:`}`,
								type: 'text',
							},
						],
					},
					{
						type: 'container',
						styles: 'paragraph',
						elements: [
							{
								id: '1',
								caption: `${`No known adjustments`}`,
								type: 'text',
							},
						],
					},
				],
			},
			{
				type: 'container',
				styles: 'row',
				elements: [
					{
						type: 'container',
						styles: 'infoHeading',
						elements: [
							{
								id: '1',
								caption: `${`Work Instructions:`}`,
								type: 'text',
							},
						],
					},
					{
						type: 'container',
						styles: 'paragraph',
						elements: [
							{
								id: '1',
								caption: `${`No known adjustments`}`,
								type: 'text',
							},
						],
					},
				],
			},
			{
				type: 'container',
				styles: 'row',
				elements: [
					{
						type: 'container',
						styles: 'infoHeading',
						elements: [
							{
								id: '1',
								caption: `${`Team Meetings:`}`,
								type: 'text',
							},
						],
					},
					{
						type: 'container',
						styles: 'paragraph',
						elements: [
							{
								id: '1',
								caption: `${`No known adjustments`}`,
								type: 'text',
							},
						],
					},
				],
			},
			{
				type: 'container',
				styles: 'row',
				elements: [
					{
						type: 'container',
						styles: 'infoHeading',
						elements: [
							{
								id: '1',
								caption: `${`Feedback:`}`,
								type: 'text',
							},
						],
					},
					{
						type: 'container',
						styles: 'paragraph',
						elements: [
							{
								id: '1',
								caption: `${`No known adjustments`}`,
								type: 'text',
							},
						],
					},
				],
			},
			{
				type: 'container',
				styles: 'row',
				elements: [
					{
						type: 'container',
						styles: 'infoHeading',
						elements: [
							{
								id: '1',
								caption: 'Optional Adjustments',
								type: 'text',
							},
						],
					},
				],
			},
			{
				type: 'container',
				styles: 'row',
				elements: [
					{
						type: 'container',
						styles: 'infoHeading',
						elements: [
							{
								id: '1',
								caption: `${`Physical Environment:`}`,
								type: 'text',
							},
						],
					},
					{
						type: 'container',
						styles: 'paragraph',
						elements: [
							{
								id: '1',
								caption: `${`No known adjustments`}`,
								type: 'text',
							},
						],
					},
				],
			},
			{
				type: 'container',
				styles: 'row',
				elements: [
					{
						type: 'container',
						styles: 'infoHeading',
						elements: [
							{
								id: '1',
								caption: `${`Working Hours:`}`,
								type: 'text',
							},
						],
					},
					{
						type: 'container',
						styles: 'paragraph',
						elements: [
							{
								id: '1',
								caption: `${`No known adjustments`}`,
								type: 'text',
							},
						],
					},
				],
			},
			{
				type: 'container',
				styles: 'row',
				elements: [
					{
						type: 'container',
						styles: 'infoHeading',
						elements: [
							{
								id: '1',
								caption: `${`Social Interaction:`}`,
								type: 'text',
							},
						],
					},
					{
						type: 'container',
						styles: 'paragraph',
						elements: [
							{
								id: '1',
								caption: `${`No known adjustments`}`,
								type: 'text',
							},
						],
					},
				],
			},
			{
				type: 'container',
				styles: 'row',
				elements: [
					{
						type: 'container',
						styles: 'infoHeading',
						elements: [
							{
								id: '1',
								caption: `${`Work Instructions:`}`,
								type: 'text',
							},
						],
					},
					{
						type: 'container',
						styles: 'paragraph',
						elements: [
							{
								id: '1',
								caption: `${`No known adjustments`}`,
								type: 'text',
							},
						],
					},
				],
			},
			{
				type: 'container',
				styles: 'row',
				elements: [
					{
						type: 'container',
						styles: 'infoHeading',
						elements: [
							{
								id: '1',
								caption: `${`Team Meetings:`}`,
								type: 'text',
							},
						],
					},
					{
						type: 'container',
						styles: 'paragraph',
						elements: [
							{
								id: '1',
								caption: `${`No known adjustments`}`,
								type: 'text',
							},
						],
					},
				],
			},
			{
				type: 'container',
				styles: 'row',
				elements: [
					{
						type: 'container',
						styles: 'infoHeading',
						elements: [
							{
								id: '1',
								caption: `${`Feedback:`}`,
								type: 'text',
							},
						],
					},
					{
						type: 'container',
						styles: 'paragraph',
						elements: [
							{
								id: '1',
								caption: `${`No known adjustments`}`,
								type: 'text',
							},
						],
					},
				],
			},
		];
		return result;
	}

	private constructProfilePage7() {
		const result = [
			{
				type: 'container',
				styles: 'row',
				elements: [
					{
						type: 'container',
						styles: 'infoHeading',
						elements: [
							{
								id: '1',
								caption: `About Xceptional`,
								type: 'text',
							},
						],
					},
				],
			},
			{
				type: 'container',
				styles: 'row',
				elements: [
					{
						type: 'container',
						styles: 'paragraph',
						elements: [
							{
								id: '1',
								caption: `Xceptional is a leading technology platform that harnesses the strengths of autistic people, securing them meaningful employment. Our business was established to demonstrate that the strengths of autistic employees, while often overlooked, are of significant value to employers. Since launching in 2017, Xceptional has grown rapidly due to the increasing demand for neurodiverse workplaces and our unique work has been recognised with awards from Google.org, Westpac, AMP and Optus.`,
								type: 'text',
							},
						],
					},
					{
						type: 'container',
						styles: 'paragraph',
						elements: [
							{
								id: '2',
								caption: `We attribute a lot of our success to the diversity of thinking and innovation that is made possible through our team, half of whom are neurodiverse. Our proprietary technology and innovation in the recruitment process, including this candidate profile, were designed and built by the autistic community for the autistic community. Our unique skills assessment tools help organisations identify skills in the recruitment process they might otherwise miss. Xceptional supports the recruitment process with comprehensive training and coaching for managers and candidates.`,
								type: 'text',
							},
						],
					},
				],
			},
			{
				type: 'container',
				styles: 'row',
				elements: [
					{
						type: 'container',
						styles: 'infoHeading',
						elements: [
							{
								id: '1',
								caption: `xceptional.io`,
								type: 'text',
							},
						],
					},
				],
			},
		];
		return result;
	}

	private convertComponentsToText(components: any[]) {
		return components.map((component) => {
			if (component) {
				// Make a copy so the original definition is not affected
				// Note: this breaks sync logic so any updates to the original will not be applied to the copy
				// TODO: Investigate to find whether there is a data structure that will allow modification in the child
				//   without affecting the parent while also allowing updates to the parent to flow through to the child
				const copy = Object.assign({}, component);
				copy.type = 'text';
				return copy;
			}
		});
	}
}

export const ProfileDownloadBuilder = new ProfileDownloadImplementation();
