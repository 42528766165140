import { Link } from 'gatsby';
import React from 'react';
import styles from './styles.module.css';

export const VerificationReminder = () => {
	return (
		<div className={styles.detailsContainer}>
			<p>A verification link has been sent to your email account.</p>
			<p>
				Please verify your account by clicking the link or{' '}
				<Link to={'/sign-up'}>request a new verification email</Link>
			</p>
		</div>
	);
};
