import React from 'react';
import nanoid from 'nanoid';
import Select from 'react-select';
import styles from './styles.module.css';
import colors from 'src/css/colors.module.css';
import { RequiredFieldAsterisk } from 'src/components/SVGs/RequiredFieldAsterisk';

interface Props {
	caption: string;
	onChange(values: any): void;
	defaultValues?: string[];
	options: SelectedValue[];
	isRequired?: boolean;
	validate?: (value: any) => boolean;
	validationMsg?: string;
	isSingle?: boolean;
	isClearable?: boolean;
	width?: number;
}

export interface SelectedValue {
	label: string;
	value: any;
}

interface State {
	selectedValues?: SelectedValue[];
	hasError: boolean;
	validationMsg?: string;
}

export default class TagList extends React.Component<Props, State> {
	uid: string;

	state: State = {
		hasError: false,
	};

	constructor(props: Props) {
		super(props);
		this.uid = nanoid();
	}

	componentDidMount() {
		this.setState({
			selectedValues: this.props.options.filter((item) =>
				this.props.defaultValues?.includes(item.value)
			),
		});
	}

	onChange = (newValue: any) => {
		if (this.props.validate) {
			const isValid = this.props.validate(newValue);
			if (!isValid) {
				this.setState({ hasError: true, validationMsg: this.props.validationMsg });
				return;
			} else {
				this.setState({ validationMsg: undefined });
			}
		}
		this.setState(
			{
				selectedValues: newValue,
				hasError: (this.props.isRequired && (!newValue || newValue.length == 0)) ?? false,
			},
			() => this.props.onChange(newValue)
		);
	};

	customStyle = {
		container: (provided: any) => getContainerStyle(provided),
		control: (provided: any) =>
			!this.state.hasError ? getValidControlStyle(provided) : getInvalidControlStyle(provided),
	};

	render() {
		const style = {
			'--width': `${this.props.width ? this.props.width : 10}px`,
		} as React.CSSProperties;
		const className = `${styles.caption} ${!!this.props.width ? styles.setWidth : ''}`;
		return (
			<div className={styles.control} style={style}>
				<label htmlFor={this.uid} className={className}>
					{this.props.caption}
					{this.props.isRequired && (
						<div className={styles.asterisk}>
							<RequiredFieldAsterisk />
						</div>
					)}
				</label>

				<Select
					isClearable={this.props.isClearable}
					isMulti={!this.props.isSingle}
					options={this.props.options}
					styles={this.customStyle}
					value={this.state.selectedValues}
					onChange={this.onChange}
					theme={customTheme}
				/>

				{this.state.hasError && (
					<div className={styles.validationMsg}>
						{this.state.validationMsg ?? 'This field is required'}
					</div>
				)}
			</div>
		);
	}
}

const getContainerStyle = (provided: any) => ({
	...provided,
	width: '100%',
});

const getValidControlStyle = (provided: any) => ({
	...provided,
	backgroundColor: colors.lightestBlue,
	border: '1px solid ' + colors.borderLightBlue,
});

const getInvalidControlStyle = (provided: any) => ({
	...provided,
	backgroundColor: colors.lightestBlue,
	border: '2px solid ' + colors.red,
	'&:hover': {
		border: '2px solid ' + colors.red,
	},
});

const customTheme = (theme: any) => ({
	...theme,
	borderRadius: 0,
	backgroundColor: colors.lightestBlue,
	colors: {
		...theme.colors,
		primary: colors.borderLightBlue,
		neutral0: 'white', // Dropdown background colour
		neutral10: 'white', // Option lozenge background colour
		neutral20: 'lightGray', // Close button and down-arrow (when dropdown non-active)
		neutral30: colors.borderBlue, // Control hover border
		neutral60: 'gray', // Close button and down-arrow (when dropdown active)
		neutral80: 'gray', // Option lozenge text colour
		danger: 'gray', // Option lozenge Clear Button X colour
		dangerLight: 'lightGray', // Option lozenge Clear Button background colour
	},
});

export { TagList };
