import React from 'react';
import nanoid from 'nanoid';
import 'antd/dist/antd.css';
import styles from './styles.module.css';
import { TextInput } from 'src/components/TextInput';
import { Dropdown2 } from 'src/components/Dropdown2';
import { DateSelector } from 'src/components/DateSelector';

interface Props {
	tableData: {
		columns: { caption: string; type: string; defaultValue: any; props: {} }[];
		data: any[];
		rowValidation?: (row: any[]) => boolean;
	};
	onRowEdited?: (tableData: any) => void;
	onRowAdded?: (tableData: any) => void;
	onRowDeleted?: (tableData: any) => void;
}

interface State {
	tableData: any;
}

export default class Grid extends React.Component<Props, State> {
	uid: string;

	state: State = {
		tableData: {
			columns: [],
			data: [],
			props: {},
			rowValidation: (row: any) => {
				return true;
			},
		},
	};

	constructor(props: Props) {
		super(props);
		this.uid = nanoid();
	}

	componentDidMount() {
		this.setState({
			tableData: this.props.tableData,
		});
	}

	render() {
		const { tableData } = this.state;
		const headings = tableData.columns;
		const rows = tableData.data;

		return (
			<div className={styles.tableWrapper}>
				<table className={styles.table}>
					<tbody>
						<tr className={styles.row}>
							{headings.map((heading: any, headingIndex: number) => {
								return (
									<td key={headingIndex} className={styles.col}>
										{heading.caption}
									</td>
								);
							})}
							<td></td>
						</tr>
						{rows.map((row: any, rowIndex: number) => {
							return (
								<tr
									key={rowIndex}
									className={
										tableData.rowValidation && !tableData.rowValidation(row)
											? styles.validation
											: styles.row
									}
								>
									{headings.map((col: any) => {
										let element: any = { ...col };
										const stateField = !!element.stateField ? element.stateField : element.caption;
										if (!!row[stateField]) element[stateField] = row[stateField];
										return (
											<td key={stateField} className={styles.col}>
												{element.type == 'TextInput' ? (
													<TextInput
														value={row[stateField] || ''}
														onValueChanged={(v) => {
															row[stateField] = v;
															this.setState({
																tableData,
															});
															this.props.onRowEdited && this.props.onRowEdited(tableData);
														}}
														// This element must be last
														{...element.props}
													></TextInput>
												) : element.type == 'Dropdown' ? (
													<Dropdown2
														caption=""
														onChange={(v) => {
															row[stateField] = v;
															this.setState({
																tableData,
															});
															this.props.onRowEdited && this.props.onRowEdited(tableData);
														}}
														defaultOption={!!row[stateField] ? row[stateField] : ''}
														options={[]}
														// This element must be last
														{...element.props}
													/>
												) : element.type == 'DateSelector' ? (
													<DateSelector
														onChange={(v) => {
															row[stateField] = v;
															this.setState({
																tableData,
															});
															this.props.onRowEdited && this.props.onRowEdited(tableData);
														}}
														value={row[stateField] || ''}
														// This element must be last
														{...element.props}
													/>
												) : (
													<div>Grid error: Unrecognised Type</div>
												)}
											</td>
										);
									})}
									<td>
										<button
											className={styles.button}
											onClick={() => {
												rows.splice(rowIndex, 1);
												this.setState({ tableData });
												this.props.onRowDeleted && this.props.onRowDeleted(tableData);
											}}
										>
											Delete
										</button>
									</td>
								</tr>
							);
						})}
						<tr>
							{headings.map((_heading: any, headingIndex: number) => {
								return <td key={headingIndex} className={styles.col}></td>;
							})}
							<td className={styles.col}>
								<button
									className={styles.button}
									onClick={() => {
										const newRow: any = {};
										headings.forEach((heading: any) => {
											const stateField = !!heading.stateField
												? heading.stateField
												: heading.caption;
											newRow[stateField] = heading.defaultValue;
										});
										rows.push(newRow);
										this.setState({ tableData });
										this.props.onRowAdded && this.props.onRowAdded(tableData);
									}}
								>
									Add
								</button>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		);
	}
}

export { Grid };
