import React from 'react';

export const SpinnerSVG = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="63" height="63" viewBox="0 0 63 63">
      <g fill="#54CADC" stroke="none" transform="matrix(-1 0 0 1 62.633 0)">
        <path
          fillOpacity="0.2"
          d="M1.285 9.68c.5.3 1 .398 1.5.398.899 0 1.801-.5 2.399-1.3.898-1.5 2-2.802 3.101-4.102 1.102-1.102 1-2.899-.101-3.899-1.102-1.101-2.899-1-3.899.102a31.51 31.51 0 00-3.8 5c-.899 1.3-.5 3 .8 3.8z"
          transform="translate(4.246 9.218)"
        />
        <path
          fillOpacity="0.6"
          d="M15.2 9.102c-4-1.801-7.602-4.5-10.2-8-.898-1.2-2.7-1.5-3.898-.5-1.2.898-1.5 2.699-.5 3.898C3.8 8.7 8.102 12.102 13 14.3c.398.2.699.2 1.101.2 1.102 0 2.102-.601 2.602-1.699.598-1.398-.102-3.098-1.504-3.7z"
          transform="translate(5.832 45.796)"
        />
        <path
          fillOpacity="0.4"
          d="M6.434 13.229c-.898-3.301-1-6.801-.601-10.102.199-1.5-.801-2.899-2.399-3.102C1.934-.174.536.826.333 2.424c-.602 4.101-.399 8.199.699 12.3.3 1.301 1.5 2.102 2.7 2.102.198 0 .5 0 .698-.101 1.504-.497 2.403-1.997 2.004-3.497z"
          transform="translate(0 24.47)"
        />
        <path
          fillOpacity="0.18"
          d="M3.469.13c3.457 1.003 1.945 6.19-1.512 5.183C-1.5 4.305.012-.88 3.47.129"
          transform="translate(17.371 2.507)"
        />
        <path
          d="M2.8 0C1.3 0 0 1.2 0 2.8c0 1.5 1.2 2.802 2.8 2.802 14.2 0 25.802 11.602 25.802 25.8 0 2.7-.399 5.399-1.301 8.102-.5 1.5.3 3 1.8 3.5.302.102.602.102.9.102 1.198 0 2.3-.7 2.6-1.899 1.102-3.199 1.602-6.5 1.602-9.8C34.203 14.097 20.101 0 2.801 0V0z"
          transform="translate(28.43)"
        />
        <path
          fillOpacity="0.8"
          d="M21.8.875c-4.898 5.3-11.8 8.398-19 8.398-1.5 0-2.8 1.2-2.8 2.801 0 1.5 1.2 2.801 2.8 2.801 8.7 0 17.2-3.7 23.103-10.199 1-1.102 1-2.898-.2-3.898-1.101-1.102-2.8-1-3.902.097z"
          transform="translate(28.43 47.921)"
        />
      </g>
    </svg>
  );
};
