import React from 'react';

const UncheckedSVG = () => (
	<svg
		width="22px"
		height="22px"
		viewBox="0 0 32 32"
		version="1.1"
		xmlnsXlink="http://www.w3.org/1999/xlink"
		xmlns="http://www.w3.org/2000/svg"
	>
		<defs>
			<rect width="32" height="32" id="slice_1" />
			<clipPath id="clip_1">
				<use href="#slice_1" clipRule="evenodd" />
			</clipPath>
		</defs>
		<g id="Slice" clipPath="url(#clip_1)">
			<use href="#slice_1" stroke="none" fill="none" />
			<path
				d="M0 4.32432C0 1.90203 1.90203 0 4.32432 0L27.6757 0C30.098 0 32 1.90203 32 4.32432L32 27.6757C32 30.098 30.098 32 27.6757 32L4.32432 32C1.90203 32 0 30.098 0 27.6757L0 4.32432ZM27.6756 1.72968L4.32427 1.72968C2.85467 1.72968 1.72968 2.85467 1.72968 4.32427L1.72968 27.6756C1.72968 29.1452 2.85467 30.2702 4.32427 30.2702L27.6756 30.2702C29.1452 30.2702 30.2702 29.1452 30.2702 27.6756L30.2702 4.32427C30.2702 2.85467 29.1452 1.72968 27.6756 1.72968Z"
				id="Shape"
				fill="#C3ECF2"
				fillRule="evenodd"
				stroke="none"
			/>
		</g>
	</svg>
);

export { UncheckedSVG };
