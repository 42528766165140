import React from 'react';

import styles from './styles.module.css';

export interface PopUpProps {
	caption: string;
	onClose?: () => void;
	autoCloseDelay: number;
	fadeInDuration: number;
	fadeOutDuration: number;
	key: number;
}

interface State {
	visible: boolean;
}

class PopUp extends React.Component<PopUpProps, State> {
	state: State = {
		visible: true,
	};

	async componentDidMount() {
		const { autoCloseDelay, fadeInDuration, fadeOutDuration } = this.props;
		await setTimeout(() => {
			this.close();
		}, autoCloseDelay + fadeInDuration + fadeOutDuration);
	}

	close() {
		this.setState({ visible: false });
		this.props.onClose ? this.props.onClose() : null;
	}

	render() {
		const { autoCloseDelay, fadeInDuration, fadeOutDuration } = this.props;
		const style = {
			'--fade-out-duration': `${fadeOutDuration}ms`,
			'--fade-in-duration': `${fadeInDuration}ms`,
			'--fade-out-delay': `${autoCloseDelay + fadeInDuration + fadeOutDuration}ms`,
		} as React.CSSProperties;
		return this.state.visible ? (
			<div className={styles.popUpContainer} style={style}>
				<div className={styles.popUp}>
					<div className={styles.popUpText}>{this.props.caption}</div>
					<div
						className={styles.popUpCross}
						onClick={() => {
							this.close();
						}}
					>
						x
					</div>
				</div>
			</div>
		) : null;
	}
}

export { PopUp };
