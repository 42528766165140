import { ProfileBuilder } from './profiledetails';
import { QuestionnaireBuilder } from './questionnaire';
import { ProfileDownloadBuilder } from './profiledownload';
import * as common from './common';

export const getProfilePageLayout = ProfileBuilder.BuildProfileDict;

export const getQuestionnairePageLayout = QuestionnaireBuilder.BuildQuestionnaireDict;

export const getProfileDownloadPageLayout = ProfileDownloadBuilder.BuildProfileDownloadDict;

export const ProfileData = common.ProfileData;

export function getTechLanguages() {
	return common.getTechLanguages();
}

export function getWorkplaceSupports() {
	return common.getWorkplaceSupports();
}

export function getWorkplaceSupportsTextOnly() {
	return common.getWorkplaceSupportsTextOnly();
}
