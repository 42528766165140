import React, { useState, useEffect } from 'react';
import styles from './styles.module.css';
import { NavMenuItem } from './NavMenuItem/component';
import { UserManager } from 'src/helpers/UserManager';
import classNames from 'classnames';
import { navigate } from 'gatsby';

interface Props {
	onUserSignOut?: any;
}

interface NavItem {
	title: string;
	icon: number;
	badgeValue?: number;
	to: string;
}

type NavItems = { [key: string]: NavItem };

const navItems: NavItems = {
	profile: {
		title: 'Profile',
		icon: 4,
		to: '/profile',
	},
	puzzles: {
		title: 'Puzzles',
		icon: 1,
		to: '/',
	},
	signin: {
		title: 'Sign In',
		icon: 3,
		to: '/sign-in',
	},
	signup: {
		title: 'Sign Up',
		icon: 3,
		to: '/sign-up',
	},
};

export const NavMenuItemList = (props: Props) => {
	const [isSignedIn, setIsSignedIn] = useState(UserManager.isSignedInAsUser);
	useEffect(() => {
		function handleStatusChange(status: boolean) {
			setIsSignedIn(status);
		}
		UserManager.subscribeToSignInChanges(handleStatusChange);
		return () => {
			UserManager.unsubscribeFromSignInChanges(handleStatusChange);
		};
	});

	const displayNavItems = isSignedIn
		? Object.keys(navItems)
				.filter((value) => value !== 'signin' && value !== 'signup')
				.map((navItem, i) => {
					return (
						<NavMenuItem
							key={i}
							title={navItems[navItem].title}
							badgeValue={navItems[navItem].badgeValue}
							icon={navItems[navItem].icon}
							to={navItems[navItem].to}
						/>
					);
				})
		: Object.keys(navItems)
				.filter((value) => value !== 'profile')
				.map((navItem, i) => {
					return (
						<NavMenuItem
							key={i}
							title={navItems[navItem].title}
							badgeValue={navItems[navItem].badgeValue}
							icon={navItems[navItem].icon}
							to={navItems[navItem].to}
						/>
					);
				});

	return (
		<div className={styles.looks}>
			{displayNavItems}
			{isSignedIn && (
				<button
					className={classNames(
						styles.title,
						styles.activityIrrelevant,
						styles.inactive,
						styles.pointer
					)}
					onClick={async () => {
						await UserManager.signOut();
						await props.onUserSignOut();
						navigate('/');
					}}
				>
					Sign Out
				</button>
			)}
		</div>
	);
};
