import React, { Component } from 'react';
import classNames from 'classnames';
import nanoid from 'nanoid';

import TextareaAutosize from 'react-autosize-textarea';
import { PasswordEye } from './components/PasswordEye';
import { PasswordEyeSelected } from './components/PasswordEye_Selected';
import { TextInputState, InputControlType } from './Input.interface';
import styles from './styles.module.css';
import { RequiredFieldAsterisk } from 'src/components/SVGs/RequiredFieldAsterisk';

interface Props {
	caption: string;
	isPasswordInput?: boolean;
	isMultiLine?: boolean;
	isRequired?: boolean;
	onValueChanged?: (value: any, error: boolean) => void;
	validate?: (value: any) => boolean;
	validationMsg?: string;
	multiLineRows?: number;
	additionalStyles?: { [key: string]: any };
	placeholder?: string;
	value?: string;
	onEnter?: () => void;
	readOnly?: boolean;
	hasError?: boolean;
}

class TextInput extends React.Component<Props, TextInputState> {
	uid: string;

	state: TextInputState = {
		checked: true,
		hasError: false,
		value: '',
		errorMsg: '',
	};

	constructor(props: Props) {
		super(props);
		this.handleChange = this.handleChange.bind(this);
		this.uid = nanoid();
	}

	clear() {
		this.setState({ value: '' });
	}

	// React.ChangeEvent<HTMLInputElement> || FormEvent<HTMLTextAreaElement> || React.ChangeEvent<HTMLTextAreaElement>?
	handleChange(event: any) {
		const { validate, validationMsg, isPasswordInput, isRequired } = this.props;
		let hasError = this.props.hasError || false;
		let errorPreventsSave = false;
		const defaultErrorMsg = 'This field is required';
		let errorMsg = validationMsg ? validationMsg : defaultErrorMsg;
		this.setState({ value: event.target.value });
		if (validate) {
			if (event.target.value != '') {
				hasError = !validate(event.target.value);
				errorPreventsSave = hasError;
			} else {
				errorMsg = defaultErrorMsg;
			}
			if (isPasswordInput) hasError = !validate(event.target.value);
			if (!isPasswordInput && isRequired) hasError = !validate(event.target.value);
		}
		if (!validate && isRequired && !isPasswordInput) {
			hasError = event.target.value === '';
			errorMsg = defaultErrorMsg;
		}
		this.setState({ hasError, errorMsg });
		this.props.onValueChanged && this.props.onValueChanged(event.target.value, errorPreventsSave);
	}

	toggleChecked = () => {
		this.setState({
			checked: !this.state.checked,
		});
	};

	getInputControlType() {
		if (this.props.isMultiLine) {
			return InputControlType.MultiLineField;
		} else if (this.props.isPasswordInput) {
			return InputControlType.PasswordField;
		} else {
			return InputControlType.TextField;
		}
	}

	getInputControl(type: InputControlType) {
		const classesObj = {
			[styles.border_error]: this.hasError(),
			[styles.border_ok]: !this.hasError(),
			[styles.field]: true,
		};

		if (this.props.additionalStyles && this.props.additionalStyles.field) {
			classesObj[this.props.additionalStyles.field as any] = true;
		}
		let classes: string = classNames(classesObj);

		switch (type) {
			case InputControlType.TextField:
				return (
					<input
						id={this.uid}
						className={classes}
						type="text"
						value={this.props.value !== undefined ? this.props.value : this.state.value}
						onChange={this.handleChange}
						onBlur={this.handleChange}
						onKeyPress={(e) => {
							if (e.key === 'Enter' && this.props.value !== '') {
								this.props.onEnter && this.props.onEnter();
							}
						}}
						placeholder={this.props.placeholder}
						readOnly={this.props.readOnly}
					/>
				);
			case InputControlType.MultiLineField:
				return (
					<TextareaAutosize
						className={classes}
						onChange={this.handleChange}
						rows={this.props.multiLineRows ? this.props.multiLineRows : 5}
						value={this.props.value !== undefined ? this.props.value : this.state.value}
						readOnly={this.props.readOnly}
					/>
				);
			case InputControlType.PasswordField:
				return (
					<div className={styles.revealToggleContainer}>
						<input
							id={this.uid}
							className={classes}
							type={this.state.checked ? 'password' : 'text'}
							value={this.props.value !== undefined ? this.props.value : this.state.value}
							onBlur={this.handleChange}
							onChange={this.handleChange}
							onKeyPress={(e) => {
								if (e.key === 'Enter' && this.props.value !== '') {
									this.props.onEnter && this.props.onEnter();
								}
							}}
							readOnly={this.props.readOnly}
						/>
						<div className={styles.revealToggle} onClick={this.toggleChecked}>
							{this.state.checked ? <PasswordEyeSelected /> : <PasswordEye />}
						</div>
					</div>
				);
		}
	}

	hasError() {
		return this.props.hasError || this.state.hasError;
	}

	render() {
		const customControlStyles: { [key: string]: boolean } = {};
		if (this.props.additionalStyles && this.props.additionalStyles.control) {
			customControlStyles[this.props.additionalStyles.control] = true;
		}

		const customCaptionStyles: { [key: string]: boolean } = {};
		if (this.props.additionalStyles && this.props.additionalStyles.caption) {
			customCaptionStyles[this.props.additionalStyles.caption] = true;
		}
		return (
			<div className={classNames(styles.control, customControlStyles)}>
				<label htmlFor={this.uid} className={classNames(styles.caption, customCaptionStyles)}>
					{this.props.caption}
					{this.props.isRequired && (
						<div className={styles.asterisk}>
							<RequiredFieldAsterisk />
						</div>
					)}
				</label>
				{this.getInputControl(this.getInputControlType())}
				{this.hasError() && <div className={styles.validationMsg}>{this.state.errorMsg}</div>}
			</div>
		);
	}
}

export { TextInput };
