import React from 'react';

const PasswordEyeSelected = () => {
	return (
		<svg
			enableBackground="new 0 0 32 32"
			height="24px"
			id="Layer_1"
			version="1.1"
			viewBox="0 0 32 32"
			width="24px"
			xmlSpace="preserve"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g>
				<g>
					<path
						d="M29.586,14.186c-2.76-2.246-7.407-5.51-13.253-5.621v0c-0.006,0-0.012,0-0.018,0c-0.006,0-0.012,0-0.018,0    v0C10.453,8.676,5.801,11.94,3.041,14.186c-0.132,0.107-1.036,0.8-1.051,1.686c-0.011,0.624,0.28,1.085,0.699,1.471    c2.255,2.181,7.176,6.059,13.609,6.087v0.002c0.006,0,0.012,0,0.018,0c0.005,0,0.011,0,0.018,0v-0.002    c6.445-0.028,11.351-3.906,13.607-6.087c0.419-0.386,0.709-0.847,0.697-1.471C30.621,14.986,29.719,14.293,29.586,14.186z     M16.315,21.752c-3.162-0.021-5.719-2.59-5.719-5.756s2.557-5.735,5.719-5.755c3.17,0.02,5.718,2.589,5.718,5.755    S19.485,21.73,16.315,21.752z"
						fill="#54CADC"
						opacity="0.3"
					/>
				</g>
			</g>
		</svg>
	);
};
export { PasswordEyeSelected };
