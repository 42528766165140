import React from 'react';
import { KeystoneUser } from 'src/helpers/UserManager';
import styles from './styles.module.css';
const defaultProfPic = require('src/images/XceptionalDefaultProfPic.png');

interface Props {
	user?: KeystoneUser;
}

export const Profile = (props: Props) => {
	const name = props.user?.firstName ? props.user.firstName : '';
	return (
		<div className={styles.profile}>
			<img className={styles.profilePic} src={defaultProfPic} alt="Profile Pic" />
			<div className={styles.profileName}>
				<div className={styles.overflowEllipsis}>{name}</div>
			</div>
		</div>
	);
};
