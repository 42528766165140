import React from 'react';

const RequiredFieldAsterisk = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8 8" height={8} width={8}>
		<path stroke="#FF0000" d="M4 0.5 L 4 7.5" />
		<path stroke="#FF0000" d="M1 2 L 7 6" />
		<path stroke="#FF0000" d="M7 2 L 1 6" />
	</svg>
);

export { RequiredFieldAsterisk };
