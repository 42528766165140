import { ApolloManager } from 'src/helpers/ApolloManager';
import { Client } from '../ClientManager';
import { UserManager } from '../UserManager';
import { RegistrationManager } from '../RegistrationManager';
import getSetting from './getSetting.graphql';

interface Setting {
	allSettings: [
		{
			id?: number;
			key?: string;
			value?: string;
			client?: Client;
		}
	];
}

class SettingsImplementation {
	public readonly getSetting = async (
		key: string,
		userId?: number
	): Promise<string | undefined> => {
		try {
			const result = await ApolloManager.client.query<Setting>({
				query: getSetting,
				variables: { key },
				fetchPolicy: 'no-cache',
			});
			if (result?.data?.allSettings?.length > 0 && result.data.allSettings[0]?.id) {
				const userRegistration = await RegistrationManager.getMostRecentRegistrationForUser(userId);
				const settings = result?.data?.allSettings;
				return (
					settings.find(
						(setting: any) =>
							typeof userRegistration?.client === 'object' &&
							setting.client?.id == userRegistration?.client?.id
					)?.value || settings.find((setting: any) => !setting.client)?.value
				);
			}
		} catch (error) {}
		return undefined;
	};

	public readonly getCountryList = async (): Promise<any | undefined> => {
		return require('./countryList.json');
	};
}

export const SettingsManager = new SettingsImplementation();
