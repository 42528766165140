import React from 'react';

export const RightArrowSVG = () => (
	<svg
		width="42px"
		height="42px"
		viewBox="0 0 42 42"
		version="1.1"
		xmlnsXlink="http://www.w3.org/1999/xlink"
		xmlns="http://www.w3.org/2000/svg"
	>
		<desc>Created with Lunacy</desc>
		<g id="Group" transform="translate(1 1)">
			<path
				d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40Z"
				id="Oval-Copy-4"
				fill="none"
				stroke="#FFFFFF"
				strokeOpacity="0.352097273"
				strokeWidth="2"
			/>
			<path
				d="M7.75399 3.59898L12.9823 8.87C13.5609 9.44855 14.4961 9.44855 15.0747 8.87C15.6522 8.28561 15.6522 7.34072 15.0747 6.75636L8.79961 0.433917C8.22105 -0.144639 7.28487 -0.144639 6.70824 0.433917L0.433192 6.75636C-0.145364 7.34074 -0.143429 8.28563 0.433192 8.87C1.01175 9.44855 1.94696 9.44855 2.52552 8.87L7.75399 3.59898Z"
				transform="matrix(-4.371139E-08 1 -1 -4.371139E-08 25.40588 11.89803)"
				id="Fill-1-Copy"
				fill="#FFFFFF"
				stroke="none"
			/>
		</g>
	</svg>
);
