import React from 'react';
import nanoid from 'nanoid';
import styles from './styles.module.css';
import { Dropdown2 } from 'src/components/Dropdown2';

interface Props {
	onChange(value: string): void;
	value: string;
}

export default class DateSelector extends React.Component<Props> {
	uid: string;

	constructor(props: Props) {
		super(props);
		this.uid = nanoid();
	}

	render() {
		const date = this.props.value.split('-');
		const month = date.length > 1 ? date[1] : ' ';
		const year = date[0] || ' ';
		const start = new Date().getFullYear() - 50;
		const end = new Date().getFullYear();
		const years = [' '];
		for (let i = end; i >= start; i--) years.push(String(i));
		return (
			<div className={styles.control}>
				<div className={styles.month}>
					<Dropdown2
						caption=""
						onChange={(v) => {
							this.props.onChange(`${year}-${v}`);
						}}
						options={[
							' ',
							'Jan',
							'Feb',
							'Mar',
							'Apr',
							'May',
							'Jun',
							'Jul',
							'Aug',
							'Sep',
							'Oct',
							'Nov',
							'Dec',
						]}
						defaultOption={month || ' '}
					/>
				</div>
				<div className={styles.year}>
					<Dropdown2
						caption=""
						onChange={(v) => {
							this.props.onChange(`${v}-${month}`);
						}}
						options={years}
						defaultOption={year || ' '}
					/>
				</div>
			</div>
		);
	}
}

export { DateSelector };
